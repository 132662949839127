import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import '../styles/home/CardStyles.css'; 

const WikiHomePage = () => {
    const location = useLocation();


    return (
        <div className='page-content'>
                  { location.pathname === '/wiki' ? <div className='page-title'>Wiki Home</div> : null }

            
            <Outlet />
        </div>
    );
}

export default WikiHomePage;
