// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-page {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 60vw;
    padding: var(--spacing-medium);
    position: relative;
    top: calc(var(--spacing-small) * 12);
}

.content-with-images {
    display: flex;
    justify-content: space-between;
}

.text-block {
    width: 65%;
}

.image-block {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-medium);
}

.image-block img {
    background-color: var(--white);
    width: 100%;
    height: auto;
    object-fit: cover;
    padding: var(--spacing-medium);
}

.team-list {
    list-style: none;
}

.team-title {
    margin-bottom: var(--spacing-medium);
    display: flex;
}

.team-item {}

.team-image img {
    height: auto;
    object-fit: cover;
    background-color: var(--white);
}

.attr {
    background-color: var(--white);
}

.image-with-attr {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
    font-size: 0.6em;
    background-color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/styles/home/AboutPageStyles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,eAAe;IACf,8BAA8B;IAC9B,kBAAkB;IAClB,oCAAoC;AACxC;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,0BAA0B;AAC9B;;AAEA;IACI,8BAA8B;IAC9B,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,aAAa;AACjB;;AAEA,YAAY;;AAEZ;IACI,YAAY;IACZ,iBAAiB;IACjB,8BAA8B;AAClC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,gBAAgB;IAChB,8BAA8B;AAClC","sourcesContent":[".about-page {\n    display: flex;\n    flex-direction: column;\n    margin: 0 auto;\n    max-width: 60vw;\n    padding: var(--spacing-medium);\n    position: relative;\n    top: calc(var(--spacing-small) * 12);\n}\n\n.content-with-images {\n    display: flex;\n    justify-content: space-between;\n}\n\n.text-block {\n    width: 65%;\n}\n\n.image-block {\n    width: 30%;\n    display: flex;\n    flex-direction: column;\n    gap: var(--spacing-medium);\n}\n\n.image-block img {\n    background-color: var(--white);\n    width: 100%;\n    height: auto;\n    object-fit: cover;\n    padding: var(--spacing-medium);\n}\n\n.team-list {\n    list-style: none;\n}\n\n.team-title {\n    margin-bottom: var(--spacing-medium);\n    display: flex;\n}\n\n.team-item {}\n\n.team-image img {\n    height: auto;\n    object-fit: cover;\n    background-color: var(--white);\n}\n\n.attr {\n    background-color: var(--white);\n}\n\n.image-with-attr {\n    display: flex;\n    flex-direction: column;\n    gap: var(--spacing-small);\n    font-size: 0.6em;\n    background-color: var(--white);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
