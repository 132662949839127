// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-legend-component-container {
    display: flex;
    flex-direction: row;
    width: 55vw;
    padding: var(--spacing-small);
}


.map-legend-selector-container {
    width: 25%;
    margin-right: var(--spacing-small);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.map-legend-selector {
    width: 100%;
    padding: var(--spacing-small);
}

.map-legend-container {
    width: 75%;
    border: 1px solid #ccc;
}

.map-legend-selector-header {
    font-size: 1.5em;
    font-weight: bold;
    padding: 0 var(--spacing-small) var(--spacing-small) var(--spacing-small);

}

.map-legend {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.map-legend-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.map-legend-color {
    width: 100%;
    height: calc(var(--spacing-large) * 1.5);
    margin-bottom: var(--spacing-small);
}

.map-legend-text {
    font-size: medium;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ipkw/IPKWMapLegend.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,6BAA6B;AACjC;;;AAGA;IACI,UAAU;IACV,kCAAkC;IAClC,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,6BAA6B;AACjC;;AAEA;IACI,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,yEAAyE;;AAE7E;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,wCAAwC;IACxC,mCAAmC;AACvC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".map-legend-component-container {\n    display: flex;\n    flex-direction: row;\n    width: 55vw;\n    padding: var(--spacing-small);\n}\n\n\n.map-legend-selector-container {\n    width: 25%;\n    margin-right: var(--spacing-small);\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.map-legend-selector {\n    width: 100%;\n    padding: var(--spacing-small);\n}\n\n.map-legend-container {\n    width: 75%;\n    border: 1px solid #ccc;\n}\n\n.map-legend-selector-header {\n    font-size: 1.5em;\n    font-weight: bold;\n    padding: 0 var(--spacing-small) var(--spacing-small) var(--spacing-small);\n\n}\n\n.map-legend {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    align-items: center;\n}\n\n.map-legend-column {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    flex-grow: 1;\n}\n\n.map-legend-color {\n    width: 100%;\n    height: calc(var(--spacing-large) * 1.5);\n    margin-bottom: var(--spacing-small);\n}\n\n.map-legend-text {\n    font-size: medium;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
