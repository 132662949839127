// ConfigSlider.js
import React from 'react';
import '../../../../styles/ipkw/EmissionsConfig.css';

const ConfigSlider = ({
    title,
    value,
    min,
    max,
    step,
    onChange,
    unit,
}) => {
    const handleSliderChange = (event) => {
        const newValue = parseFloat(event.target.value);
        onChange(newValue);
    };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const newValue = parseFloat(inputValue);
        if (!isNaN(newValue) && newValue >= min && newValue <= max) {
            onChange(newValue);
        }
    };

    return (
        <div className="config-box">
            <h3 className="config-title">{title}</h3>
            <div className="config-content">
                <div className="slider-container">
                    <span className="slider-label">{min}</span>
                    <input
                        type="range"
                        min={min}
                        max={max}
                        step={step}
                        value={value}
                        onChange={handleSliderChange}
                        className="slider"
                    />
                    <span className="slider-label">{max}</span>
                </div>
                <input
                    type="number"
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    onChange={handleInputChange}
                    className="input-field"
                />
                {unit && <span className="unit-label">{unit}</span>}
            </div>
        </div>
    );
};

export default ConfigSlider;
