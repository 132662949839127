import React from 'react';
import NavButton from './NavButton';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import '../styles/Wiki.css';
import '../styles/NavBar.css';
import { useDashboardContext } from '../context/DashboardContext';
import { luetticherData, preuswaldData, ziegelweiherData } from '../components/ipkw/PresetDistricts';

const NavBanner = () => {
  const { quartierSelection, setSidebarState, setMapState, setQuartierSelection } = useDashboardContext();

  const location = useLocation();
  const navigate = useNavigate();
  const options = [
    { value: 'geg', label: 'GEG' },
    { value: 'waermepumpen', label: 'Wärmepumpen' },
    { value: 'sanieren', label: 'Sanieren' },
    { value: 'szenarien', label: 'Szenarien' }
  ];


  const handleChange = (selectedOption) => {
    navigate(`${'/wiki/' + selectedOption.value}`);
  };
  const urlSplit = location.pathname.split('/').filter(Boolean);

  const urlMapping = {
    'about': 'About',
    'wiki': 'Wiki',
    'geg': 'GEG',
    'waermepumpen': 'Wärmepumpen',
    'sanieren': 'Sanieren',
    'szenarien': 'Szenarien',
    'ipkw': 'IPKW',
    'quartier': 'Toolbox',
    'dashboard': 'Dashboard',
  };

  const loadQuartierBySidebar = (newSelection) => {
    const { quartierSelection } = newSelection;

    if (quartierSelection === 'load') {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.json';
      input.click();
      input.onchange = () => {
        const file = input.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const json = JSON.parse(reader.result);
            if (json.name && json.quartier && json.geometry) {
              setMapState(prevState => ({ ...prevState, geometry: json.geometry }));
            } else {
              alert('Invalid JSON');
            }
          } catch (error) {
            alert('Invalid JSON');
          }
        };
        reader.readAsText(file);
      };
    } else {
      switch (quartierSelection) {
        case 'luetticher':
          setMapState(prevState => ({ ...prevState, geometry: luetticherData.geometry }));
          break;
        case 'preuswald':
          setMapState(prevState => ({ ...prevState, geometry: preuswaldData.geometry }));
          break;
        case 'ziegelweiher':
          setMapState(prevState => ({ ...prevState, geometry: ziegelweiherData.geometry }));
          break;
        default:
          setSidebarState('none');
          break;
      }
    }
    setQuartierSelection(prevState => ({ ...prevState, quartierSelection }));

  };

  const renderNavBanner = () => {
    // Home Navigation
    if (urlSplit.length === 0) {
      return (
        <>
          <NavButton className="selected" to="/" outline>About</NavButton>
          <NavButton className="option" to="/karte" outline>Karte</NavButton>
          <NavButton className="option" to="/toolbox" outline>Toolbox</NavButton>
        </>
      );
    }
    if (urlSplit.length === 1) {
      if (urlSplit[0] === 'about') {
        return (
          <>
            <NavButton className="selected" to="/" outline>About</NavButton>
            <NavButton className="option" to="/karte" outline>Karte</NavButton>
            <NavButton className="option" to="/toolbox" outline>Toolbox</NavButton>
          </>
        );
      } else if (urlSplit[0] === 'karte') {
        return (
          <>
            <NavButton className="option" to="/" outline>About</NavButton>
            <NavButton className="selected" to="/karte" outline>Karte</NavButton>
            <NavButton className="option" to="/toolbox" outline>Toolbox</NavButton>

          </>
        );
      } else if (urlSplit[0] === 'toolbox') {
        return (
          <>
            <NavButton className="option" to="/" outline>About</NavButton>
            <NavButton className="option" to="/karte" outline>Karte</NavButton>
            <NavButton className="selected" to="/toolbox" outline>Toolbox</NavButton>

          </>
        );
      } else if (urlSplit[0] === 'wiki') {
        return (
          <>
            <NavButton className="option" to="/" outline>About</NavButton>
            <NavButton className="option" to="/karte" outline>Karte</NavButton>
            <NavButton className="option" to="/toolbox" outline>Toolbox</NavButton>
            <NavButton className="selected" to="/wiki" outline>Wiki</NavButton>
            <Select className='wiki-select' options={options} onChange={handleChange} placeholder="Search Wiki Pages..." />

          </>
        );
      }

      else {
        return (
          <>
            <NavButton className="option" to="/" outline>About</NavButton>
            <NavButton className="option" to="/karte" outline>Karte</NavButton>
            <NavButton className="option" to="/toolbox" outline>Toolbox</NavButton>
          </>
        );
      }

    }

    if (urlSplit.length === 2) {
      if (urlSplit[0] === 'dashboard') {
        return (
          <>
            <NavButton className="option" to="/" outline>About</NavButton>
            <NavButton className={urlSplit[1] === "karte" ? "option" : "selected"} to="/karte" outline>Karte</NavButton>
            <NavButton className={urlSplit[1] === "quartier" ? "option" : "selected"} to="/toolbox" outline>Toolbox</NavButton>
          </>
        );
      } else {
        const wikiSub = '/' + urlSplit[0] + '/' + urlSplit[1];
        return (
          <>
            <NavButton className="option" to="/" outline>About</NavButton>
            <NavButton className="option" to="/karte" outline>Karte</NavButton>
            <NavButton className="option" to="/toolbox" outline>Toolbox</NavButton>
            <NavButton className="selected" to={wikiSub} outline>Wiki</NavButton>
            <Select className='wiki-select' options={options} onChange={handleChange} placeholder="Search Wiki Pages..." />


          </>
        );
      }
    }
  }


  return (
    <div className='nav-container'>

      <div className='nav-left'>
        {renderNavBanner()}
        {urlSplit.length > 0 && urlSplit[0] === 'karte' &&
          <div className='quartier-selector-container'>
            <div className='info-label'>Toolbox:</div>
            <select
              value={quartierSelection.quartierSelection}
              onChange={(e) => loadQuartierBySidebar({ quartierSelection: e.target.value })}
              className="quartier-selector">
              {quartierSelection.modellQuartierOptions.map((option, index) => (
                <option key={index} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
        }
      </div>


    </div>
  );
};

export default NavBanner;
