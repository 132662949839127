import React, { useState } from 'react';

import '../../../../styles/ipkw/Dashboard.css';
import '../../../../styles/ipkw/QuartierCard.css';
import ModellQuartierCard from './ModellQuartierCard';
import NullszenarioCard from './NullszenarioCard';
import Potenziale from './Potenziale';
import QuartierConfigModal from './QuartierConfigModal';
import BuildingOverview from './BuildingOverview';
import configure_quartier_icon from '../../../../images/configure_quartier_icon.png';

const QuartierOverview = () => {
    const [showConfigModal, setShowConfigModal] = useState(false);

    const handleConfigScenario = () => {
        setShowConfigModal(!showConfigModal); 
    };

    const handleCloseModal = () => {
        setShowConfigModal(false); 
    };

    return (
        <div className='quartier-card-container'>
            <div className='quartier-card-top-half'>

            <div className='config-header-container' onClick={handleConfigScenario}>
                <div className='configure-combo' >
                    <img src={configure_quartier_icon} alt='Configure Icon' className='configure-icon' />
                    <div className='configure-text'>Quartier Konfigurieren</div>
                </div>
            </div>


            </div>
            <div className='quartier-card-lower-half'>
                <BuildingOverview />
            </div>
            {showConfigModal && <QuartierConfigModal onClose={handleCloseModal} />}

        </div>
    );
};

export default QuartierOverview;
