import L from 'leaflet';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';


const MapHoverButtonControl = ({ mapHoverState, setMapHoverState }) => {
    const map = useMap();
    useEffect(() => {

        const control = new L.Control({ position: 'topleft' });
        control.onAdd = function (map) {
            var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');

            var button1 = L.DomUtil.create('button', 'leaflet-override-control', container);
            button1.innerHTML = 'ℹ️';
            if (mapHoverState.hoverEnabled) {
                button1.style.backgroundColor = 'var(--colora)';
            }
            button1.onclick = () => {
                setMapHoverState(prevState => ({
                    ...prevState,
                    hoverEnabled: !prevState.hoverEnabled

                }));
            };
            button1.onmouseenter = () => {
                button1.style.backgroundColor = 'var(--gray90)';
                if (mapHoverState.hoverEnabled) {
                    button1.style.backgroundColor = 'hsl(52, 100%, 45%)';
                }

            };
            button1.onmouseout = () => {
                button1.style.backgroundColor = 'var(--white)';
                if (mapHoverState.hoverEnabled) {
                    button1.style.backgroundColor = 'var(--colora)';
                }
            };

            // var button2 = L.DomUtil.create('button', 'a', container);
            // button2.innerHTML = '🖥️';
            // button2.onclick = () => {
            //     setMapState(prevState => ({
            //         ...prevState,
            //         loadScreen: true

            //     }));
            // };
            // button2.onmouseenter = () => {
            //     button2.style.backgroundColor = 'var(--gray90)';
            // };
            // button2.onmouseout = () => {
            //     button2.style.backgroundColor = 'var(--white)';
            // };

            return container;
        };

        map.addControl(control);

        return () => {
            map.removeControl(control);
        };
    }, [mapHoverState, setMapHoverState, map]);

    return null;
};

export default MapHoverButtonControl;