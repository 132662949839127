const ipkwBuildingTypes = ['EFH', 'MFH', 'GMFH', 'NWG'];
const ipkwYears = [1945, 1980, 2000, 2010, 2020];

const kwpNRWBuildingTypes = ['RH', 'EFH', 'MFH', 'GMFH', 'NWG'];
const kwpNrwYears = ['1900', '1945', '1960', '1970', '1980', '1985', '2015', '2015+'];

const yearColours = [
    [0, 1, 4, 6, 7],
    [0, 2, 4, 6, 8],
    [0, 2, 3, 5, 8],
    [1, 2, 4, 6, 8],
]

const eekMapping = [
    { sanierungstand: 0, eek: 'H', maxBedarf: 30, temperatureCSSIndex: 0 },
    { sanierungstand: 1, eek: 'G', maxBedarf: 50, temperatureCSSIndex: 1 },
    { sanierungstand: 2, eek: 'F', maxBedarf: 75, temperatureCSSIndex: 2 },
    { sanierungstand: 3, eek: 'E', maxBedarf: 100, temperatureCSSIndex: 3 },
    { sanierungstand: 4, eek: 'D', maxBedarf: 130, temperatureCSSIndex: 4 },
    { sanierungstand: 5, eek: 'C', maxBedarf: 160, temperatureCSSIndex: 5 },
    { sanierungstand: 6, eek: 'B', maxBedarf: 200, temperatureCSSIndex: 6 },
    { sanierungstand: 7, eek: 'A', maxBedarf: 250, temperatureCSSIndex: 7 },
    { sanierungstand: 8, eek: 'A+', maxBedarf: 250, temperatureCSSIndex: 8 },

]

const legendMapping = [
    { combinedType: 'WGB1945', baualter: 1945, waermebedarf: 150, temperatureScale: 2 },
    { combinedType: 'WGB1980', baualter: 1980, waermebedarf: 200, temperatureScale: 0 },
    { combinedType: 'WGB2000', baualter: 2000, waermebedarf: 160, temperatureScale: 1 },
    { combinedType: 'WGB2010A', baualter: 2010, waermebedarf: 100, temperatureScale: 3 },
    { combinedType: 'WGB2010B', baualter: 2010, waermebedarf: 70, temperatureScale: 4 },
    { combinedType: 'WGB2025A', baualter: 2025, waermebedarf: 55, temperatureScale: 5 },
    { combinedType: 'WGB2025B', baualter: 2025, waermebedarf: 40, temperatureScale: 6 },
    { combinedType: 'WGB2025C', baualter: 2025, waermebedarf: 25, temperatureScale: 7 },
    //
    { combinedType: 'SKG1945', baualter: 1945, waermebedarf: 140, temperatureScale: 0 },
    { combinedType: 'SKG2010A', baualter: 2010, waermebedarf: 70, temperatureScale: 2 },
    { combinedType: 'SKG2025A', baualter: 2025, waermebedarf: 40, temperatureScale: 4 },
    { combinedType: 'SKG2025B', baualter: 2025, waermebedarf: 0, temperatureScale: 7 },
    //
    { combinedType: 'HG1945', baualter: 1945, waermebedarf: 150, temperatureScale: 0 },
    { combinedType: 'HG2010A', baualter: 2010, waermebedarf: 80, temperatureScale: 2 },
    { combinedType: 'HG2025A', baualter: 2025, waermebedarf: 35, temperatureScale: 4 },
    { combinedType: 'HG2025B', baualter: 2025, waermebedarf: 0, temperatureScale: 7 },
    //
    { combinedType: 'BG1945', baualter: 1945, waermebedarf: 140, temperatureScale: 0 },
    { combinedType: 'BG2010A', baualter: 2010, waermebedarf: 80, temperatureScale: 2 },
    { combinedType: 'BG2025A', baualter: 2025, waermebedarf: 40, temperatureScale: 4 },
    { combinedType: 'BG2025B', baualter: 2025, waermebedarf: 0, temperatureScale: 7 },
    //
    { combinedType: 'GG1945', baualter: 1945, waermebedarf: 170, temperatureScale: 0 },
    { combinedType: 'GG2010A', baualter: 2010, waermebedarf: 120, temperatureScale: 2 },
    { combinedType: 'GG2025A', baualter: 2025, waermebedarf: 80, temperatureScale: 4 },
    { combinedType: 'GG2025B', baualter: 2025, waermebedarf: 0, temperatureScale: 7 },


];

const luetticherData = {
    'name': 'Lütticher',
    'quartier': {
        'buildingData': [
            {
                'year': 1980,
                'type': 'EFH',
                'blocks': 1,
            },
            {
                'year': 2000,
                'type': 'EFH',
                'blocks': 1,
            },
            {
                'year': 2020,
                'type': 'EFH',
                'blocks': 1,
            },
            {
                'year': 1945,
                'type': 'MFH',
                'blocks': 1,
            },
            {
                'year': 1980,
                'type': 'MFH',
                'blocks': 3,
            },
            {
                'year': 2000,
                'type': 'MFH',
                'blocks': 3,
            }
        ],
        'quartierData': {
            'nutzfläche': 54038.01174052108,
            'grundfläche': 25686.715295613016,
            'gesamtfläche': 136158.12455411808,
            'gesamtverbrauch': 8093512.521996767
        }
    },
    'geometry': [
        {
            'lat': 50.76144621216344,
            'lng': 6.0636385932535894
        },
        {
            'lat': 50.76084899355227,
            'lng': 6.065613411710672
        },
        {
            'lat': 50.76149371786205,
            'lng': 6.066482761140156
        },
        {
            'lat': 50.7644932657371,
            'lng': 6.069981624276096
        },
        {
            'lat': 50.76472399222451,
            'lng': 6.069412790698776
        },
        {
            'lat': 50.76483935504155,
            'lng': 6.068318054380178
        },
        {
            'lat': 50.765226157705285,
            'lng': 6.067588230167754
        },
        {
            'lat': 50.76481899691804,
            'lng': 6.066064185488896
        },
        {
            'lat': 50.7650429357895,
            'lng': 6.06574220421873
        },
        {
            'lat': 50.76494114552627,
            'lng': 6.0652699650224635
        },
        {
            'lat': 50.76472399222451,
            'lng': 6.065302163149507
        },
        {
            'lat': 50.76418110456078,
            'lng': 6.063874712851704
        },
        {
            'lat': 50.7635635621854,
            'lng': 6.062608253189002
        },
        {
            'lat': 50.762138433288904,
            'lng': 6.063874712851704
        },
        {
            'lat': 50.76199591801194,
            'lng': 6.063584929708543
        }
    ]
}

const preuswaldData = {
    'name': 'Preuswald',
    'quartier': {
        'buildingData': [
            {
                'year': 1980,
                'type': 'GMFH',
                'blocks': 9
            },
            {
                'year': 1980,
                'type': 'EFH',
                'blocks': 1
            }
        ],
        'quartierData': {
            'nutzfläche': 60721.97637979239,
            'grundfläche': 23118.47400908465,
            'gesamtfläche': null
        }
    },
    'geometry': [
        {
            'lat': 50.73894345076523,
            'lng': 6.040935826193139
        },
        {
            'lat': 50.737884230781944,
            'lng': 6.041268540172307
        },
        {
            'lat': 50.737857070980155,
            'lng': 6.041815908331625
        },
        {
            'lat': 50.737415721993884,
            'lng': 6.041891037294676
        },
        {
            'lat': 50.73752436213025,
            'lng': 6.042534999835008
        },
        {
            'lat': 50.7373614018312,
            'lng': 6.044187837021925
        },
        {
            'lat': 50.73744288205161,
            'lng': 6.044606412673184
        },
        {
            'lat': 50.73882123429751,
            'lng': 6.046119724643002
        },
        {
            'lat': 50.738794075039124,
            'lng': 6.046570498421265
        },
        {
            'lat': 50.74005696389093,
            'lng': 6.047396917014702
        },
        {
            'lat': 50.740172387517475,
            'lng': 6.047858423501969
        },
        {
            'lat': 50.740484708845266,
            'lng': 6.048062344973078
        },
        {
            'lat': 50.74097355543749,
            'lng': 6.0478047599569225
        },
        {
            'lat': 50.741319818686044,
            'lng': 6.047418382432746
        },
        {
            'lat': 50.7413401870327,
            'lng': 6.046913945109435
        },
        {
            'lat': 50.74019954597651,
            'lng': 6.045529425647676
        },
        {
            'lat': 50.74053223582125,
            'lng': 6.045368435012574
        },
        {
            'lat': 50.740729132779286,
            'lng': 6.043908786587766
        },
        {
            'lat': 50.74070197462739,
            'lng': 6.042513534417005
        },
        {
            'lat': 50.74057297319082,
            'lng': 6.041332936426357
        },
        {
            'lat': 50.74004338462203,
            'lng': 6.041075351410199
        },
        {
            'lat': 50.7396360047244,
            'lng': 6.040935826193139
        }
    ]
}

const ziegelweiherData = {
    'name': 'Ziegelweiher',
    'quartier': {
        'buildingData': [
            {
                'year': 2000,
                'type': 'EFH',
                'blocks': 3
            },
            {
                'year': 1980,
                'type': 'EFH',
                'blocks': 7
            }
        ],
        'quartierData': {
            'nutzfläche': 12958.942126104652,
            'grundfläche': 8483.751063437896,
            'gesamtfläche': null
        }
    },
    'geometry': [
        {
            'lat': 50.75706710365721,
            'lng': 6.106339383436149
        },
        {
            'lat': 50.75849578048428,
            'lng': 6.110363729477479
        },
        {
            'lat': 50.759116783177944,
            'lng': 6.1097895894422445
        },
        {
            'lat': 50.75973777763099,
            'lng': 6.1086681383453785
        },
        {
            'lat': 50.759079455379954,
            'lng': 6.107761319037413
        },
        {
            'lat': 50.75880458613246,
            'lng': 6.1064627967147445
        },
        {
            'lat': 50.75824126880286,
            'lng': 6.105985240984504
        },
        {
            'lat': 50.75787476954998,
            'lng': 6.106130117441975
        }
    ]
}


export const waermepumpen_map = [
  {
    option: 'lwp_mono',
    name: 'Monoblock Luftwärmepumpe',
    waermetraeger: 'Luft',
    bauweisen: 'Monoblock',
    buildings: ['MFH', 'EFH'],
    funktionsweise: 'Eine Monoblock Luftwärmepumpe nutzt die in der Außenluft vorhandene Wärmeenergie, um diese für die Heizung und Warmwasserbereitung zu verwenden. Alle Komponenten sind in einem einzigen Gerät (Monoblock) verbaut, welches außerhalb oder innerhalb des Gebäudes installiert werden kann. Die Wärme wird über einen Wärmetauscher an das Heizsystem abgegeben.',
    technikkenzahlen: {
      waermeleistung_auslegungstemp: '3-20 kW',
      scop_auslegungstemp: '2-5',
      vorlauftemperaturbereich_auslegungstemp: '35°C-65°C',
    },
    platzbedarf: [
      {
        title: 'Außeneinheit',
        description: 'Enthält den kompletten Kältemittelkreislauf. Inklusive Luftwechselraum vor der Wärmepumpe.',
        metersquared: 4,
      },
      {
        title: 'Leitungen',
        description: 'Verbindet Heizsystem direkt mit der Außeneinheit',
      },
      {
        title: 'Inneneinheit',
        description: 'Anbindung an Heizsystem',
        metersquared: 0,
      },
    ],
    gesetze: [
      {
        gesetz: 'GEG',
        beschreibung: 'Erfüllt die Anforderungen des Gebäudeenergiegesetzes',
      },
      {
        gesetz: 'Emissioneschutz NRW',
        beschreibung: 'Der Lärmpegel der Wärmepumpe darf nach 3m Abstand nicht mehr als 60dB betragen',
      },
    ],
    foerderungen: [
      {
        foerderung: 'BEG',
        beschreibung: 'Förderungen für Wärmeverteilung, Wärmeerzeugung, Wärmespeicherung, Steuer-, Mess-. Regelungstechnik, Wärmeübergabestationen. Bis zu 70% möglich',
      },
    ],
    emissionen: {
      emissionquellen: 'Stromerzeugung für Wärmepumpe',
      emissionsfaktor_je_kwh: '0,2-0,3 kg CO₂/kWh',
    },
    kosten: {
      anlagekosten: 20000,
      installationskosten: 15000,
      waermespeicherkosten: 2000,
      wartungskosten: 200,
      betriebskosten_je_kwh: 0.4,
    },
  },
  {
    option: 'lwp_split',
    name: 'Split Luftwärmepumpe',
    waermetraeger: 'Luft',
    bauweisen: 'Split',
    buildings: ['GMFH', 'MFH', 'EFH'],
    funktionsweise: 'Die Split Luftwärmepumpe besteht aus einer Innen- und einer Außeneinheit. Die Außeneinheit entzieht der Umgebungsluft Wärme und leitet diese über Kältemittelleitungen an die Inneneinheit weiter. In der Inneneinheit wird die Wärme an das Heizsystem übertragen. Dieses System ermöglicht eine flexible Installation und kann platzsparend sein.',
    technikkenzahlen: {
      waermeleistung_auslegungstemp: '3-15 kW',
      scop_auslegungstemp: '2.5-5',
      vorlauftemperaturbereich_auslegungstemp: '35°C-60°C',
    },
    platzbedarf: [
      {
        title: 'Außeneinheit',
        description: 'Enthält Verdampfer und Kompressor. Inklusive Luftwechselraum vor der Wärmepumpe.',
        metersquared: 4,
      },
      {
        title: 'Leitungen',
        description: 'Kältemittelleitungen zwischen Außen- und Inneneinheit',
      },
      {
        title: 'Inneneinheit',
        description: 'Enthält Kondensator und Regelungstechnik. Anbindung an Heizsystem',
        metersquared: 1,
      },
    ],
    gesetze: [
      {
        gesetz: 'GEG',
        beschreibung: 'Erfüllt die Anforderungen des Gebäudeenergiegesetzes',
      },
      {
        gesetz: 'Emissioneschutz NRW',
        beschreibung: 'Der Lärmpegel der Wärmepumpe darf nach 3m Abstand nicht mehr als 60dB betragen',
      },
    ],
    foerderungen: [
      {
        foerderung: 'BEG',
        beschreibung: 'Förderungen für Wärmeverteilung, Wärmeerzeugung, Wärmespeicherung, Steuer-, Mess-. Regelungstechnik, Wärmeübergabestationen. Bis zu 70% möglich',
      },
    ],
    emissionen: {
      emissionquellen: 'Stromerzeugung für Wärmepumpe',
      emissionsfaktor_je_kwh: '0,2-0,3 kg CO₂/kWh',
    },
    kosten: {
      anlagekosten: 999,
      installationskosten: 999,
      waermespeicherkosten: 999,
      wartungskosten: 999,
      betriebskosten_je_kwh: 999,
    },
  },
  {
    option: 'wwp_grundwasser',
    name: 'Grundwasser-Wärmepumpe',
    waermetraeger: 'Wasser',
    bauweisen: 'Grundwasser',
    buildings: ['GMFH', 'MFH', 'EFH'],
    funktionsweise: 'Die Grundwasser-Wärmepumpe nutzt die ganzjährig konstante Temperatur des Grundwassers als Wärmequelle. Durch einen Förderbrunnen wird das Grundwasser entnommen, die Wärme entzogen und anschließend über einen Schluckbrunnen wieder in den Grund geleitet. Die gewonnene Wärme wird an das Heizsystem des Gebäudes abgegeben.',
    technikkenzahlen: {
      waermeleistung_auslegungstemp: '5-100 kW',
      scop_auslegungstemp: '4-6',
      vorlauftemperaturbereich_auslegungstemp: '35°C-70°C',
    },
    platzbedarf: [
      {
        title: 'Außeneinheit',
        description: 'Förder- und Schluckbrunnen.',
        metersquared: 10,
      },
      {
        title: 'Leitungen',
        description: 'Verbindet Brunnenanlage Wasserleitungen mit der Wärmepumpe.',
      },
      {
        title: 'Inneneinheit',
        description: 'Enthält kompletten Kältemittelkreislauf. Anbindung an Heizsystem',
        metersquared: 2,
      },
    ],
    gesetze: [
      {
        gesetz: 'GEG',
        beschreibung: 'Erfüllt die Anforderungen des Gebäudeenergiegesetzes',
      },
      {
        gesetz: 'Emissioneschutz NRW',
        beschreibung: 'Der Lärmpegel der Wärmepumpe darf nach 3m Abstand nicht mehr als 60dB betragen',
      },
    ],
    foerderungen: [
      {
        foerderung: 'BEG',
        beschreibung: 'Förderungen für Wärmeverteilung, Wärmeerzeugung, Wärmespeicherung, Steuer-, Mess-. Regelungstechnik, Wärmeübergabestationen. Bis zu 70% möglich',
      },
    ],
    emissionen: {
      emissionquellen: 'Stromerzeugung für Wärmepumpe',
      emissionsfaktor_je_kwh: '0,2-0,3 kg CO₂/kWh',
    },
    kosten: {
      anlagekosten: 999,
      installationskosten: 999,
      waermespeicherkosten: 999,
      wartungskosten: 999,
      betriebskosten_je_kwh: 999,
    },
  },
  {
    option: 'wwp_abwasser',
    name: 'Abwasser-Wärmepumpe',
    waermetraeger: 'Wasser',
    bauweisen: 'Abwasser',
    buildings: ['GMFH', 'MFH', 'EFH'],
    funktionsweise: 'Die Abwasser-Wärmepumpe nutzt die im Abwasser enthaltene Wärmeenergie. Über Wärmetauscher wird dem Abwasser Wärme entzogen, die dann für Heizzwecke verwendet wird. Dieses System ermöglicht eine effiziente Nutzung von Abwärme, die ansonsten ungenutzt bliebe.',
    technikkenzahlen: {
      waermeleistung_auslegungstemp: '10-500 kW',
      scop_auslegungstemp: '4-6',
      vorlauftemperaturbereich_auslegungstemp: '35°C-70°C',
    },
    platzbedarf: [
      {
        title: 'Außeneinheit',
        description: 'Installation im Abwasserkanal zur Wärmeentnahme.',
        metersquared: 0,
      },
      {
        title: 'Leitungen',
        description: 'Verbindet Abwasseranlage Wasserleitungen mit der Wärmepumpe.',
      },
      {
        title: 'Inneneinheit',
        description: 'Enthält kompletten Kältemittelkreislauf. Anbindung an Heizsystem.',
        metersquared: 2,
      },
    ],
    gesetze: [
      {
        gesetz: 'GEG',
        beschreibung: 'Erfüllt die Anforderungen des Gebäudeenergiegesetzes',
      },
      {
        gesetz: 'Emissioneschutz NRW',
        beschreibung: 'Der Lärmpegel der Wärmepumpe darf nach 3m Abstand nicht mehr als 60dB betragen',
      },
    ],
    foerderungen: [
      {
        foerderung: 'BEG',
        beschreibung: 'Förderungen für Wärmeverteilung, Wärmeerzeugung, Wärmespeicherung, Steuer-, Mess-. Regelungstechnik, Wärmeübergabestationen. Bis zu 70% möglich',
      },
    ],
    emissionen: {
      emissionquellen: 'Stromerzeugung für Wärmepumpe',
      emissionsfaktor_je_kwh: '0,2-0,3 kg CO₂/kWh',
    },
    kosten: {
      anlagekosten: 999,
      installationskosten: 999,
      waermespeicherkosten: 999,
      wartungskosten: 999,
      betriebskosten_je_kwh: 999,
    },
  },
  {
    option: 'wwp_flusswasser',
    name: 'Flusswasser-Wärmepumpe',
    waermetraeger: 'Wasser',
    bauweisen: 'Flusswasser',
    buildings: ['GMFH', 'MFH'],
    funktionsweise: 'Die Flusswasser-Wärmepumpe entzieht Wärme aus Flüssen oder Seen. Über einen Wärmetauscher wird die im Wasser vorhandene Wärmeenergie aufgenommen und an das Heizsystem übertragen. Dieses System erfordert eine entsprechende Genehmigung und die Nähe zu einem geeigneten Gewässer.',
    technikkenzahlen: {
      waermeleistung_auslegungstemp: '10-500 kW',
      scop_auslegungstemp: '4-6',
      vorlauftemperaturbereich_auslegungstemp: '35°C-70°C',
    },
    platzbedarf: [
      {
        title: 'Außeneinheit',
        description: 'Installation im Fluss oder See zur Wärmeentnahme.',
        metersquared: 0,
      },
      {
        title: 'Leitungen',
        description: 'Verbindet Flusswasser Wasserleitungen mit der Wärmepumpe.',
      },
      {
        title: 'Inneneinheit',
        description: 'Enthält kompletten Kältemittelkreislauf. Anbindung an Heizsystem.',
        metersquared: 2,
      },
    ],
    gesetze: [
      {
        gesetz: 'GEG',
        beschreibung: 'Erfüllt die Anforderungen des Gebäudeenergiegesetzes',
      },
      {
        gesetz: 'Emissioneschutz NRW',
        beschreibung: 'Der Lärmpegel der Wärmepumpe darf nach 3m Abstand nicht mehr als 60dB betragen',
      },
    ],
    foerderungen: [
      {
        foerderung: 'BEG',
        beschreibung: 'Förderungen für Wärmeverteilung, Wärmeerzeugung, Wärmespeicherung, Steuer-, Mess-. Regelungstechnik, Wärmeübergabestationen. Bis zu 70% möglich',
      },
    ],
    emissionen: {
      emissionquellen: 'Stromerzeugung für Wärmepumpe',
      emissionsfaktor_je_kwh: '0,2-0,3 kg CO₂/kWh',
    },
    kosten: {
      anlagekosten: 999,
      installationskosten: 999,
      waermespeicherkosten: 999,
      wartungskosten: 999,
      betriebskosten_je_kwh: 999,
    },
  },
  {
    option: 'swp_erdsonde',
    name: 'Erdsonden-Wärmepumpe',
    waermetraeger: 'Sole',
    bauweisen: 'Erdsonde',
    buildings: ['GMFH', 'MFH'],
    funktionsweise: 'Die Erdsonden-Wärmepumpe nutzt die im Erdreich gespeicherte Wärme. Vertikale Sonden werden tief in den Boden gebohrt, um die konstante Erdwärme aufzunehmen. Die Wärme wird dann an das Heizsystem abgegeben. Dieses System ist effizient und platzsparend, da wenig Oberfläche benötigt wird.',
    technikkenzahlen: {
      waermeleistung_auslegungstemp: '5-50 kW',
      scop_auslegungstemp: '3.5-5',
      vorlauftemperaturbereich_auslegungstemp: '35°C-65°C',
    },
    platzbedarf: [
      {
        title: 'Außeneinheit',
        description: 'Vertikale Bohrungen für die Erdsonden. Flächenangabe je Bohrung. Wärmebedarf ist entscheidend für die Menge der Bohrungen, mindestens 2.',
        metersquared: 2,
      },
      {
        title: 'Leitungen',
        description: 'Verbindet Erdsonden mit der Wärmepumpe.',
      },
      {
        title: 'Inneneinheit',
        description: 'Enthält kompletten Kältemittelkreislauf. Anbindung an Heizsystem.',
        metersquared: 2,
      },
    ],
    gesetze: [
      {
        gesetz: 'GEG',
        beschreibung: 'Erfüllt die Anforderungen des Gebäudeenergiegesetzes',
      },
      {
        gesetz: 'Emissioneschutz NRW',
        beschreibung: 'Der Lärmpegel der Wärmepumpe darf nach 3m Abstand nicht mehr als 60dB betragen',
      },
    ],
    foerderungen: [
      {
        foerderung: 'BEG',
        beschreibung: 'Förderungen für Wärmeverteilung, Wärmeerzeugung, Wärmespeicherung, Steuer-, Mess-. Regelungstechnik, Wärmeübergabestationen. Bis zu 70% möglich',
      },
    ],
    emissionen: {
      emissionquellen: 'Stromerzeugung für Wärmepumpe',
      emissionsfaktor_je_kwh: '0,2-0,3 kg CO₂/kWh',
    },
    kosten: {
      anlagekosten: 999,
      installationskosten: 999,
      waermespeicherkosten: 999,
      wartungskosten: 999,
      betriebskosten_je_kwh: 999,
    },
  },
  {
    option: 'swp_flaechenkollektor',
    name: 'Flächenkollektor-Wärmepumpe',
    waermetraeger: 'Sole',
    bauweisen: 'Flächenkollektor',
    buildings: ['GMFH', 'MFH'],
    funktionsweise: 'Die Flächenkollektor-Wärmepumpe nutzt ebenfalls die Erdwärme, jedoch über horizontal im Boden verlegte Kollektoren. Die Kollektoren entziehen dem Boden die Wärme, die an das Heizsystem weitergegeben wird. Dieses System erfordert eine größere verfügbare Fläche für die Installation der Kollektoren.',
    technikkenzahlen: {
      waermeleistung_auslegungstemp: '5-30 kW',
      scop_auslegungstemp: '3-5',
      vorlauftemperaturbereich_auslegungstemp: '35°C-60°C',
    },
    platzbedarf: [
      {
        title: 'Außeneinheit',
        description: 'Horizontal verlegte Kollektoren im Boden. Wärmebedarf ist entscheidend für die Größe des Kollektors. Für ein Einfamilienhaus ist mit mindestens 200m² zu rechnen.',
        metersquared: 200,
      },
      {
        title: 'Leitungen',
        description: 'Verbindet Flächenkollektoren mit der Wärmepumpe.',
      },
      {
        title: 'Inneneinheit',
        description: 'Enthält kompletten Kältemittelkreislauf. Anbindung an Heizsystem.',
        metersquared: 2,
      },
    ],
    gesetze: [
      {
        gesetz: 'GEG',
        beschreibung: 'Erfüllt die Anforderungen des Gebäudeenergiegesetzes',
      },
      {
        gesetz: 'Emissioneschutz NRW',
        beschreibung: 'Der Lärmpegel der Wärmepumpe darf nach 3m Abstand nicht mehr als 60dB betragen',
      },
    ],
    foerderungen: [
      {
        foerderung: 'BEG',
        beschreibung: 'Förderungen für Wärmeverteilung, Wärmeerzeugung, Wärmespeicherung, Steuer-, Mess-. Regelungstechnik, Wärmeübergabestationen. Bis zu 70% möglich',
      },
    ],
    emissionen: {
      emissionquellen: 'Stromerzeugung für Wärmepumpe',
      emissionsfaktor_je_kwh: '0,2-0,3 kg CO₂/kWh',
    },
    kosten: {
      anlagekosten: 999,
      installationskosten: 999,
      waermespeicherkosten: 999,
      wartungskosten: 999,
      betriebskosten_je_kwh: 999,
    },
  },
];

export { yearColours, legendMapping, luetticherData, preuswaldData, ziegelweiherData, eekMapping, ipkwBuildingTypes, ipkwYears, kwpNRWBuildingTypes, kwpNrwYears };
