// pages/QuartierPage.js
import React, { useEffect, useState, memo } from 'react';
import QuartierMapLegend from '../../components/ipkw/quartier/QuartierMapLegend';
import QuartierMap from '../../components/ipkw/quartier/QuartierMap';
import InfoContainer from '../../components/ipkw/quartier/InfoContainer';
import '../../styles/ipkw/IPKW.css';
import '../../styles/ipkw/Sidebar.css';
import HoverCard from '../../components/ipkw/quartier/HoverCard';
import { useDashboardContext } from '../../context/DashboardContext';

const QuartierPage = memo(() => {
  const { mapHoverState, setMapHoverState } = useDashboardContext();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    }
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="page-content">

      <div className='quartier-page-container'>
        <div className='quartier-page-legend-map-container'>
          <HoverCard mapHoverState={mapHoverState} setMapHoverState={setMapHoverState} mousePosition={mousePosition} />
          <QuartierMapLegend />
          <QuartierMap />
        </div>
        <InfoContainer />
      </div>
    </div>
  );
});


export default QuartierPage;
