import React, { useState } from 'react';
import '../../../../styles/ipkw/Dashboard.css';
import '../../../../styles/ipkw/QuartierCard.css';

import gas from '../../../../images/gas.png';
// import gasSan from '../../../../images/gasSan.png';
import lwp from '../../../../images/lwp.png';
// import lwpSan from '../../../../images/lwpSan.png';
// import fwSan from '../../../../images/fwSan.png';
import fw from '../../../../images/fw.png';
import { useDashboardContext } from '../../../../context/DashboardContext';


const NullszenarioCard = () => {
    const { solutionBuilderData, setSolutionBuilderData } = useDashboardContext();

    const [selectedImageIndex, setSelectedImageIndex] = useState(solutionBuilderData.activeVergleichszenario);
    const [showOptions, setShowOptions] = useState(false);

    const images = [
        { src: gas, name: 'Gasheizungen' },
        // { src: gasSan, name: 'Gasheizungen + Sanieren' },
        { src: lwp, name: 'Luft-Wärmepumpen' },
        // { src: lwpSan, name: 'Luft-Wärmepumpen + Sanieren' },
        { src: fw, name: 'Fernwärme' },
        // { src: fwSan, name: 'Fernwärme + Sanieren' },
    ];

    const handleImageClick = () => {
        setShowOptions(!showOptions);
    };

    const handleOptionClick = (index) => {
        setSelectedImageIndex(index);
        setShowOptions(false);
        setSolutionBuilderData(prevData => ({ ...prevData, activeVergleichszenario: index }));

    };

    return (
        <div className='quartier-card-top-left'>
            <h3 className='inner-title'>Vergleichslösung</h3>
            <div className='square-image-container'>
                <img
                    src={images[selectedImageIndex].src}
                    alt={images[selectedImageIndex].name}
                    className='square-image'
                    onClick={handleImageClick}
                />
                <div className='image-name'>{images[selectedImageIndex].name}</div>
                {showOptions && (
                    <div className='image-options-popup-right'>
                        {images.map((img, index) => (
                            <div key={index} className='option-item' onClick={() => handleOptionClick(index)}>
                                <img
                                    src={img.src}
                                    alt={img.name}
                                    className='option-image'
                                />
                                <div className='option-name'>{img.name}</div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default NullszenarioCard;
