import React from 'react';

const CostConfig = () => {
    return (
        <div>
            <p>Konfiguration für Kosten</p>
        </div>
    );
};

export default CostConfig;
