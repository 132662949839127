// ConfigModal.js
import React, { useState } from 'react';
import '../../../../styles/ipkw/ConfigModal.css';

import CostConfig from './CostConfig';
import EmissionsConfig from './EmissionsConfig';

const ConfigModal = ({ onClose }) => {
    const [activeTab, setActiveTab] = useState(1); // Default to Tab 1

    const renderTabContent = () => {
        switch (activeTab) {
            case 1:
                return <CostConfig />;
            case 2:
                return <EmissionsConfig />;
            default:
                return null;
        }
    };

    return (
        <div className="modal-overlay-right">
            <div className="config-header">
                <div className="tab-buttons">
                    <button
                        className={activeTab === 1 ? 'tab-button active' : 'tab-button'}
                        onClick={() => setActiveTab(1)}
                    >
                        Kosten
                    </button>
                    <button
                        className={activeTab === 2 ? 'tab-button active' : 'tab-button'}
                        onClick={() => setActiveTab(2)}
                    >
                       Emissionen
                    </button>
                </div>
                <button className="close-button" onClick={onClose}>
                    X
                </button>
            </div>
            <div className="modal-content">
                {renderTabContent()}
            </div>
        </div>
    );
};

export default ConfigModal;
