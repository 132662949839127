import React from 'react';
import AppRoutes from '../routes/AppRoutes';
import NavBanner from './NavBanner.js';
import { DashboardProvider } from '../context/DashboardContext';

const AppLayout = () => {

  return (
    <>
      <DashboardProvider>

        <NavBanner />
        <AppRoutes />
      </DashboardProvider>

    </>
  );
}

export default AppLayout;
