import React, { useEffect, useRef } from 'react';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { MapContainer, TileLayer, GeoJSON, FeatureGroup, useMapEvents } from 'react-leaflet';
import { MIN_ZOOM_LEVEL, STARTING_AACHEN } from '../MapConsts';
import { getMapLegendStyle } from './Legend';
import { useDashboardContext } from '../../../context/DashboardContext';
import MapHoverButtonControl from './MapHoverButtonControl';

import '../../../styles/ipkw/IPKW.css';
import { useMap } from 'react-leaflet';
import { getBBForGeometry } from '../IPKWUtils';



const QuartierMap = React.memo(function QuartierMap() {
    const { selectedLegend, leafletState, setLeafletState, currentGeoJSONData, mapState, setMapState, mapHoverState, setMapHoverState, sidebarHoverState } = useDashboardContext();

    const position = leafletState ? [leafletState.bounds.getCenter().lat, leafletState.bounds.getCenter().lng] : STARTING_AACHEN;
    const zoom = leafletState ? leafletState.zoom : MIN_ZOOM_LEVEL;

    function MapState() {
        const map = useMapEvents({

            moveend() {
                const currentBounds = map.getBounds();

                setLeafletState({
                    zoom: map.getZoom(),
                    bounds: currentBounds,
                });
            },
            zoomlevelschange() {
                const currentBounds = map.getBounds();

                setLeafletState({
                    zoom: map.getZoom(),
                    bounds: currentBounds,
                });
            },

        });
        return null;
    }

    function setZoom(zoom) {
        setLeafletState({ ...leafletState, zoom });
    }

    const lastDrawnLayer = useRef(null);
    const currentGeoJSONDataRef = useRef(null);

    useEffect(() => {
        currentGeoJSONDataRef.current = currentGeoJSONData;
    }, [currentGeoJSONData]);

    function onEachFeature(feature, layer) {
        if (mapHoverState.hoverEnabled) {
            layer.on({
                mouseover: function (e) {

                    setMapHoverState(prevState => ({ ...prevState, hoverData: feature.properties  }));
                },
                mouseout: function (e) {
                    setMapHoverState(prevState => ({ ...prevState, hoverData: null }));
                },
            })
        }
        layer.on({
            click: function (e) {
                // setMapState(prevState => ({ ...prevState, selectedFeature: feature.properties.fest_id }));
            }
        });
    }
    function QuartierDataFeatures() {
        return (
            <GeoJSON
                data={currentGeoJSONData}
                onEachFeature={onEachFeature}
                style={(feature) => {
                    return getMapLegendStyle(selectedLegend, feature, zoom, sidebarHoverState);
                }
                }
            />
        );
    }

    const handleDelete = (e) => {
        lastDrawnLayer.current.remove();
    };

    function drawComplete(e) {

        if (lastDrawnLayer.current) {
            lastDrawnLayer.current.remove();
        }
        lastDrawnLayer.current = e.layer;
        setMapState(prevState => ({ ...prevState, geometry: lastDrawnLayer.current._latlngs[0] }));

    };

    function QuartierOutlineFeatures() {
        if (lastDrawnLayer.current) {
            lastDrawnLayer.current.remove();
        }
        const mapStateGeometry = mapState.geometry;
        if (!mapStateGeometry) {
            return null;
        }
        const geoJSON = {
            type: "Feature",
            properties: {},
            geometry: {
                type: "Polygon",
                coordinates: [mapStateGeometry.map(point => [point.lng, point.lat])]
            }
        };
        return (
            <GeoJSON
                data={geoJSON}
                style={{ fillOpacity: 0, zIndex:1 }}
            />
        );
    }



    return (
        <div className="quartier-map-container">

            <MapContainer center={position} zoom={zoom} zoomControl={false}   >
                <ZoomMapComponent mapState={mapState} />
                <TileLayer
                    url="https://map.nowum.fh-aachen.de/cartodb/light_all/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <MapState setZoom={setZoom} />
                <FeatureGroup>
                    <MapHoverButtonControl mapHoverState={mapHoverState} setMapHoverState={setMapHoverState} />
                    <EditControl
                        onCreated={drawComplete}
                        onDeleted={handleDelete}
                        edit={{
                            edit: false,
                            remove: true,
                        }}
                        draw={{
                            rectangle: {
                                shapeOptions: { fill: false },
                            },
                            polygon: {
                                shapeOptions: { fill: false },
                            },
                            circle: false,
                            circlemarker: false,
                            marker: false,
                            polyline: false,
                            line: false,
                        }}
                    />


                </FeatureGroup>
                <QuartierOutlineFeatures mapState={mapState} />
                <QuartierDataFeatures currentGeoJSONData={currentGeoJSONData} />
            </MapContainer>

        </div>
    );

});


function ZoomMapComponent({mapState}){
    const map = useMap(); 

    useEffect(() => {
        if (mapState.geometry) {
          const newBounds = getBBForGeometry(mapState.geometry);
          if (newBounds) {
            map.fitBounds(newBounds); 
          }
        }
      }, [mapState.geometry, map]); 
}

export default QuartierMap;