import React, { useState } from 'react';
import '../../../../styles/ipkw/Dashboard.css';
import '../../../../styles/ipkw/QuartierCard.css';

import lo_density from '../../../../images/lo_density.png';
import me_density from '../../../../images/me_density.png';
import hi_density from '../../../../images/hi_density.png';

const ModellQuartierCard = () => {

    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [showOptions, setShowOptions] = useState(false);

    const images = [
        { src: lo_density, name: 'Äußere Stadt' },
        { src: me_density, name: 'Innere Stadt' },
        { src: hi_density, name: 'Stadtzentrum' },
    ];

    const handleImageClick = () => {
        setShowOptions(!showOptions);
    };

    const handleOptionClick = (index) => {
        setSelectedImageIndex(index);
        setShowOptions(false);
    };

    return (
        <div className='quartier-card-top-left'>
            <h3 className='inner-title'>Siedlungsdichte</h3>
            <div className='square-image-container'>
                <img
                    src={images[selectedImageIndex].src}
                    alt={images[selectedImageIndex].name}
                    className='square-image'
                    onClick={handleImageClick}
                />
                <div className='image-name'>{images[selectedImageIndex].name}</div>
                {showOptions && (
                    <div className='image-options-popup-right'>
                        {images.map((img, index) => (
                            <div key={index} className='option-item' onClick={() => handleOptionClick(index)}>
                                <img
                                    src={img.src}
                                    alt={img.name}
                                    className='option-image'
                                />
                                <div className='option-name'>{img.name}</div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ModellQuartierCard;
