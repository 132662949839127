import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/NavBar.css';

const NavButton = ({ to, children, className }) => {
  return (
    <Link to={to} className={`nav-button ${className}`}>
      {children}
    </Link>
  );
};

export default NavButton;
