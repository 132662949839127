import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import '../../styles/wiki/wiki.css';
import mfhImage from '../../images/mfh.png';
import efhImage from '../../images/efh.png';
import gmfhImage from '../../images/gmfh.png';
import lwpImage from '../../images/lwp.png';
import wwpImage from '../../images/wwp.png';
import swwpImage from '../../images/swp.png';
import { waermepumpen_map } from '../../components/ipkw/IPKWConsts';


const waermetraeger_options = [
  { value: 'luft', label: 'Luft', image: lwpImage, bauweisen: ['Monoblock', 'Split'] },
  { value: 'wasser', label: 'Wasser', image: wwpImage, bauweisen: ['Grundwasser', 'Abwasser', 'Flusswasser'] },
  { value: 'sole', label: 'Sole', image: swwpImage, bauweisen: ['Erdsonde', 'Flächenkollektor'] },
];

const geb_typ_images = {
  MFH: mfhImage,
  EFH: efhImage,
  GMFH: gmfhImage,
};


const WaermepumpePage = () => {
  const [selectedWaermetraeger, setSelectedWaermetraeger] = useState(waermetraeger_options[0]);
  const [bauweisenOptions, setBauweisenOptions] = useState([]);
  const [selectedBauweise, setSelectedBauweise] = useState(waermetraeger_options[0].bauweisen[0]);
  const [selectedHeatPump, setSelectedHeatPump] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);

  useEffect(() => {
    const options = selectedWaermetraeger.bauweisen.map((bauweise) => ({
      value: bauweise.toLowerCase(),
      label: bauweise,
    }));
    setBauweisenOptions(options);
    setSelectedBauweise(options[0]);
  }, [selectedWaermetraeger]);

  useEffect(() => {
    console.log(selectedWaermetraeger, selectedBauweise, waermepumpen_map);
    const matchingHeatPump = waermepumpen_map.find(
      (pump) =>
        pump.waermetraeger.toLowerCase() === selectedWaermetraeger.value &&
        pump.bauweisen.toLowerCase() === selectedBauweise.value
    );

    setSelectedHeatPump(matchingHeatPump);

    if (matchingHeatPump) {
      setSelectedBuilding(matchingHeatPump.buildings[0]);
    } else {
      setSelectedBuilding(null);
    }
  }, [selectedWaermetraeger, selectedBauweise]);

  const handleBuildingChange = (building) => {
    setSelectedBuilding(building);
  };
  return (
    <div className="page-container">
      <div className="title-select-container">
        <h1>Auswahl Wärmepumpe</h1>
        <div className="selects-container">
          <div className="select-with-title">
            <p>Wärmeträger:</p>
            <Select
              value={selectedWaermetraeger}
              onChange={(option) => setSelectedWaermetraeger(option)}
              options={waermetraeger_options}
              className="select-component"
              placeholder="Wärmeträger auswählen"
            />
          </div>
          <div className="select-with-title">
            <p>Bauweise:</p>
            <Select
              value={selectedBauweise}
              onChange={(option) => setSelectedBauweise(option)}
              options={bauweisenOptions}
              className="select-component"
              placeholder="Bauweise auswählen"
            />

          </div>
        </div>
      </div>
      <div className="wiki-title-container">
        <h2>{selectedHeatPump ? selectedHeatPump.name : 'Wärmepumpen Informationen'}</h2>
      </div>

      <div className="wiki-overview-container">

        <div className="images-container">
          <div className="image-with-title">
            Wärmepumpe:
            <img
              src={selectedWaermetraeger.image}
              alt={selectedWaermetraeger.label}
              className="wiki-image"
            />
          </div>

          <div className="image-with-title">
            Anwendungsbereich:
            <div className="building-image-container">

              <img
                src={geb_typ_images[selectedBuilding]}
                alt={selectedBuilding}
                className="wiki-image"
              />
              {selectedHeatPump && selectedHeatPump.buildings.length > 1 && (
                <div className="building-buttons">
                  {selectedHeatPump.buildings.map((building) => (
                    <button
                      key={building}
                      onClick={() => handleBuildingChange(building)}
                      className={building === selectedBuilding ? 'active' : ''}
                    >
                      {building}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-container">
          <h3>Funktionsweise</h3>
          {selectedHeatPump && (
            <div className='desc-text'>{selectedHeatPump.funktionsweise}</div>
          )}
        </div>
      </div>
      {/* Fact Sheet */}
      <div className="fact-sheet-container">
        {selectedHeatPump && (
          <>
            {/* Technische Kennzahlen */}
            <div className="fact-sheet-item">
              <h3>Technische Kennzahlen</h3>
                  <br />
                  <strong>Wärmeleistung (bei Auslegungstemperatur):</strong>{' '}
                  <br />
                  {selectedHeatPump.technikkenzahlen.waermeleistung_auslegungstemp}
                  <br />
                  <strong>SCOP (bei Auslegungstemperatur):</strong>{' '}
                  <br />
                  {selectedHeatPump.technikkenzahlen.scop_auslegungstemp}

                  <br />
                  <strong>Vorlauftemperaturbereich (bei Auslegungstemperatur):</strong>{' '}
                  <br />
                  {selectedHeatPump.technikkenzahlen.vorlauftemperaturbereich_auslegungstemp}
            </div>

            {/* Platzbedarf */}
            <div className="fact-sheet-item">
              <h3>Platzbedarf</h3>
              {selectedHeatPump.platzbedarf.map((platz, index) => (
                <div key={index}>
                  <strong>{platz.title}:</strong>
                  {platz.description}
                  {platz.metersquared && (
                    <div>
                      <strong>Fläche:</strong> {platz.metersquared} m²
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* Gesetze */}
            <div className="fact-sheet-item">
              <h3>Gesetze</h3>
                {selectedHeatPump.gesetze.map((gesetz, index) => (
                  <div >
                    <strong>{gesetz.gesetz}:</strong> {gesetz.beschreibung}
                  </div>
                ))}
            </div>

            {/* Förderungen */}
            <div className="fact-sheet-item">
              <h3>Förderungen</h3>
                {selectedHeatPump.foerderungen.map((foerderung, index) => (
                  <div>

                    <strong>{foerderung.foerderung}:</strong> {foerderung.beschreibung}
                    <br />
                  </div>

                ))}
            </div>

            {/* Kosten */}
            <div className="fact-sheet-item">
              <h3>Kosten</h3>

              <strong>Anlagekosten:</strong> {selectedHeatPump.kosten.anlagekosten} €
              <br />
              <strong>Installationskosten:</strong> {selectedHeatPump.kosten.installationskosten} €
              <br />

              <strong>Wärmespeicherkosten:</strong> {selectedHeatPump.kosten.waermespeicherkosten} €
              <br />

              <strong>Wartungskosten:</strong> {selectedHeatPump.kosten.wartungskosten} € pro Jahr
              <br />
              <strong>Betriebskosten je kWh:</strong> {selectedHeatPump.kosten.betriebskosten_je_kwh} €

            </div>

            {/* Emissionen */}
            <div className="fact-sheet-item">
              <h3>Emissionen</h3>
                <strong>Emissionsquellen:</strong>
              <br />
              {selectedHeatPump.emissionen.emissionquellen}

              <br />
                <strong>Emissionsfaktor:</strong>{' '}
                <br />
                {selectedHeatPump.emissionen.emissionsfaktor_je_kwh}
            </div>
          </>
        )}
      </div>
      <div className="mini-calculator-container">
      </div>

    </div>
  );
};

export default WaermepumpePage;