// EmissionsConfig.js
import React from 'react';
import { useDashboardContext } from '../../../../context/DashboardContext';
import ConfigSlider from './ConfigSlider';
import '../../../../styles/ipkw/EmissionsConfig.css';

const EmissionsConfig = () => {
    const { scenarioConfig, setScenarioConfig } = useDashboardContext();

    const handleLwpCopChange = (newValue) => {
        setScenarioConfig((prevConfig) => ({
            ...prevConfig,
            lwpCop: newValue,
        }));
    };

    const handleSanierungsQuoteChange = (newValue) => {
        setScenarioConfig((prevConfig) => ({
            ...prevConfig,
            sanierungsQuoteYear: newValue,
        }));
    };
    const handleSanierungsImpactChange = (newValue) => {
        setScenarioConfig((prevConfig) => ({
            ...prevConfig,
            sanierungsEfficiency: newValue,
        }));
    };

    return (
        <div className="emissions-config">
            <ConfigSlider
                title="Luftwärmepumpe COP Wert"
                value={scenarioConfig.lwpCop || 1.0}
                min={1.0}
                max={10.0}
                step={0.1}
                onChange={handleLwpCopChange}
            />
            <ConfigSlider
                title="Sanierungsquote pro Jahr"
                value={scenarioConfig.sanierungsQuoteYear || 0.001}
                min={0.001}
                max={0.1}
                step={0.001}
                onChange={handleSanierungsQuoteChange}
            />
            <ConfigSlider
                title="Wärmebedarf Reduktionsfaktor bei Sanierung"
                value={scenarioConfig.sanierungsEfficiency || 0.1}
                min={0.01}
                max={0.5}
                step={0.01}
                onChange={handleSanierungsImpactChange}
            />
        </div>
    );
};

export default EmissionsConfig;
