import React from 'react';
import { useDashboardContext } from '../../../context/DashboardContext';
import BuildingTypes from './BuildingTypes';
import SanierungstandTypes from './SanierungsstandTypes';
import QuartierTypes from './QuartierTypes';
import InfoSwitcher from './InfoSwitcher';
import ResultingIPKWTypes from './ResultingIPKWTypes';

const InfoContainer = () => {
    const {sidebarState} = useDashboardContext();
    const renderTopInfo = () => {
        switch (sidebarState.infoSelection) {
          case 'buildingTypes':
            return <BuildingTypes/>;
          case 'sanierungstandTypes':
            return <SanierungstandTypes />;
          case 'districtType':
            return <QuartierTypes />;
          default:
            return <BuildingTypes />;
        }
      };
    
    return (
        <div className='quartier-sidebar-container'>
            <InfoSwitcher />
            {renderTopInfo()}
            <ResultingIPKWTypes />
        </div>
    );
};

export default InfoContainer;
