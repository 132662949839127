import { technikkatalog_jahre, technikkatalog_emissionen, luft_wp_einzel, grundwasser_wp_einzel, sonden_wp_einzel, kollektor_wp_einzel } from '../../NRWTKConsts';
import { bedarfMitSanierung, pickNRWTKRowByLeistung, energyToLeistung } from './CalculatorUtil';
import { chartYears,costsinEuroPerKwh } from '../../IPKWConsts';
import { getVerbrennungEnergieBedarfObjekt, getVerbrennungCo2, getVerbrennungLaufendeKosten } from './Verbrennung';
import { getStromdirektEnergieBedarfObjekt, getStromdirektCo2 } from './DirektHeizung';

export function getWPAndSpitzenlastCo2(gesamtVerbrauch, scenarioConfig, heatSource, spitzenLast, spitzenLastPercentage) {
    let dataNullszenario = [];
    const spitzenLastBedarf = gesamtVerbrauch * (spitzenLastPercentage / 100);
    const wpBedarf = gesamtVerbrauch - spitzenLastBedarf;
    const wpBedarfTimeline = getWPEnergieBedarfObjekt(wpBedarf, scenarioConfig, heatSource, chartYears);
    const emissionenWP = getWPCo2(wpBedarfTimeline, heatSource);
    if (spitzenLastBedarf === 0 || spitzenLast === 'Keine') {
        dataNullszenario = emissionenWP;
        return dataNullszenario;
    }

    if (spitzenLast === 'Gaskessel' || spitzenLast === 'Holzpellets') {
        const spitzenLastbedarfTimeline = getVerbrennungEnergieBedarfObjekt(spitzenLastBedarf, scenarioConfig, spitzenLast, chartYears);
        const emissionenSpitzenlast = getVerbrennungCo2(spitzenLastbedarfTimeline, spitzenLast);

        dataNullszenario = emissionenWP.map((item, idx) => {
            return {
                year: item.year,
                emission: item.emission + emissionenSpitzenlast[idx].emission,
            };
        });
    }

    if (spitzenLast === 'Stromdirekt') {
        const spitzenLastbedarfTimeline = getStromdirektEnergieBedarfObjekt(spitzenLastBedarf, scenarioConfig, chartYears);
        const emissionenSpitzenlast = getStromdirektCo2(spitzenLastbedarfTimeline);

        dataNullszenario = emissionenWP.map((item, idx) => {
            return {
                year: item.year,
                emission: item.emission + emissionenSpitzenlast[idx].emission,
            };
        });
    }
    return dataNullszenario;
}


export function getWPEnergieBedarfObjekt(gesamtVerbrauch, scenarioConfig, heatSource, years) {
    const leistung = energyToLeistung(gesamtVerbrauch);
    let dataRow;
    switch (heatSource) {
        case 'Luft Wärmepumpe':
            dataRow = pickNRWTKRowByLeistung(leistung, luft_wp_einzel);
            break;
        case 'Grundwasser Wärmepumpe':
            dataRow = pickNRWTKRowByLeistung(leistung, grundwasser_wp_einzel);
            break;
        case 'Erdsonden Wärmepumpe':
            dataRow = pickNRWTKRowByLeistung(leistung, sonden_wp_einzel);
            break;
        case 'Flächenkollektor Wärmepumpe':
            dataRow = pickNRWTKRowByLeistung(leistung, kollektor_wp_einzel);
            break;
        case 'Gewässer Wärmepumpe':
            dataRow = pickNRWTKRowByLeistung(leistung, grundwasser_wp_einzel);
            break;
        case 'Abwasser Wärmepumpe':
            dataRow = pickNRWTKRowByLeistung(leistung, grundwasser_wp_einzel);
            break;
        default:
            dataRow = pickNRWTKRowByLeistung(leistung, luft_wp_einzel);
            break;
    }
    const jazUnsaniert = dataRow.jazUnsaniert;
    const wpElectricityNeeded = gesamtVerbrauch / jazUnsaniert;
    return bedarfMitSanierung(
        wpElectricityNeeded,
        scenarioConfig.sanierungsQuoteYear,
        scenarioConfig.sanierungsEfficiency,
        years
    );


}

export function getWPCo2(bedarfTimeline) {
    
    const emissionData = technikkatalog_emissionen.find(
        (item) => item.energietraeger === 'Strom-Mix-D'
    );
    if (!emissionData) {
        console.error('Strom-Mix-D emission data not found');
        return [];
    }

    const years = bedarfTimeline.map((item) => item.year);

    const emissionFactors = {};

    years.forEach((year) => {
        const index = technikkatalog_jahre.indexOf(year);
        if (index !== -1) {
            emissionFactors[year] = emissionData.emissionen[index] / 1000;
        } else {
            emissionFactors[year] =
                emissionData.emissionen[emissionData.emissionen.length - 1] / 1000;
        }
    });

    const wpEmissions = bedarfTimeline.map((item) => {
        const emissionFactor = emissionFactors[item.year];
        const emissions = emissionFactor * item.requiredBedarf;
        return {
            year: item.year,
            emission: emissions,
        };
    });


    return wpEmissions;
}

export function getWPAndSpitzenlastKosten(gesamtVerbrauch, scenarioConfig, heatSource, spitzenLast, spitzenLastPercentage) {
    let kosten = [];
    const spitzenLastBedarf = gesamtVerbrauch * (spitzenLastPercentage / 100); // Calculate peak load demand
    const wpBedarf = gesamtVerbrauch - spitzenLastBedarf; // Remaining demand for the heat pump

    // Calculate heat pump electricity demand
    const wpBedarfTimeline = getWPEnergieBedarfObjekt(wpBedarf, scenarioConfig, heatSource, chartYears);
    const wpKosten = getWPKosten(wpBedarfTimeline);
    console.log('wpKosten', wpKosten);

    if (spitzenLastBedarf === 0 || spitzenLast === 'Keine') {
        // No peak load, return only heat pump costs
        kosten = wpKosten;
        return kosten;
    }

    if (spitzenLast === 'Gaskessel' || spitzenLast === 'Holzpellets') {
        // Calculate peak load costs for gas or pellets
        const spitzenLastbedarfTimeline = getVerbrennungEnergieBedarfObjekt(spitzenLastBedarf, scenarioConfig, spitzenLast, chartYears);
        const spitzenLastKosten = getVerbrennungLaufendeKosten(spitzenLastbedarfTimeline, spitzenLast);

        // Combine heat pump and peak load costs
        kosten = wpKosten.map((item, idx) => {
            return {
                year: item.year,
                cost: item.cost + spitzenLastKosten[idx].cost,
            };
        });
    }

    if (spitzenLast === 'Stromdirekt') {
        // Calculate peak load costs for direct electricity
        const spitzenLastbedarfTimeline = getStromdirektEnergieBedarfObjekt(spitzenLastBedarf, scenarioConfig, chartYears);
        const spitzenLastKosten = getStromdirektKosten(spitzenLastbedarfTimeline);

        // Combine heat pump and peak load costs
        kosten = wpKosten.map((item, idx) => {
            return {
                year: item.year,
                cost: item.cost + spitzenLastKosten[idx].cost,
            };
        });
    }
    return kosten;
}

export function getWPKosten(bedarfTimeline) {
    // Find the cost data for electricity (Strom)
    const electricityCostData = costsinEuroPerKwh;

    return bedarfTimeline.map((item) => {
        // Find the cost for the current year
        const costData = electricityCostData.find((cost) => cost.year === item.year);
        if (!costData) {
            console.error(`Cost data not found for year: ${item.year}`);
            return {
                year: item.year,
                cost: 0, // Default to 0 if cost data is missing
            };
        }

        // Calculate the running costs for the heat pump
        const cost = item.requiredBedarf * costData.strom;

        return {
            year: item.year,
            cost: cost,
        };
    });
}

export function getStromdirektKosten(bedarfTimeline) {
    // Find the cost data for electricity (Strom)
    const electricityCostData = costsinEuroPerKwh;

    return bedarfTimeline.map((item) => {
        // Find the cost for the current year
        const costData = electricityCostData.find((cost) => cost.year === item.year);
        if (!costData) {
            console.error(`Cost data not found for year: ${item.year}`);
            return {
                year: item.year,
                cost: 0, // Default to 0 if cost data is missing
            };
        }

        // Calculate the running costs for direct electricity
        const cost = item.requiredBedarf * costData.strom;

        return {
            year: item.year,
            cost: cost,
        };
    });
}