import React from 'react';

const SzenarienPage = () => {
  return (
    <div>
      <h1>Szenarien</h1>
      <p>This is a page about different scenarios in the use of renewable energy.</p>
    </div>
  );
}

export default SzenarienPage;
