import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/home/CardStyles.css'; // Importing shared CSS
import QuartierIcon  from '../../images/QuartierIcon.png';

const QuartierPageNav = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('quartier'); 
  };

  return (
    <div className="card"  onClick={handleClick}>
      <img src={QuartierIcon} alt="Wiki Icon" className="card-icon" />
      <h2>iPKW Spiel Dashboard</h2>
      <p>Auf dieser Seite ist der Spiel-Companion zu dem iPKW Projekt zu finden, mit dem man für ein Quartier eine Wärmelösung bauen und berechnen kann.</p>
    </div>
  );
};

export default QuartierPageNav;
