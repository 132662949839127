import React, { useState} from 'react';
import '../../../../styles/ipkw/Dashboard.css';
import '../../../../styles/ipkw/QuartierCard.css';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import { useDashboardContext } from '../../../../context/DashboardContext';


function createBedarfTimeline(gesamtVerbrauch, sanierungsquote, sanierungsimpact) {
    const lowerEachYearBy = gesamtVerbrauch * sanierungsquote * sanierungsimpact;
    const data = [
        { year: 2025, requiredBedarf: gesamtVerbrauch },
        { year: 2030, requiredBedarf: gesamtVerbrauch - (lowerEachYearBy * 5) },
        { year: 2035, requiredBedarf: gesamtVerbrauch - (lowerEachYearBy * 10) },
        { year: 2040, requiredBedarf: gesamtVerbrauch - (lowerEachYearBy * 15) },
        { year: 2045, requiredBedarf: gesamtVerbrauch - (lowerEachYearBy * 20) },
    ];
    return data;
}



function getDummy() {

    const dataNullszenario = [
        { year: 2025, nullSzenario: 70, solutionBuilder: 0 },
        { year: 2030, nullSzenario: 70, solutionBuilder: 0 },
        { year: 2035, nullSzenario: 70, solutionBuilder: 0 },
        { year: 2040, nullSzenario: 70, solutionBuilder: 0 },
        { year: 2045, nullSzenario: 60, solutionBuilder: 0 },
    ];
    return dataNullszenario;
}

function getGasHeizungCo2(gesamtVerbrauch, scenarioConfig) {
    const nutzungsgradHeizkessel = 0.9;
    const gasBedarfHeizwert = gesamtVerbrauch / nutzungsgradHeizkessel;
    const bedarfTimeline = createBedarfTimeline(gasBedarfHeizwert, scenarioConfig.sanierungsQuoteYear, scenarioConfig.sanierungsEfficiency);


    const emissionsfaktorErdgasHeizwertbezogen = 0.202;


    const dataNullszenario = bedarfTimeline.map((item) => {
        return {
            year: item.year, nullSzenario: emissionsfaktorErdgasHeizwertbezogen * item.requiredBedarf, solutionBuilder: 0
        };
    });

    return dataNullszenario;
}

function getLWPCo2(gesamtVerbrauch, scenarioConfig) {
    const nutzungsgradHeizkessel = scenarioConfig.lwpCop;
    const stromBedarfWP = gesamtVerbrauch / nutzungsgradHeizkessel;
    const bedarfTimeline = createBedarfTimeline(stromBedarfWP, scenarioConfig.sanierungsQuoteYear, scenarioConfig.sanierungsEfficiency);

    const emissionsfaktorStromnetz2025 = 0.4; //Fest
    const emissionsfaktorStromnetz2030 = 0.16; //Fest
    const emissionsfaktorStromnetz2045 = 0; //Fest
    const emissionsfaktorStromnetz2035 = emissionsfaktorStromnetz2030 + ( emissionsfaktorStromnetz2045 - emissionsfaktorStromnetz2030 ) / 3;
    const emissionsfaktorStromnetz2040 = emissionsfaktorStromnetz2030 + 2 * (emissionsfaktorStromnetz2045 - emissionsfaktorStromnetz2030) / 3;

    let dataNullszenario = [];

    dataNullszenario.push({ year: 2025, nullSzenario: emissionsfaktorStromnetz2025 * bedarfTimeline[0].requiredBedarf, solutionBuilder: 0 });
    dataNullszenario.push({ year: 2030, nullSzenario: emissionsfaktorStromnetz2030 * bedarfTimeline[1].requiredBedarf, solutionBuilder: 0 });
    dataNullszenario.push({ year: 2035, nullSzenario: emissionsfaktorStromnetz2035 * bedarfTimeline[2].requiredBedarf, solutionBuilder: 0 });
    dataNullszenario.push({ year: 2040, nullSzenario: emissionsfaktorStromnetz2040 * bedarfTimeline[3].requiredBedarf, solutionBuilder: 0 });
    dataNullszenario.push({ year: 2045, nullSzenario: emissionsfaktorStromnetz2045 * bedarfTimeline[4].requiredBedarf, solutionBuilder: 0 });


    return dataNullszenario;
}

function getFWCo2(gesamtVerbrauch, scenarioConfig) {
    const netzVerlust = 0.15;
    const netzBedarfBrutto = gesamtVerbrauch * (1 + netzVerlust);
    const bedarfTimeline = createBedarfTimeline(netzBedarfBrutto, scenarioConfig.sanierungsQuoteYear, scenarioConfig.sanierungsEfficiency);

    const gruenesNetz2030 = 0.3;
    const gruenesNetz2040 = 0.8;
    const gruenesNetz2045 = 1;


    const emissionsfaktorStawag2025 = 0.254;
    const emissionsfaktorStawag2030 =emissionsfaktorStawag2025 - (emissionsfaktorStawag2025 - ( emissionsfaktorStawag2025 * (1 - gruenesNetz2030)))/2;
    const emissionsfaktorStawag2035 = emissionsfaktorStawag2025 * (1 - gruenesNetz2030);
    const emissionsfaktorStawag2040 = emissionsfaktorStawag2025 * (1 - gruenesNetz2040);
    const emissionsfaktorStawag2045 = emissionsfaktorStawag2025 * (1 - gruenesNetz2045);



    let dataNullszenario = [];

    dataNullszenario.push({ year: 2025, nullSzenario: emissionsfaktorStawag2025 * bedarfTimeline[0].requiredBedarf, solutionBuilder: 0 });
    dataNullszenario.push({ year: 2030, nullSzenario: emissionsfaktorStawag2030 * bedarfTimeline[1].requiredBedarf, solutionBuilder: 0 });
    dataNullszenario.push({ year: 2035, nullSzenario: emissionsfaktorStawag2035 * bedarfTimeline[2].requiredBedarf, solutionBuilder: 0 });
    dataNullszenario.push({ year: 2040, nullSzenario: emissionsfaktorStawag2040 * bedarfTimeline[3].requiredBedarf, solutionBuilder: 0 });
    dataNullszenario.push({ year: 2045, nullSzenario: emissionsfaktorStawag2045 * bedarfTimeline[4].requiredBedarf, solutionBuilder: 0 });

    return dataNullszenario;
}


function getAllCo2Data(gesamtVerbrauch, scenarioConfig) {
    const years = [2025, 2030, 2035, 2040, 2045];
    let data = years.map(year => ({ year }));

    const gasHeizungData = getGasHeizungCo2(gesamtVerbrauch, scenarioConfig);
    const lwpData = getLWPCo2(gesamtVerbrauch, scenarioConfig);
    const fwData = getFWCo2(gesamtVerbrauch, scenarioConfig);

    gasHeizungData.forEach((item, idx) => {
        data[idx]["Gasheizungen"] = item.nullSzenario;
    });

    lwpData.forEach((item, idx) => {
        data[idx]["Luft-Wärmepumpen"] = item.nullSzenario;
    });

    fwData.forEach((item, idx) => {
        data[idx]["Fernwärme"] = item.nullSzenario;
    });

    return data;
}
const Co2Chart = () => {
    const { ipkwQuartierData ,scenarioConfig} = useDashboardContext();

    const maxDomain = ipkwQuartierData.quartierData.gesamtverbrauch? Math.ceil(ipkwQuartierData.quartierData.gesamtverbrauch / 2.5e6) * 1e6 : 5e6;
    const data = getAllCo2Data(ipkwQuartierData.quartierData.gesamtverbrauch,scenarioConfig);

    const options = ['Gasheizungen', 'Luft-Wärmepumpen', 'Fernwärme']; 
    const colors = ['#ff3300', '#8884d8', '#82ca9d']; 
    const colorsdark = ['#B32400', '#3D3B61', '#528063']; 

    const initialLineVisibility = options.reduce(
        (acc, img) => ({ ...acc, [img]: true }),
        {}
    );
    initialLineVisibility['Wärmelösung'] = true; 
    const [lineVisibility, setLineVisibility] = useState(initialLineVisibility);

    const legendPayload = [
        ...options.map((img, index) => ({
            value: img,
            type: 'line',
            id: img,
            dataKey: img,
            color: colors[index % colors.length],
            darkColor: colorsdark[index % colors.length],
        })),
        {
            value: 'Wärmelösung',
            type: 'line',
            id: 'Wärmelösung',
            dataKey: 'solutionBuilder',
            color: '#000000',
            darkColor: '#949494',
        },
    ];

    const handleLegendClick = (dataKey) => {
        setLineVisibility((prevState) => ({
            ...prevState,
            [dataKey]: !prevState[dataKey],
        }));
    };

    const renderCustomizedLegend = (props) => {
        const { payload } = props;
        return (
            <ul
                className="legend-list"

            >
                {payload.map((entry, index) => (
                    <li
                        key={`item-${index}`}
                        onClick={() => handleLegendClick(entry.dataKey)}
                        style={{
                            cursor: 'pointer',
                            marginRight: 10,
                        }}
                    >
                        <span
                            style={{

                                color: lineVisibility[entry.dataKey]
                                    ? entry.color
                                    : entry.darkColor,                            }}
                        >
                            {entry.value}
                        </span>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className="status-quo-charts">
            <div className="chart-container">
                <div className="chart-label">
                    GT CO<sub>2</sub>/a
                </div>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={data}>
                        <Legend content={renderCustomizedLegend} payload={legendPayload} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="year" />
                        <YAxis
                            tickFormatter={(value) => `${value / 1e6} GT`}
                            domain={[0, maxDomain]}
                        />
                        <Tooltip formatter={(value) => `${(value / 1e6).toFixed(2)} GT`} />

                        {options.map((img, index) =>
                            lineVisibility[img] ? (
                                <Line
                                    key={img}
                                    name={img}
                                    type="monotone"
                                    dataKey={img}
                                    stroke={colors[index % colors.length]}
                                    isAnimationActive={false} 

                                />
                            ) : null
                        )}
                        {lineVisibility['Wärmelösung'] && (
                            <Line
                                name="Wärmelösung"
                                type="monotone"
                                dataKey="solutionBuilder"
                                stroke="#000000"
                                isAnimationActive={false} 

                            />
                        )}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default Co2Chart;