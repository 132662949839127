import React from 'react';
import '../../styles/ipkw/Dashboard.css';
import QuartierCard from '../../components/ipkw/dashboard/quartierOverview/QuartierCard';
import SolutionBuilder from '../../components/ipkw/dashboard/heatSolutionBuilder/SolutionBuilder';
import Co2Chart from '../../components/ipkw/dashboard/charts/Co2/Co2Chart';
import MoneyChart from '../../components/ipkw/dashboard/charts/Money/MoneyChart';
import ScenarioSelector from '../../components/ipkw/dashboard/charts/ScenarioSelector';

const DashboardPage = () => {
  return (
    <div className="page-content">

    <div className='dashboard-container'>
      <div className='dashboard-flex-container'>
        <div className='left-section'>
          <QuartierCard />
          <SolutionBuilder />
        </div>
        <div className='right-section'>
          <ScenarioSelector />
          <Co2Chart />
          <MoneyChart />

        </div>
      </div>
    </div>
    </div>
  );
};

export default DashboardPage;
