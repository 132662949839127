import React from 'react';
import '../../../../styles/ipkw/ConfigModal.css';

const FernModal = ({ onClose }) => {
  return (
    <div className='modal-overlay-right'>
      <div className='config-header'>
        <div className='tab-buttons'>
          <div className='tab-header'>Fernwärmelösung konfigurieren</div>
        </div>
        <button className='close-button' onClick={onClose}>
          X
        </button>
      </div>
      <div className='modal-content'>
        {/* Display the preset settings */}
        <div className='select-group'>
          <label>Fernwärmeanbieter:</label>
          <input
            type='text'
            value='Fernwärmeanbieter Aachen'
            readOnly
          />
        </div>
        <div className='select-group'>
          <label>Wärmequelle:</label>
          <input
            type='text'
            value='Kohlekraftwerk Verbrennung'
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

export default FernModal;
