import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/home/CardStyles.css'; // Importing shared CSS
import MapIcon  from '../../images/MapIcon.png';

const MapPageNav = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('map'); 
  };

  return (
    <div className="card"  onClick={handleClick}>
      <img src={MapIcon} alt="Wiki Icon" className="card-icon" />
      <h2>iPKW Map</h2>
      <p>Auf dieser Seite ist der eine Karte mit Informationen relevant zu der Kommunalen Wärmewende in NRW zu finden. Auch kan man sich hier ein eigenes Quartier zusammenstellen um es in dem iPKW Spiel zu benutzen.</p>
    </div>
  );
};

export default MapPageNav;
