import React from 'react';

const GEGPage = () => {
  return (
    <div>
      <h1>GEG</h1>
      <img src={require('../../images/240813_Zeitlinien.png')} alt="Zeitlinie" style={{ maxWidth: '100%', height: 'auto' }} />
    </div>
  );
}

export default GEGPage;
