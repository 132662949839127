
import { costsinEuroPerKwh } from '../../../IPKWConsts';
import { bedarfMitSanierung } from '../CalculatorUtil';

export function getNetzMoney(ipkwQuartierData, scenarioConfig) {
    if (!ipkwQuartierData || !ipkwQuartierData.quartierData || !ipkwQuartierData.buildingData) {
        console.error('No ipkwQuartierData or missing data');
        return [];
    }

    const baseDemand = ipkwQuartierData.quartierData.gesamtverbrauch; // MWh/a
    const years = [2025, 2030, 2035, 2040, 2045];

    const bedarfTimeline = bedarfMitSanierung(
        baseDemand,
        scenarioConfig.sanierungsQuoteYear,
        scenarioConfig.sanierungsEfficiency,
        years
    );


    return bedarfTimeline.map((item) => {
        // Find the cost data for the current year
        const costData = costsinEuroPerKwh.find((cost) => cost.year === item.year);
        if (!costData) {
            console.error(`Cost data not found for year: ${item.year}`);
            return {
                year: item.year,
                costs: 0, // Default to 0 if cost data is missing
            };
        }

        // Get the cost per kWh for the energy carrier
        const costPerKwh = costData["aachen_fw"];

        // Calculate the running costs for the year
        const runningCosts = item.requiredBedarf * costPerKwh;

        return {
            year: item.year,
            cost: runningCosts,
        };
    });
}
