import {
  ipkwBuildingTypes,
  ipkwYears,
  yearColours,
} from '../ipkw/IPKWConsts';

export function getBBForGeometry(geometry) {
    const lats = geometry.map(latlng => latlng.lat);
    const lngs = geometry.map(latlng => latlng.lng);
  
    const minLat = Math.min(...lats);
    const maxLat = Math.max(...lats);
    const minLng = Math.min(...lngs);
    const maxLng = Math.max(...lngs);
  
    return [[minLat, minLng], [maxLat, maxLng]];
  }

export function isPointInPolygon(point, polygon) {
    const [y, x] = point;
    let inside = false;
  
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const [xi, yi] = polygon[i];
      const [xj, yj] = polygon[j];
  
      const intersect = ((yi > y) !== (yj > y)) && 
                        (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
  
    return inside;
  }
  
  export function getPolygonArea(polygon) {
    let area = 0;
    const numPoints = polygon.length;

    for (let i = 0; i < numPoints; i++) {
        const [x1, y1] = polygon[i];
        const [x2, y2] = polygon[(i + 1) % numPoints]; 

        area += (x1 * y2) - (x2 * y1);
    }

    area = Math.abs(area / 2);

    const averageLatitude = polygon.reduce((sum, point) => sum + point[1], 0) / numPoints;
    const latInRadians = averageLatitude * (Math.PI / 180); 

    const metersPerDegreeLat = 111320; 
    const metersPerDegreeLng = 111320 * Math.cos(latInRadians); 

    const areaInSquareMeters = area * metersPerDegreeLat * metersPerDegreeLng;

    return areaInSquareMeters;
}

export const buildingGroups = {
  'EFH': ['RH', 'EFH'],
  '1945': ['1900', '1945'],
  '1980': ['1960', '1970', '1980'],
  '2000': ['1985'],
  '2010': ['2015'],
  '2020': ['2015+'],
};

export const reverseBuilidingGroups = {
  'RH': 'EFH',
  'EFH': 'EFH',
  '1900': '1945',
  '1945': '1945',
  '1960': '1980',
  '1970': '1980',
  '1980': '1980',
  '1985': '2000',
  '2015': '2010',
  '2015+': '2020',
};

export const getGroupForType = type => {
  for (const key in buildingGroups) {
    if (buildingGroups[key].includes(type)) {
      return buildingGroups[key];
    }
  }
  return [type];
};

export const getGroupForYear = year => {
  return buildingGroups[year] || [year];
};

export const getReverseBuildingGroupFoType = type => {
  return reverseBuilidingGroups[type];
};

export const getReverseYearGroupForYear = year => {
  return reverseBuilidingGroups[year];
};

export function getColorBlockList(blockList, index) {
  if (index === -1) {
    return { border: '3px solid var(--gray50)' }
  }
  if (blockList[index]) {
    const yearIndex = ipkwYears.indexOf(blockList[index].year);
    const typeIndex = ipkwBuildingTypes.indexOf(blockList[index].type);
    const coloridx = yearColours[typeIndex][yearIndex];
    const backgroundColor = 'var(--temperature-scale-' + coloridx + ')';
    const textColor = coloridx < 5 ? 'var(--white)' : 'var(--black)';
    return { border: '3px solid ' + backgroundColor, backgroundColor: backgroundColor, color: textColor, fontSize: '0.8rem' };
  }
};
