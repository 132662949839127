import React from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import MapPageNav from '../components/ipkw/MapPageNav';
import QuartierPageNav from '../components/ipkw/QuartierPageNav';
import '../styles/home/CardStyles.css';

const IPKWHomePage = () => {
  const location = useLocation();
  const urlSplit = location.pathname.split('/').filter(Boolean);

  return (
    <div className="page-content">
      {location.pathname === '/dashboard' ? <div className='page-title'>IPKW Home Page</div> : null}
      {urlSplit.length === 1 &&
        <div className="card-container">
          <MapPageNav />
          <QuartierPageNav />
        </div>
      }
      <Outlet />
    </div>
  );
}

export default IPKWHomePage;
