import {  wpg_bestehendes_netz } from '../../../IPKWConsts';
import {
    technikkatalog_jahre,
    technikkatalog_emissionen,
    transportleitungen,
    verteilnetze
} from '../../../NRWTKConsts';
import { bedarfMitSanierung,pickNRWTKRowByLeistung, getWPGRenewableFractionForYear,  energyToLeistung } from '../CalculatorUtil';

export function getNetzCo2(ipkwQuartierData, scenarioConfig, currentWlGeoJSONData,scenarios) {
    if (!ipkwQuartierData || !ipkwQuartierData.quartierData || !ipkwQuartierData.buildingData || !currentWlGeoJSONData) {
        console.error('No ipkwQuartierData or missing data');
        return [];
    }

    const baseDemand = ipkwQuartierData.quartierData.gesamtverbrauch; // MWh/a
    const years = [2025, 2030, 2035, 2040, 2045];

    const bedarfTimeline = bedarfMitSanierung(
        baseDemand,
        scenarioConfig.sanierungsQuoteYear,
        scenarioConfig.sanierungsEfficiency,
        years
    );

    const peakLoadkW = energyToLeistung(baseDemand) * 1000;
    const peakLoadMW = peakLoadkW / 1000;

    const totalLengthMeters = currentWlGeoJSONData.reduce(
        (sum, feat) => sum + (feat?.properties?.strassenlaenge || 0),
        0
    );
    const totalLengthKM = totalLengthMeters / 1000.0;

    const netzRow = pickNRWTKRowByLeistung(peakLoadkW, verteilnetze);
    const stationLossPct = netzRow.waermeverlusteUebergabestationen;
    const pumpPct = netzRow.verbrauchHilfsenergie;

    const transportRow = pickNRWTKRowByLeistung(peakLoadMW, transportleitungen);
    const transportLossPctPerKM = transportRow.waermeverlusteLeitungen;

    const carrier = 'Steinkohle';
    const emissionData = technikkatalog_emissionen.find(e => e.energietraeger === carrier);
    if (!emissionData) {
        console.error('No emission data for netz heat carrier:', carrier);
        return [];
    }
    const stromData = technikkatalog_emissionen.find(e => e.energietraeger === 'Strom-Mix-D');
    if (!stromData) {
        console.error('Strom-Mix-D data not found');
        return [];
    }

    const yearsAvailable = technikkatalog_jahre;
    const carrierEF = {};
    const stromEF = {};

    years.forEach(y => {
        const idx = yearsAvailable.indexOf(y);
        const cVal = idx !== -1
            ? emissionData.emissionen[idx]
            : emissionData.emissionen[emissionData.emissionen.length - 1];
        const sVal = idx !== -1
            ? stromData.emissionen[idx]
            : stromData.emissionen[stromData.emissionen.length - 1];

        carrierEF[y] = cVal / 1000; // in t/MWh
        stromEF[y] = sVal / 1000;   // in t/MWh
    });

    const result = bedarfTimeline.map(item => {
        const { year, requiredBedarf } = item;

        const stationLossMWh = (requiredBedarf * stationLossPct) / 100.0;
        const totalTransportLossPct = (transportLossPctPerKM / 100.0) * totalLengthKM;
        const transportLossMWh = requiredBedarf * totalTransportLossPct;

        const totalLossMWh = stationLossMWh + transportLossMWh;
        const totalHeatNeeded = requiredBedarf + totalLossMWh;

        const pumpMWh = (totalHeatNeeded * pumpPct) / 100.0;
        const wpg_scenario = scenarios.includes("Wärmeplanungsgesetz Halbiert");
        const renewablePercent = getWPGRenewableFractionForYear(year, wpg_bestehendes_netz,wpg_scenario);
        const renewableFraction = renewablePercent / 100.0; // 0..1

        const steinkohleEF = carrierEF[year];  // t/MWh
        const efEffective = steinkohleEF * (1 - renewableFraction);

        const lossCO2 = totalHeatNeeded * efEffective;

        const pumpCO2 = pumpMWh * stromEF[year];

        const netzCO2 = lossCO2 + pumpCO2;

        return {
            year: year,
            emission: netzCO2,
        };
    });

    return result;
}
