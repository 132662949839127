import React from 'react';
import WikiPage from '../components/home/WikiPageNav';
import IpkwPage from '../components/home/IpkwPageNav';
import '../styles/home/CardStyles.css'; 
import KartePageNav from '../components/home/KartePageNav';
import QuartierPageNav from '../components/home/QuartierPageNav';
import AboutPage from './AboutPage';

const HomePage = () => {
  return (

    <AboutPage/>
  );
}

export default HomePage;
