export const effizienzGasKessel = 0.95;
export const effizienzPelletsKessel = 0.9;


export const ipkwBuildingTypes = ['EFH', 'MFH', 'GMFH', 'NWG'];
export const ipkwYears = [1945, 1980, 2000, 2010, 2020];

export const kwpNRWBuildingTypes = ['RH', 'EFH', 'MFH', 'GMFH', 'NWG'];
export const kwpNrwYears = ['1900', '1945', '1960', '1970', '1980', '1985', '2015', '2015+'];

export const yearColours = [
    [0, 1, 4, 6, 7],
    [0, 2, 4, 6, 8],
    [0, 2, 3, 5, 8],
    [1, 2, 4, 6, 8],
]

export const eekMapping = [
    { sanierungstand: 0, eek: 'H', maxBedarf: 30, temperatureCSSIndex: 0 },
    { sanierungstand: 1, eek: 'G', maxBedarf: 50, temperatureCSSIndex: 1 },
    { sanierungstand: 2, eek: 'F', maxBedarf: 75, temperatureCSSIndex: 2 },
    { sanierungstand: 3, eek: 'E', maxBedarf: 100, temperatureCSSIndex: 3 },
    { sanierungstand: 4, eek: 'D', maxBedarf: 130, temperatureCSSIndex: 4 },
    { sanierungstand: 5, eek: 'C', maxBedarf: 160, temperatureCSSIndex: 5 },
    { sanierungstand: 6, eek: 'B', maxBedarf: 200, temperatureCSSIndex: 6 },
    { sanierungstand: 7, eek: 'A', maxBedarf: 250, temperatureCSSIndex: 7 },
    { sanierungstand: 8, eek: 'A+', maxBedarf: 250, temperatureCSSIndex: 8 },

]

export const legendMapping = [
    { combinedType: 'WGB1945', baualter: 1945, waermebedarf: 150, temperatureScale: 2 },
    { combinedType: 'WGB1980', baualter: 1980, waermebedarf: 200, temperatureScale: 0 },
    { combinedType: 'WGB2000', baualter: 2000, waermebedarf: 160, temperatureScale: 1 },
    { combinedType: 'WGB2010A', baualter: 2010, waermebedarf: 100, temperatureScale: 3 },
    { combinedType: 'WGB2010B', baualter: 2010, waermebedarf: 70, temperatureScale: 4 },
    { combinedType: 'WGB2025A', baualter: 2025, waermebedarf: 55, temperatureScale: 5 },
    { combinedType: 'WGB2025B', baualter: 2025, waermebedarf: 40, temperatureScale: 6 },
    { combinedType: 'WGB2025C', baualter: 2025, waermebedarf: 25, temperatureScale: 7 },
    //
    { combinedType: 'SKG1945', baualter: 1945, waermebedarf: 140, temperatureScale: 0 },
    { combinedType: 'SKG2010A', baualter: 2010, waermebedarf: 70, temperatureScale: 2 },
    { combinedType: 'SKG2025A', baualter: 2025, waermebedarf: 40, temperatureScale: 4 },
    { combinedType: 'SKG2025B', baualter: 2025, waermebedarf: 0, temperatureScale: 7 },
    //
    { combinedType: 'HG1945', baualter: 1945, waermebedarf: 150, temperatureScale: 0 },
    { combinedType: 'HG2010A', baualter: 2010, waermebedarf: 80, temperatureScale: 2 },
    { combinedType: 'HG2025A', baualter: 2025, waermebedarf: 35, temperatureScale: 4 },
    { combinedType: 'HG2025B', baualter: 2025, waermebedarf: 0, temperatureScale: 7 },
    //
    { combinedType: 'BG1945', baualter: 1945, waermebedarf: 140, temperatureScale: 0 },
    { combinedType: 'BG2010A', baualter: 2010, waermebedarf: 80, temperatureScale: 2 },
    { combinedType: 'BG2025A', baualter: 2025, waermebedarf: 40, temperatureScale: 4 },
    { combinedType: 'BG2025B', baualter: 2025, waermebedarf: 0, temperatureScale: 7 },
    //
    { combinedType: 'GG1945', baualter: 1945, waermebedarf: 170, temperatureScale: 0 },
    { combinedType: 'GG2010A', baualter: 2010, waermebedarf: 120, temperatureScale: 2 },
    { combinedType: 'GG2025A', baualter: 2025, waermebedarf: 80, temperatureScale: 4 },
    { combinedType: 'GG2025B', baualter: 2025, waermebedarf: 0, temperatureScale: 7 },


];

export const spitzenLastOptions = [
    'Holzpellets',
    'Gaskessel',
    'Stromdirekt',
    'keine',
]

export const netzSonderPotenziale = [
    'Abwärme',
    'Kraft-Wärme-Kopplung',
]


export const einzel_loesung_options = [
    {
        solutionClass: 'Wärmepumpe',
        options: [
            {
                heatSource: 'Luft Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Grundwasser Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Gewässer Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Abwasser Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Erdsonden Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Flächenkollektor Wärmepumpe',
                spitzenLastBedarf: true,
            },
        ]
    },
    {
        solutionClass: 'Verbrennung',
        options: [
            {
                heatSource: 'Holzpellets',
                spitzenLastBedarf: false,
            },
            {
                heatSource: 'Gaskessel',
                spitzenLastBedarf: false,
            },
        ],
    },
]




export const solution_builder_options = [
    {
        solutionClass: 'Fern',
        options: [
            {
                heatSource: 'Fernwärme Anbieter',
                spitzenLastBedarf: false,
            }
        ]
    },
    {
        solutionClass: 'Netz',
        options: [
            {
                heatSource: 'Luft Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Grundwasser Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Gewässer Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Abwasser Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Erdsonden Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Flächenkollektor Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Geothermie',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Holzpellets',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Gaskessel',
                spitzenLastBedarf: false,
            },

        ]
    },
    {
        solutionClass: 'Objekt',
        options: [
            {
                heatSource: 'Luft Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Grundwasser Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Gewässer Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Abwasser Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Erdsonden Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Flächenkollektor Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Holzpellets',
                spitzenLastBedarf: false,
            },
            {
                heatSource: 'Gaskessel',
                spitzenLastBedarf: false,
            },
            {
                heatSource: 'Stromdirekt',
                spitzenLastBedarf: false,
            },
        ]
    }
]




export const luetticherData = {
    'name': 'Lütticher',
    'quartier': {
        'buildingData': [
            {
                'year': 1980,
                'type': 'EFH',
                'blocks': 1,
                'buildingCount': 5,
            },
            {
                'year': 2000,
                'type': 'EFH',
                'blocks': 1,
                'buildingCount': 5,
            },
            {
                'year': 2020,
                'type': 'EFH',
                'blocks': 1,
                'buildingCount': 5,
            },
            {
                'year': 1945,
                'type': 'MFH',
                'blocks': 1,
                'buildingCount': 10,
            },
            {
                'year': 1980,
                'type': 'MFH',
                'blocks': 3,
                'buildingCount': 35,
            },
            {
                'year': 2000,
                'type': 'MFH',
                'blocks': 3,
                'buildingCount': 35,
            }
        ],
        'quartierData': {
            'nutzfläche': 54038.01174052108,
            'grundfläche': 25686.715295613016,
            'gesamtfläche': 136158.12455411808,
            'gesamtverbrauch': 8093512.521996767
        }
    },
    'geometry': [
        {
            'lat': 50.76144621216344,
            'lng': 6.0636385932535894
        },
        {
            'lat': 50.76084899355227,
            'lng': 6.065613411710672
        },
        {
            'lat': 50.76149371786205,
            'lng': 6.066482761140156
        },
        {
            'lat': 50.7644932657371,
            'lng': 6.069981624276096
        },
        {
            'lat': 50.76472399222451,
            'lng': 6.069412790698776
        },
        {
            'lat': 50.76483935504155,
            'lng': 6.068318054380178
        },
        {
            'lat': 50.765226157705285,
            'lng': 6.067588230167754
        },
        {
            'lat': 50.76481899691804,
            'lng': 6.066064185488896
        },
        {
            'lat': 50.7650429357895,
            'lng': 6.06574220421873
        },
        {
            'lat': 50.76494114552627,
            'lng': 6.0652699650224635
        },
        {
            'lat': 50.76472399222451,
            'lng': 6.065302163149507
        },
        {
            'lat': 50.76418110456078,
            'lng': 6.063874712851704
        },
        {
            'lat': 50.7635635621854,
            'lng': 6.062608253189002
        },
        {
            'lat': 50.762138433288904,
            'lng': 6.063874712851704
        },
        {
            'lat': 50.76199591801194,
            'lng': 6.063584929708543
        }
    ]
}

export const preuswaldData = {
    'name': 'Preuswald',
    'quartier': {
        'buildingData': [
            {
                'year': 1980,
                'type': 'GMFH',
                'blocks': 9,
                'buildingCount': 53,
            },
            {
                'year': 1980,
                'type': 'EFH',
                'blocks': 1,
                'buildingCount': 34,
            }
        ],
        'quartierData': {
            'nutzfläche': 60721.97637979239,
            'grundfläche': 23118.47400908465,
            'gesamtfläche': null
        }
    },
    'geometry': [
        {
            'lat': 50.73894345076523,
            'lng': 6.040935826193139
        },
        {
            'lat': 50.737884230781944,
            'lng': 6.041268540172307
        },
        {
            'lat': 50.737857070980155,
            'lng': 6.041815908331625
        },
        {
            'lat': 50.737415721993884,
            'lng': 6.041891037294676
        },
        {
            'lat': 50.73752436213025,
            'lng': 6.042534999835008
        },
        {
            'lat': 50.7373614018312,
            'lng': 6.044187837021925
        },
        {
            'lat': 50.73744288205161,
            'lng': 6.044606412673184
        },
        {
            'lat': 50.73882123429751,
            'lng': 6.046119724643002
        },
        {
            'lat': 50.738794075039124,
            'lng': 6.046570498421265
        },
        {
            'lat': 50.74005696389093,
            'lng': 6.047396917014702
        },
        {
            'lat': 50.740172387517475,
            'lng': 6.047858423501969
        },
        {
            'lat': 50.740484708845266,
            'lng': 6.048062344973078
        },
        {
            'lat': 50.74097355543749,
            'lng': 6.0478047599569225
        },
        {
            'lat': 50.741319818686044,
            'lng': 6.047418382432746
        },
        {
            'lat': 50.7413401870327,
            'lng': 6.046913945109435
        },
        {
            'lat': 50.74019954597651,
            'lng': 6.045529425647676
        },
        {
            'lat': 50.74053223582125,
            'lng': 6.045368435012574
        },
        {
            'lat': 50.740729132779286,
            'lng': 6.043908786587766
        },
        {
            'lat': 50.74070197462739,
            'lng': 6.042513534417005
        },
        {
            'lat': 50.74057297319082,
            'lng': 6.041332936426357
        },
        {
            'lat': 50.74004338462203,
            'lng': 6.041075351410199
        },
        {
            'lat': 50.7396360047244,
            'lng': 6.040935826193139
        }
    ]
}

export const ziegelweiherData = {
    'name': 'Ziegelweiher',
    'quartier': {
        'buildingData': [
            {
                'year': 2000,
                'type': 'EFH',
                'blocks': 3,
                'buildingCount': 19,
            },
            {
                'year': 1980,
                'type': 'EFH',
                'blocks': 7,
                'buildingCount': 58,
            }
        ],
        'quartierData': {
            'nutzfläche': 12958.942126104652,
            'grundfläche': 8483.751063437896,
            'gesamtfläche': null
        }
    },
    'geometry': [
        {
            'lat': 50.75706710365721,
            'lng': 6.106339383436149
        },
        {
            'lat': 50.75849578048428,
            'lng': 6.110363729477479
        },
        {
            'lat': 50.759116783177944,
            'lng': 6.1097895894422445
        },
        {
            'lat': 50.75973777763099,
            'lng': 6.1086681383453785
        },
        {
            'lat': 50.759079455379954,
            'lng': 6.107761319037413
        },
        {
            'lat': 50.75880458613246,
            'lng': 6.1064627967147445
        },
        {
            'lat': 50.75824126880286,
            'lng': 6.105985240984504
        },
        {
            'lat': 50.75787476954998,
            'lng': 6.106130117441975
        }
    ]
}

export const auslegungstemp = -10;
export const vollnutzungstunden_wohn = 2100;


export const building_map = [
    {
        name: 'EFH',
        leistungsbereich_l: '3',
        leistungsbereich_h: '20',
        beispielleistung: '12',
    },
    {
        name: 'MFH',
        leistungsbereich_l: '30',
        leistungsbereich_h: '150',
        beispielleistung: '60',
    },
    {
        name: 'GMFH',
        leistungsbereich_l: '200',
        leistungsbereich_h: '2000',
        beispielleistung: '1000',
    },
    {
        name: 'Netz',
        leistungsbereich_l: '5000',
        leistungsbereich_h: '100000',
        beispielleistung: '50000',
    },
]




