import React, { useState } from 'react';
import '../../../../styles/ipkw/Dashboard.css';
import '../../../../styles/ipkw/SolutionBuilder.css';

const SolutionCard = () => {
  const [art, setArt] = useState('');
  const [hauptwaermequelle, setHauptwaermequelle] = useState('');
  const [spitzenlast, setSpitzenlast] = useState('');

  const handleArtChange = (e) => {
    const selectedArt = e.target.value;
    setArt(selectedArt);

    // Reset hauptwaermequelle and spitzenlast when art changes
    if (selectedArt === 'Fernwärmenetz') {
      setHauptwaermequelle('Fernwärme Anbieter');
      setSpitzenlast('');
    } else {
      setHauptwaermequelle('');
      setSpitzenlast('');
    }
  };

  const handleHauptwaermequelleChange = (e) => {
    const selectedHauptwaermequelle = e.target.value;
    setHauptwaermequelle(selectedHauptwaermequelle);

    if (!['Flächenkollektor', 'Erdsonde', 'Geothermie'].includes(selectedHauptwaermequelle)) {
      setSpitzenlast('');
    }
  };

  let hauptwaermequelleOptions = [];

  if (art === 'Nahwärmenetz') {
    hauptwaermequelleOptions = ['Flächenkollektor', 'Erdsonde', 'Geothermie', 'Holzpellets', 'Gas'];
  } else if (art === 'Einzellösung') {
    hauptwaermequelleOptions = ['Flächenkollektor', 'Erdsonde', 'Geothermie', 'Luftwärmepumpe', 'Holzpellets', 'Gas']; 
  }

  const showSpitzenlast = ['Flächenkollektor', 'Erdsonde', 'Geothermie'].includes(hauptwaermequelle);
  const showSpitzenlastEinzel = ['Flächenkollektor', 'Erdsonde', 'Geothermie', 'Luftwärmepumpe'].includes(hauptwaermequelle);

  return (
    <div className='solution-card-content'>
      <div className='sb-left-section'>
        <div className='select-group'>
          <label htmlFor='art-select'>Art</label>
          <select id='art-select' value={art} onChange={handleArtChange}>
            <option value=''>Bitte wählen</option>
            <option value='Fernwärmenetz'>Fernwärmenetz</option>
            <option value='Nahwärmenetz'>Nahwärmenetz</option>
            <option value='Einzellösung'>Einzellösung</option>
          </select>
        </div>
        <div className='select-group'>
          <label htmlFor='hauptwaermequelle-select'>Hauptwärmequelle</label>
          {art === 'Fernwärmenetz' ? (
            <input type='text' value='Fernwärme Anbieter' readOnly />
          ) : (
            <select
              id='hauptwaermequelle-select'
              value={hauptwaermequelle}
              onChange={handleHauptwaermequelleChange}
            >
              <option value=''>Bitte wählen</option>
              {hauptwaermequelleOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
        </div>
        {(showSpitzenlast  || showSpitzenlastEinzel) && (
          <div className='select-group'>
            <label htmlFor='spitzenlast-select'>Spitzenlast</label>
            <select
              id='spitzenlast-select'
              value={spitzenlast}
              onChange={(e) => setSpitzenlast(e.target.value)}
            >
              <option value=''>Bitte wählen</option>
              <option value='Holzpellets'>Holzpellets</option>
              <option value='Gas'>Gas</option>
              <option value='Direktheizung'>Direktheizung</option>
              <option value='Keins'>Keins</option>
            </select>
          </div>
        )}
      </div>
      <div className='sb-right-section'>
        <p>Organisationsform: {art || '...'}</p>
        <p>Verantwortung: {hauptwaermequelle || '...'}</p>
        <p>Akteure: {spitzenlast || '...'}</p>
        <p>Finanzierung: ...</p>
        <p>Technik: ...</p>
        <p>Betrieb: ...</p>
      </div>
    </div>
  );
};

export default SolutionCard;
