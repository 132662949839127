import React from 'react';
import '../../../styles/ipkw/IPKW.css';
import '../../../styles/ipkw/IPKWMapLegend.css';
import '../../../styles/ipkw/IPKWMap.css';
import { absoluteLegend, specificLegend, legendOptions } from './Legend';
import { useDashboardContext } from '../../../context/DashboardContext';

const QuartierMapLegend = () => {
    const { selectedLegend, setSelectedLegend } = useDashboardContext();
    let displayLegend = null;
    let currentUnit = '';

    switch (selectedLegend) {
        case 'absoluteLegend':
            displayLegend = absoluteLegend;
            break;
        case 'specificLegend':
            displayLegend = specificLegend;
            break;
        default:
            displayLegend = specificLegend;
    }

    // Assuming each legend object has a 'unit' property
    if (displayLegend.length > 0) {
        currentUnit = displayLegend[0].unit; // Display unit of the first legend item
    }

    return (
        <div className="map-legend-component-container">
            <div className="map-legend-selector-container">
                <div className="map-legend-selector-header">Legende</div>
                <select
                    value={selectedLegend}
                    onChange={(e) => setSelectedLegend(e.target.value)}
                    className="map-legend-selector"
                >
                    {legendOptions.map((option, index) => (
                        <option key={index} value={option.legendName}>
                            {option.name} {currentUnit}
                        </option>
                    ))}
                </select>
            </div>

            <div className="map-legend-container">
                <div className="map-legend">
                    {displayLegend.map((legendItem, index) => (
                        <div key={index} className="map-legend-column">
                            <div
                                className="map-legend-color"
                                style={{ backgroundColor: `var(--temperature-scale-${legendItem.temperatureCSSIndex})` }}
                            ></div>
                            <div className='map-legend-text'>
                                {legendItem.text}
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default QuartierMapLegend;
