import React from 'react';
import { useNavigate } from 'react-router-dom';

import '../../styles/home/CardStyles.css'; // Importing shared CSS
import IPKWIcon  from '../../images/IPKWIcon.png';
const IpkwPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/dashboard'); 
  };
  return (
    <div className="card" onClick={handleClick}>
      <img src={IPKWIcon} alt="Ipkw Icon" className="card-icon" />
      <h2>iPKW Dashboard</h2>
      <p>Dies ist die iPKW-Dashboard-Seite. Sie enthält die Dashboard die zu dem iPKW Spiel und zu hilfe der Kommunalen Wärmewende benutzt werden kann.</p>
    </div>
  );
};

export default IpkwPage;
