import React, { useMemo, useState } from 'react';
import { useDashboardContext } from '../../../context/DashboardContext';
import { buildingTypesColorMap } from './Legend';
import {
  kwpNRWBuildingTypes,
  kwpNrwYears,
} from '../IPKWConsts';
import { getGroupForType, getGroupForYear } from '../IPKWUtils';


const getYearGroup = (year) => {
  if (year <= 1900) return "1900";
  if (year <= 1945) return "1945";
  if (year <= 1960) return "1960";
  if (year <= 1970) return "1970";
  if (year <= 1980) return "1980";
  if (year <= 1985) return "1985";
  if (year <= 2015) return "2015";
  return "2015+"; // for all kwpNrwYears greater than 2015
};


const BuildingTypes = () => {
  const { currentGeoJSONData, sidebarHoverState, setSidebarHoverState } = useDashboardContext();
  const [hoverState, setHoverState] = useState({ year: null, type: null });


  const getBorderColor = (year, type) => {
    if (sidebarHoverState.resultsHover.year) {
      const { year: hoverYear, type: hoverType } = sidebarHoverState.resultsHover;
      const hoverTypeGroup = getGroupForType(hoverType);
      const hoverYearGroup = getGroupForYear(hoverYear);
      if (hoverTypeGroup.includes(type) && hoverYearGroup.includes(year)) {
        return '2px solid var(--colora2)';
      }
    } else if (hoverState.year === year && hoverState.type === type) {
      return '2px solid var(--colora2)';
    }
  };

  const handleHover = (hoverState) => {
    setHoverState(hoverState);
    setSidebarHoverState(prevState => ({
      ...prevState,
      buildingHover: { year: hoverState.year, type: hoverState.type },
      resultsHover: { year: null, type: null }
    }));
  };


  const { data } = useMemo(() => {
    if (!currentGeoJSONData) return { data: [], totalHeatDemand: 0 };
    let overallHeatDemand = 0.0;
    const counts = kwpNRWBuildingTypes.reduce((acc, type) => {
      acc[type] = kwpNrwYears.reduce((yearAcc, year) => {
        yearAcc[year] = { count: 0, heatDemand: 0.0 };
        return yearAcc;
      }, {});
      return acc;
    }, {});

    currentGeoJSONData.forEach(feature => {
      const gebaeudetyp = feature.properties.gebaeudetyp;
      const heat_demand = feature.properties.rw_ww;
      if (gebaeudetyp && heat_demand) {
        const parts = gebaeudetyp.split('_');
        const type = parts[0];
        const year = parseInt(parts[1]);
        const yearGroup = getYearGroup(year);
        if (kwpNRWBuildingTypes.includes(type) && yearGroup) {
          counts[type][yearGroup].count += 1;
          counts[type][yearGroup].heatDemand += heat_demand;
          overallHeatDemand += heat_demand;
        }
      }
    });

    const data = kwpNrwYears.flatMap(year => {
      return kwpNRWBuildingTypes.map(type => ({
        year,
        type,
        percentage: (counts[type][year].heatDemand / overallHeatDemand) * 100
      }));
    });
    return { data, totalHeatDemand: overallHeatDemand };
  }, [currentGeoJSONData]);

  const tableData = useMemo(() => kwpNrwYears.map(year => {
    const rowData = kwpNRWBuildingTypes.map(type => {
      const item = data.find(d => d.year === year && d.type === type);
      const percentage = item ? item.percentage : 0;
      return {
        type,
        percentage,
        size: Math.max(5, percentage)
      };
    });
    return { year, rowData };
  }), [data]);

  const getColor = (year, type) => {
    const yearGroup = getYearGroup(parseInt(year));
    for (let i = 0; i < buildingTypesColorMap.length; i++) {
      if (buildingTypesColorMap[i].age === yearGroup && buildingTypesColorMap[i].type === type) {
        return buildingTypesColorMap[i].color;
      }
    }
  }


  return (
    <div className='quartier-sidebar-info-card'>
        <div className='info-label'>Wärmebedarf Verteilung</div>
      <table className='building-type-table'>
        <thead>
          <tr>
            <th >Type/Year</th>
            {kwpNrwYears.map(year => <th key={year}>{year}</th>)}
          </tr>
        </thead>
        <tbody>
          {kwpNRWBuildingTypes.map(type => (
            <tr key={type}>
              <td >{type}</td>
              {tableData.map(({ year, rowData }) => {
                const cell = rowData.find(r => r.type === type);
                return (
                  <td key={year} className='building-cell'
                    onMouseEnter={() => handleHover({ year: year, type: type })}
                    onMouseLeave={() => handleHover({ year: null, type: null })}
                    style={{ border: getBorderColor(year, type) }}>
                    <div

                      style={{
                        width: `${Math.min((cell.size * 2), 100)}%`,
                        height: `${Math.min((cell.size * 2), 100)}%`,
                        backgroundColor: getColor(year, type),
                      }} className="percentage-square"></div>
                    <div className="text-wrapper">{cell.percentage.toFixed(1)}%</div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BuildingTypes;