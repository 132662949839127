import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import '../../styles/wiki/wiki.css';
import mfhImage from '../../images/mfh.png';
import efhImage from '../../images/efh.png';
import gmfhImage from '../../images/gmfh.png';
import netzImage from '../../images/netz.png';
import lwpImage from '../../images/lwp.png';
import wwpImage from '../../images/wwp.png';
import swwpImage from '../../images/swp.png';
import { lwp_split_einzel_view } from '../../components/wiki/lwp_split_einzel_view';
import { lwp_kompress_netz_view } from '../../components/wiki/lwp_kompress_netz_view';
import { swp_einzel_view } from '../../components/wiki/swp_einzel_view';
import { wwp_grundwasser_einzel_view } from '../../components/wiki/wwp_grundwasser_einzel_view';
import { wwp_abwasser_netz_view } from '../../components/wiki/wwp_abwasser_netz_view';
import { fkwp_einzel_view } from '../../components/wiki/fkwp_einzel_view';
import { waermepumpen_map } from '../../components/ipkw/NRWTKConsts';
import { wwp_gewaesser_netz_view } from '../../components/wiki/wwp_gewaesser_netz_view';

const waermetraeger_options = [
  { value: 'luft', label: 'Luft', image: lwpImage, bauweisen: ['Monoblock', 'Split'] },
  { value: 'wasser', label: 'Wasser', image: wwpImage, bauweisen: ['Grundwasser', 'Abwasser', 'Flusswasser'] },
  { value: 'sole', label: 'Sole', image: swwpImage, bauweisen: ['Erdsonde', 'Flächenkollektor'] },
];

const geb_typ_images = {
  MFH: mfhImage,
  EFH: efhImage,
  GMFH: gmfhImage,
  Netz: netzImage,
};



const WaermepumpePage = () => {
  const [selectedWaermetraeger, setSelectedWaermetraeger] = useState(waermetraeger_options[0]);
  const [bauweisenOptions, setBauweisenOptions] = useState([]);
  const [selectedBauweise, setSelectedBauweise] = useState(waermetraeger_options[0].bauweisen[1]);
  const [selectedHeatPump, setSelectedHeatPump] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);

  useEffect(() => {
    const options = selectedWaermetraeger.bauweisen.map((bauweise) => ({
      value: bauweise.toLowerCase(),
      label: bauweise,
    }));
    setBauweisenOptions(options);
    setSelectedBauweise(options[1]);
  }, [selectedWaermetraeger]);

  useEffect(() => {
    const matchingHeatPump = waermepumpen_map.find(
      (pump) =>
        pump.waermetraeger.toLowerCase() === selectedWaermetraeger.value &&
        pump.bauweisen.toLowerCase() === selectedBauweise.value
    );


    if (matchingHeatPump && matchingHeatPump.dataName) {
      setSelectedBuilding(matchingHeatPump.buildings[2]);
      setSelectedHeatPump(matchingHeatPump);
    } else if (matchingHeatPump) {
      setSelectedBuilding(matchingHeatPump.buildings[2]);
      setSelectedHeatPump(matchingHeatPump);
    } else {
      setSelectedBuilding(null);
      setSelectedHeatPump(null);
    }
  }, [selectedWaermetraeger, selectedBauweise]);

  const handleBuildingChange = (building) => {
    setSelectedBuilding(building);
  };


  const getTableHtml = () => {
    if (!selectedHeatPump || !selectedBuilding) return null;
    switch (selectedHeatPump.option) {
      case 'lwp_mono':
        switch (selectedBuilding) {
          case 'MFH':
            return <div> <br /> Quelle Fehlt</div>;
          case 'EFH':
            return <div> <br /> Quelle Fehlt</div>;
          default:
            return <div> <br /> Quelle Fehlt</div>;
        }
      case 'lwp_split':
        switch (selectedBuilding) {
          case 'Netz':
            return lwp_kompress_netz_view(selectedBuilding);
          case 'GMFH':
            return lwp_kompress_netz_view(selectedBuilding);
          case 'MFH':
            return lwp_split_einzel_view(selectedBuilding);
          case 'EFH':
            return lwp_split_einzel_view(selectedBuilding);
          default:
            return <div> <br /> Quelle Fehlt</div>;
        }

      case 'wwp_grundwasser':
        switch (selectedBuilding) {
          case 'Netz':
            return <div>  <br />Quelle Fehlt</div>;
          case 'GMFH':
            return <div> <br /> Quelle fehlt</div>;
          case 'MFH':
            return wwp_grundwasser_einzel_view(selectedBuilding);
          case 'EFH':
            return wwp_grundwasser_einzel_view(selectedBuilding);
          default:
            return <div> <br /> Quelle Fehlt</div>;
        }

      case 'wwp_abwasser':
        switch (selectedBuilding) {
          case 'Netz':
            return wwp_abwasser_netz_view(selectedBuilding);
          case 'GMFH':
            return wwp_abwasser_netz_view(selectedBuilding);
          case 'MFH':
            return <div> <br /> Quelle fehlt</div>;
          case 'EFH':
            return <div> <br /> Quelle fehlt</div>;
          default:
            return <div> <br /> Quelle Fehlt</div>;
        }

      case 'wwp_gewaesser':
        switch (selectedBuilding) {
          case 'Netz':
            return wwp_gewaesser_netz_view(selectedBuilding);
          case 'GMFH':
            return wwp_gewaesser_netz_view(selectedBuilding);
          case 'EFH':
            return <div> <br /> Quelle Fehlt</div>;
          case 'MFH':
            return <div> <br /> Quelle Fehlt</div>;
          default:
            return <div> <br /> Quelle Fehlt</div>;
        }
      case 'swp_erdsonde':
        switch (selectedBuilding) {
          case 'Netz':
            return <div> <br /> Quelle Fehlt</div>;
          case 'GMFH':
            return <div> <br /> Quelle Fehlt</div>;
          case 'EFH':
            return swp_einzel_view(selectedBuilding);
          case 'MFH':
            return swp_einzel_view(selectedBuilding);
          default:
            return <div> <br /> Quelle Fehlt</div>;
        }

      case 'swp_flaechenkollektor':
        switch (selectedBuilding) {
          case 'Netz':
            return <div> <br /> Quelle Fehlt</div>;
          case 'GMFH':
            return <div><br />  Quelle Fehlt</div>;
          case 'EFH':
            return fkwp_einzel_view(selectedBuilding);
          case 'MFH':
            return fkwp_einzel_view(selectedBuilding);
          default:
            return <div> <br /> Quelle Fehlt</div>;
        }
      default:
        return <div> <br /> Quelle Fehlt</div>;
    };
  };

  return (
    <div className="page-container">
      <div className="title-select-container">
        <h1>Auswahl Wärmepumpe</h1>
        <div className="selects-container">
          <div className="select-with-title">
            <p>Wärmeträger:</p>
            <Select
              value={selectedWaermetraeger}
              onChange={(option) => setSelectedWaermetraeger(option)}
              options={waermetraeger_options}
              className="select-component"
              placeholder="Wärmeträger auswählen"
            />
          </div>
          <div className="select-with-title">
            <p>Bauweise:</p>
            <Select
              value={selectedBauweise}
              onChange={(option) => setSelectedBauweise(option)}
              options={bauweisenOptions}
              className="select-component"
              placeholder="Bauweise auswählen"
            />

          </div>
        </div>
      </div>
      <div className="wiki-title-container">
        <h2>{selectedHeatPump ? selectedHeatPump.name : 'Wärmepumpen Informationen'}</h2>
      </div>

      <div className="wiki-overview-container">
        <div className="images-container">
          <div className="image-with-title">
            Wärmepumpe:
            <img
              src={selectedWaermetraeger.image}
              alt={selectedWaermetraeger.label}
              className="wiki-image"
            />
          </div>

          <div className="image-with-title">
            Typischer Anwendungsbereich:
            <div className="building-image-container">

              <img
                src={geb_typ_images[selectedBuilding]}
                alt={selectedBuilding}
                className="wiki-image"
              />
              {selectedHeatPump && selectedHeatPump.buildings.length > 1 && (
                <div className="building-buttons">
                  {selectedHeatPump.buildings.map((building) => (
                    <button
                      key={building}
                      onClick={() => handleBuildingChange(building)}
                      className={building === selectedBuilding ? 'active' : ''}
                    >
                      {building}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-container">
          <h3>Beschreibung</h3>
          {selectedHeatPump && (
            <div className='desc-text'>{selectedHeatPump.funktionsweise}</div>
          )}
        </div>
      </div>
      {/* Fact Tables */}
      {selectedHeatPump && getTableHtml(selectedBuilding)
      }
      <div className="fact-sheet-item">
      </div>

    </div >
  );

};
export default WaermepumpePage;