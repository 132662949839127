import React from 'react';
import { useNavigate } from 'react-router-dom';

import '../../styles/home/CardStyles.css'; // Importing shared CSS
import '../../styles/home/AboutPageStyles.css'; // Importing shared CSS
import AboutIcon  from '../../images/AboutIcon.png';
const AboutPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/about'); 
  };

  return (
    <div className="card" onClick={handleClick}>
      <img src={AboutIcon} alt="Über Symbol" className="card-icon" />
      <h2>Über das Projekt</h2>
      <p>Dies ist die Informations-Seite. Hier erfahren Sie mehr über uns und das iPKW Projekt.</p>
    </div>
  );
};

export default AboutPage;
