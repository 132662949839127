import React from 'react';
import { useDashboardContext } from '../../../context/DashboardContext';


const InfoSwitcher = () => {
  const {  setSidebarState } = useDashboardContext();

  const infoOptions = [
    { value: 'buildingTypes', label: 'Gebäudetypen' },
    { value: 'sanierungstandTypes', label: 'Energieeffizienzklassen' },
    { value: 'districtType', label: 'Quartier Informationen' },
  ];

  

  return (
    <>
      <div className='info-horizontal'>
        <div className='info-label'>Info:</div>
        <select
          onChange={(e) => setSidebarState({ infoSelection: e.target.value })}
          className="info-selector">
          {infoOptions.map((option, index) => (
            <option key={index} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    </>
  );
}

export default InfoSwitcher;
