import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/home/CardStyles.css'; // Importing shared CSS
import WikiIcon  from '../../images/WikiIcon.png';
import IPKWIcon  from '../../images/IPKWIcon.png';

const QuartierPageNav = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/quartier'); 
  };

  return (
    <div className="card"  onClick={handleClick}>
      <img src={IPKWIcon} alt="Wiki Icon" className="card-icon" />
      <h2>Toolkit</h2>
      <p>CO2 & Kostenrechnungen von Wärmesystemen im Quartier</p>
    </div>
  );
};

export default QuartierPageNav;
