// Import the required constants
import { effizienzGasKessel, effizienzPelletsKessel } from '../../../../components/ipkw/IPKWConsts';
import { technikkatalog_jahre,
     technikkatalog_emissionen,
      lwp_split_einzel,
    swp_flaechenkollektor_einzel,
    swp_sonden_einzel,
wwp_grundwasser_einzel,  } from '../../../../components/ipkw/NRWTKConsts';

export function createBedarfTimeline(gesamtVerbrauch, sanierungsquote, sanierungsimpact, years) {
    const lowerEachYearBy = gesamtVerbrauch * sanierungsquote * sanierungsimpact;
    const startYear = years[0];
    const data = years.map(year => {
        const yearsSinceStart = year - startYear;
        const requiredBedarf = gesamtVerbrauch - (lowerEachYearBy * yearsSinceStart);
        return {
            year: year,
            requiredBedarf: requiredBedarf
        };
    });
    return data;
}

function getWärmepumpeCo2(gesamtVerbrauch, scenarioConfig, heatSource, years, spitzenLast = 'keine') {

    // Select the appropriate data array for the given heatSource
    let wpDataArray;
    switch (heatSource) {
        case 'Luft Wärmepumpe':
            wpDataArray = lwp_split_einzel;
            break;
        case 'Grundwasser Wärmepumpe':
            wpDataArray = wwp_grundwasser_einzel;
            break;
        case 'Erdsonden Wärmepumpe':
            wpDataArray = swp_sonden_einzel;
            break;
        case 'Flächenkollektor Wärmepumpe':
            wpDataArray = swp_flaechenkollektor_einzel;
            break;
        case 'Gewässer Wärmepumpe':
            // Fallback to Grundwasser as a placeholder (or handle separately if data available)
            wpDataArray = wwp_grundwasser_einzel;
            break;
        case 'Abwasser Wärmepumpe':
            // Fallback to Grundwasser as a placeholder (or handle separately if data available)
            wpDataArray = wwp_grundwasser_einzel;
            break;
        default:
            // If unknown, fallback to Luft Wärmepumpe data
            wpDataArray = lwp_split_einzel;
            break;
    }

    // Get the jazUnsaniert from the selected array , is ok because always the same anyway
    const jazUnsaniert = wpDataArray[0].jazUnsaniert;

    // Determine if we have Spitzenlast and split load
    let wpLoad = gesamtVerbrauch;    // default 100%
    let spitzenLoad = 0;             // default 0%

    if (spitzenLast && spitzenLast !== 'keine') {
        // 20% Spitzenlast, 80% WP
        spitzenLoad = gesamtVerbrauch * 0.2;
        wpLoad = gesamtVerbrauch * 0.8;
    }

    // Calculate WP electricity need
    const wpElectricityNeeded = wpLoad / jazUnsaniert;

    // Create timeline for WP electricity
    const wpBedarfTimeline = createBedarfTimeline(
        wpElectricityNeeded,
        scenarioConfig.sanierungsQuoteYear,
        scenarioConfig.sanierungsEfficiency,
        years
    );

    // Get emission factors for Strom-Mix-D
    const technikJahre = technikkatalog_jahre;
    const stromEmissionData = technikkatalog_emissionen.find(item => item.energietraeger === 'Strom-Mix-D');
    if (!stromEmissionData) {
        console.error('Strom-Mix-D emission data not found');
        return [];
    }

    const stromEmissionFactors = {};
    years.forEach(year => {
        const index = technikJahre.indexOf(year);
        if (index !== -1) {
            stromEmissionFactors[year] = stromEmissionData.emissionen[index] / 1000; // convert to t/MWh
        } else {
            // If year not found, use last known emission factor
            stromEmissionFactors[year] = stromEmissionData.emissionen[stromEmissionData.emissionen.length - 1] / 1000;
        }
    });

    // Calculate WP emissions
    const wpEmissions = wpBedarfTimeline.map(item => {
        const emissionFactor = stromEmissionFactors[item.year];
        const emissions = emissionFactor * item.requiredBedarf; // in t (MWh * t/MWh)
        return {
            year: item.year,
            wpEmission: emissions,
        };
    });

    // If no spitzenlast, return WP emissions only
    if (!spitzenLast || spitzenLast === 'keine') {
        return wpEmissions.map(item => ({
            year: item.year,
            emission: item.wpEmission
        }));
    }

    // Otherwise, calculate Spitzenlast emissions
    let spitzenEmissions = [];
    if (spitzenLast === 'Holzpellets') {
        // Similar to Verbrennung with Holz
        const efficiency = effizienzPelletsKessel; // from constants
        const energyCarrier = 'Holz';

        const spitzenFuel = spitzenLoad / efficiency;
        const spitzenBedarfTimeline = createBedarfTimeline(
            spitzenFuel,
            scenarioConfig.sanierungsQuoteYear,
            scenarioConfig.sanierungsEfficiency,
            years
        );

        const holzEmissionData = technikkatalog_emissionen.find(item => item.energietraeger === energyCarrier);
        if (!holzEmissionData) {
            console.error('Emission data not found for Holz');
            return [];
        }

        const holzEmissionFactors = {};
        years.forEach(year => {
            const index = technikJahre.indexOf(year);
            if (index !== -1) {
                holzEmissionFactors[year] = holzEmissionData.emissionen[index] / 1000;
            } else {
                holzEmissionFactors[year] = holzEmissionData.emissionen[holzEmissionData.emissionen.length - 1] / 1000;
            }
        });

        spitzenEmissions = spitzenBedarfTimeline.map(item => {
            const emissionFactor = holzEmissionFactors[item.year];
            const emissions = emissionFactor * item.requiredBedarf;
            return {
                year: item.year,
                spitzenEmission: emissions,
            };
        });

    } else if (spitzenLast === 'Gaskessel') {
        // Similar to Verbrennung with Erdgas
        const efficiency = effizienzGasKessel; // from constants
        const energyCarrier = 'Erdgas';

        const spitzenFuel = spitzenLoad / efficiency;
        const spitzenBedarfTimeline = createBedarfTimeline(
            spitzenFuel,
            scenarioConfig.sanierungsQuoteYear,
            scenarioConfig.sanierungsEfficiency,
            years
        );

        const gasEmissionData = technikkatalog_emissionen.find(item => item.energietraeger === energyCarrier);
        if (!gasEmissionData) {
            console.error('Emission data not found for Erdgas');
            return [];
        }

        const gasEmissionFactors = {};
        years.forEach(year => {
            const index = technikJahre.indexOf(year);
            if (index !== -1) {
                gasEmissionFactors[year] = gasEmissionData.emissionen[index] / 1000;
            } else {
                gasEmissionFactors[year] = gasEmissionData.emissionen[gasEmissionData.emissionen.length - 1] / 1000;
            }
        });

        spitzenEmissions = spitzenBedarfTimeline.map(item => {
            const emissionFactor = gasEmissionFactors[item.year];
            const emissions = emissionFactor * item.requiredBedarf;
            return {
                year: item.year,
                spitzenEmission: emissions,
            };
        });

    } else if (spitzenLast === 'Stromdirekt') {
        // Direct electric heating = 100% efficiency, so Strombedarf = spitzenLoad
        const spitzenElectricity = spitzenLoad;
        const spitzenBedarfTimeline = createBedarfTimeline(
            spitzenElectricity,
            scenarioConfig.sanierungsQuoteYear,
            scenarioConfig.sanierungsEfficiency,
            years
        );

        spitzenEmissions = spitzenBedarfTimeline.map(item => {
            const emissionFactor = stromEmissionFactors[item.year];
            const emissions = emissionFactor * item.requiredBedarf;
            return {
                year: item.year,
                spitzenEmission: emissions,
            };
        });

    } else {
        // If another Spitzenlast option not handled, assume no emission
        spitzenEmissions = years.map(year => ({ year, spitzenEmission: 0 }));
    }

    // Combine WP emissions and Spitzenlast emissions
    const combined = wpEmissions.map(wpItem => {
        const spItem = spitzenEmissions.find(se => se.year === wpItem.year);
        const totalEmission = wpItem.wpEmission + (spItem ? spItem.spitzenEmission : 0);
        return {
            year: wpItem.year,
            emission: totalEmission
        };
    });

    return combined;
}

function getVerbrennungCo2(gesamtVerbrauch, scenarioConfig, heatSource, years) {

    let efficiency;
    let energyCarrier;

    if (heatSource === 'Gaskessel') {
        efficiency = effizienzGasKessel;
        energyCarrier = 'Erdgas';
    } else if (heatSource === 'Holzpellets') {
        efficiency = effizienzPelletsKessel;
        energyCarrier = 'Holz';
    } else {
        console.error('Unknown heat source for Verbrennung:', heatSource);
        return [];
    }

    const fuelRequired = gesamtVerbrauch / efficiency;

    const bedarfTimeline = createBedarfTimeline(
        fuelRequired,
        scenarioConfig.sanierungsQuoteYear,
        scenarioConfig.sanierungsEfficiency,
        years
    );

    const technikJahre = technikkatalog_jahre;
    const emissionData = technikkatalog_emissionen.find(
        item => item.energietraeger === energyCarrier
    );

    if (!emissionData) {
        console.error('Emission data not found for energy carrier:', energyCarrier);
        return [];
    }

    const emissionFactors = {};
    years.forEach(year => {
        const index = technikJahre.indexOf(year);
        if (index !== -1) {
            emissionFactors[year] = emissionData.emissionen[index] / 1000;
        } else {
            console.warn('Year not found in technikkatalog_jahre:', year);
            emissionFactors[year] = emissionData.emissionen[emissionData.emissionen.length - 1] / 1000;
        }
    });

    return bedarfTimeline.map(item => {
        const emissionFactor = emissionFactors[item.year];
        const emissions = emissionFactor * item.requiredBedarf;
        const emissionsInTons = emissions;
        return {
            year: item.year,
            emission: emissionsInTons,
        };
    });
}


export function getEinzelCo2(gesamtVerbrauch, scenarioConfig, einzelConfig) {
    const { solutionClass, heatSource,spitzenLast } = einzelConfig;

    const years = [2025, 2030, 2035, 2040, 2045];
    let dataNullszenario = [];

    if (solutionClass === 'Verbrennung') {
        dataNullszenario = getVerbrennungCo2(gesamtVerbrauch, scenarioConfig, heatSource, years);
    } else if (solutionClass === 'Wärmepumpe') {
        dataNullszenario = getWärmepumpeCo2(gesamtVerbrauch, scenarioConfig, heatSource, years,spitzenLast);
    } else {
        console.error('Solution class not supported in getEinzelCo2:', solutionClass);
    }
    console.log('dataNullszenario:', dataNullszenario);
    return dataNullszenario;
}


export function getSolutionBuilderCo2(gesamtVerbrauch, scenarioConfig) {



    return [];
}

export function getNetzCo2(gesamtVerbrauch, scenarioConfig) {

    return [];
}
