import React, { useState } from 'react';
import '../../../../styles/ipkw/Dashboard.css';
import '../../../../styles/ipkw/SolutionBuilder.css';
import { solution_builder_options, spitzenLastOptions, netzSonderPotenziale } from '../../IPKWConsts';

const SolutionCard = () => {
  const [solutionClass, setSolutionClass] = useState('');
  const [heatSource, setHeatSource] = useState('');
  const [spitzenLast, setSpitzenLast] = useState('');
  const [sonderPotenzial, setSonderPotenzial] = useState(''); 

  const handleSolutionClassChange = (e) => {
    const selectedSolutionClass = e.target.value;
    setSolutionClass(selectedSolutionClass);
    setHeatSource('');
    setSpitzenLast('');
    setSonderPotenzial(''); 
  };

  const handleHeatSourceChange = (e) => {
    const selectedHeatSource = e.target.value;
    setHeatSource(selectedHeatSource);
    setSpitzenLast('');
  };

  const selectedClassOptions = solution_builder_options.find(
    (option) => option.solutionClass === solutionClass
  );

  const heatSourceOptions = selectedClassOptions ? selectedClassOptions.options : [];

  const selectedHeatSourceObj = heatSourceOptions.find(
    (option) => option.heatSource === heatSource
  );

  const showSpitzenLast = selectedHeatSourceObj && selectedHeatSourceObj.spitzenLastBedarf;

  return (
    <div className='solution-card-content'>
      <div className='sb-left-section'>
        <div className='select-group'>
          <label htmlFor='solution-class-select'>Art</label>
          <select
            id='solution-class-select'
            value={solutionClass}
            onChange={handleSolutionClassChange}
          >
            <option value=''>Bitte wählen</option>
            {solution_builder_options.map((option) => (
              <option key={option.solutionClass} value={option.solutionClass}>
                {option.solutionClass}
              </option>
            ))}
          </select>
        </div>
        <div className='select-group'>
          <label htmlFor='heat-source-select'>Hauptwärmequelle</label>
          {solutionClass === 'Fern' ? (
            <input type='text' value='Fernwärme Anbieter' readOnly />
          ) : (
            <select
              id='heat-source-select'
              value={heatSource}
              onChange={handleHeatSourceChange}
            >
              <option value=''>Bitte wählen</option>
              {heatSourceOptions.map((option) => (
                <option key={option.heatSource} value={option.heatSource}>
                  {option.heatSource}
                </option>
              ))}
            </select>
          )}
        </div>
        {showSpitzenLast && (
          <div className='select-group'>
            <label htmlFor='spitzenlast-select'>Spitzenlast</label>
            <select
              id='spitzenlast-select'
              value={spitzenLast}
              onChange={(e) => setSpitzenLast(e.target.value)}
            >
              <option value=''>Bitte wählen</option>
              {spitzenLastOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
        {solutionClass === 'Netz' && (
          <div className='select-group'>
            <label htmlFor='sonderpotenzial-select'>Sonderpotenzial</label>
            <select
              id='sonderpotenzial-select'
              value={sonderPotenzial}
              onChange={(e) => setSonderPotenzial(e.target.value)}
            >
              <option value=''>Bitte wählen</option>
              {netzSonderPotenziale.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </div>
  );
};

export default SolutionCard;
