// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General sidebar  */
.quartier-sidebar-container {
    background-color: var(--white);
    width: 45vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: var(--spacing-small);
}

.quartier-sidebar-info-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* Info selector*/
.info-selector {
    margin: 0 var(--spacing-large);
    padding: var(--spacing-small);
}


.info-label{
    font-size: 1.2em;
    font-weight: bold;
}

/* Building Types */

.building-type-table {
    width: auto;
    table-layout: auto;
    border-collapse: separate;
    border-spacing: 0;

}


/* Quartier Info*/

/* Resulting IPKW Types */`, "",{"version":3,"sources":["webpack://./src/styles/ipkw/Sidebar.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA,iBAAiB;AACjB;IACI,8BAA8B;IAC9B,6BAA6B;AACjC;;;AAGA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA,mBAAmB;;AAEnB;IACI,WAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,iBAAiB;;AAErB;;;AAGA,iBAAiB;;AAEjB,yBAAyB","sourcesContent":["/* General sidebar  */\n.quartier-sidebar-container {\n    background-color: var(--white);\n    width: 45vw;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    margin: var(--spacing-small);\n}\n\n.quartier-sidebar-info-card {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.info-horizontal {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n}\n\n/* Info selector*/\n.info-selector {\n    margin: 0 var(--spacing-large);\n    padding: var(--spacing-small);\n}\n\n\n.info-label{\n    font-size: 1.2em;\n    font-weight: bold;\n}\n\n/* Building Types */\n\n.building-type-table {\n    width: auto;\n    table-layout: auto;\n    border-collapse: separate;\n    border-spacing: 0;\n\n}\n\n\n/* Quartier Info*/\n\n/* Resulting IPKW Types */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
