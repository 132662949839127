import React from 'react';
import { useDashboardContext } from '../../../context/DashboardContext';

const QuartierTypes = () => {
  const { ipkwQuartierData } = useDashboardContext();
  const {  quartierData } = ipkwQuartierData;


  const { nutzfläche, grundfläche, gesamtfläche } = quartierData;

  const GFZ = gesamtfläche ? (nutzfläche / gesamtfläche).toFixed(1) : 'N/A';
  const formatArea = (area) => {
    return area.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const formattedGesamtfläche = formatArea(gesamtfläche.toFixed(0));
  const formattedGrundfläche = formatArea(grundfläche.toFixed(0));
  const formattedNutzfläche = formatArea(nutzfläche.toFixed(0));

  return (
    <div className='quartier-sidebar-info-card'>
      <h2>Quartierauswahl Überblick</h2>

      <h3>Flächenübersicht</h3>
      <ul>
        <li>Quartier Fläche: {formattedGesamtfläche} m²</li>
        <li>Grundfläche: {formattedGrundfläche} m²</li>
        <li>Beheizte Fläche: {formattedNutzfläche} m²</li>
        <li>Beheizte Fläche / Quartier Fläche: {GFZ}</li>
      </ul>
    </div>
  );
};

export default QuartierTypes;
