import React, { useState, useEffect } from 'react';
import { useDashboardContext } from '../../../context/DashboardContext';

import { getReverseBuildingGroupFoType, getReverseYearGroupForYear } from '../IPKWUtils';
import { getColorBlockList } from '../../ipkw/IPKWUtils';
import {
  ipkwBuildingTypes,
  ipkwBuildingYears,
  yearColours,
} from '../../ipkw/IPKWConsts';



function getColoridx(type, year) {

  const index = ipkwBuildingYears.indexOf(year);
  const typeIndex = ipkwBuildingTypes.indexOf(type);
  const val = yearColours[typeIndex][index];
  return val;
};



const ResultingIPKWTypes = () => {
  const { mapState, ipkwQuartierData, setIpkwQuartierData, sidebarHoverState, setSidebarHoverState } = useDashboardContext();
  const [inputValue, setInputValue] = useState('');
  const [hoverState, setHoverState] = useState({ year: null, type: null });


  useEffect(() => {
    if (ipkwQuartierData?.buildingData) {
      const totalBlocks = ipkwQuartierData.buildingData.reduce((acc, item) => acc + item.blocks, 0);
      const updatedData = ipkwQuartierData.buildingData.map(item => ({
        ...item,
        percentage: totalBlocks === 0 ? 0 : (item.blocks / totalBlocks) * 100
      }));
      
      if (JSON.stringify(updatedData) !== JSON.stringify(ipkwQuartierData.buildingData)) {
        setIpkwQuartierData(prev => ({ ...prev, buildingData: updatedData }));
      }
    }
  }, [ipkwQuartierData?.buildingData]);
  
  // Updated handler functions
  const handleIncrement = (type, year) => {
    if (!ipkwQuartierData) return;
  
    const totalBlocks = ipkwQuartierData.buildingData.reduce((acc, item) => acc + item.blocks, 0);
    if (totalBlocks >= 10) return;
  
    const updatedBuildingData = ipkwQuartierData.buildingData.map(item => 
      item.year === year && item.type === type 
        ? { ...item, blocks: item.blocks + 1 } 
        : item
    );
  
    if (!updatedBuildingData.some(item => item.year === year && item.type === type)) {
      updatedBuildingData.push({ year, type, blocks: 1 });
    }
  
    setIpkwQuartierData(prev => ({ ...prev, buildingData: updatedBuildingData }));
  };
  
  const handleDecrement = (type, year) => {
    if (!ipkwQuartierData) return;
  
    const updatedBuildingData = ipkwQuartierData.buildingData
      .map(item => 
        (item.year === year && item.type === type && item.blocks > 0)
          ? { ...item, blocks: item.blocks - 1 }
          : item
      )
      .filter(item => item.blocks > 0);
  
    setIpkwQuartierData(prev => ({ ...prev, buildingData: updatedBuildingData }));
  };
  
  // Update the percentage calculation in getTypePercentage
  const getTypePercentage = (year, type) => {
    if (!ipkwQuartierData?.buildingData) return 0;
    const entry = ipkwQuartierData.buildingData.find(
      item => item.year === year && item.type === type
    );
    return entry?.percentage || 0;
  };

  const handleSave = () => {
    const saveJson = {
      name: inputValue || "leer",
      quartier: ipkwQuartierData,
      geometry: mapState.geometry,
    };
    const blob = new Blob([JSON.stringify(saveJson, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = "IPKW_Dash_" + inputValue;
    a.click();
    URL.revokeObjectURL(url);

  }

  const getBlockCount = (year, type) => {

    if (!ipkwQuartierData.buildingData) {
      return 0;

    }
    for (const entry of ipkwQuartierData.buildingData) {
      if (entry.year === year && entry.type === type) {
        return entry.blocks;
      }
    }
    return 0;
  }



  const handleHover = (hoverState) => {
    setHoverState(hoverState);
    setSidebarHoverState(prevState => ({ ...prevState, resultsHover: { year: hoverState.year, type: hoverState.type } }));
  }

  const getColor = (type, year) => {
    if ((hoverState.year && hoverState.year === year && hoverState.type === type) || (sidebarHoverState.buildingData && getReverseYearGroupForYear(sidebarHoverState.buildingData.year) === year && getReverseBuildingGroupFoType(sidebarHoverState.buildingData.type) === type)) {
      return { border: '2px solid var(--colora2)' }
    }
    return { border: '2px solid var(--temperature-scale-' + getColoridx(type, year) + ')' };
  };

  console.log(ipkwQuartierData.buildingData);

  return (
    <div>
      <div className='quartier-sidebar-info-card'>
        <div className='ipkw-result-types-container'>
          <div>
            <div className='geb-auswahl'>
             Repräsentative Gebäudetypen:
            </div>
            <div className='squares-container'>
              {ipkwQuartierData.buildingData && [...ipkwQuartierData.buildingData].map((_, index) => (
                 [...Array(ipkwQuartierData.buildingData[index].blocks)].map((_, index2) => (
                  <div key={index2 + " " + index}>
                    <div className='square-in-square'>
                      <div className='ipkw-kw-square' style={getColorBlockList(ipkwQuartierData.buildingData, index)}>
                        <div style={{ position: 'absolute', top: '2px', width: '100%', textAlign: 'center' }}>
                          {ipkwQuartierData.buildingData[index].type}
                        </div>
                        <div style={{ position: 'absolute', bottom: '2px', width: '100%', textAlign: 'center' }}>
                          {ipkwQuartierData.buildingData[index].year}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ))}

              {[...Array(ipkwQuartierData.buildingData.length > 0 ? 10 - ipkwQuartierData.buildingData.reduce((acc, block) => {
                return acc += block.blocks
              }, 0) : 10)].map((_, index2) => (
                <div key={index2} className='square-in-square'>
                  <div className='ipkw-kw-square' style={getColorBlockList(ipkwQuartierData.buildingData, -1)} >
                  </div>
                </div>
              ))}
            </div>
          </div>
          <table className='grid-container'>
            <thead>
              <tr>
                <th></th>
                {ipkwBuildingYears.map(year => (
                  <th className='type-label' key={year}>{year}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {ipkwBuildingTypes.filter(type => type !== '').map(type => (
                <tr key={type}>
                  <th className='type-label'>{type}</th>
                  {ipkwBuildingYears.map(year => (
                    <td key={year}>
                      <div className='year-container'>
                        <div className='square-in-square'
                          onMouseEnter={() => handleHover({ year: year, type: type })}
                          onMouseLeave={() => handleHover({ year: null, type: null })}>
<div className='inner-square' style={{
  width: `${Math.min(10 * Math.sqrt(getTypePercentage(year, type)), 100)}%`,
  height: `${Math.min(10 * Math.sqrt(getTypePercentage(year, type)), 100)}%`,
  backgroundColor: 'var(--temperature-scale-' + getColoridx(type, year) + ')',
}}>
</div>
                          <div className='ipkw-kw-square' style={getColor(type, year)}></div>
                        </div>
                        <div className='year-legend'>
                          {getBlockCount(year, type)}
                          <button className='ipkw-sidebar-results-button' onClick={() => handleDecrement(type, year)}>-</button>
                          <button className='ipkw-sidebar-results-button' onClick={() => handleIncrement(type, year)}>+</button>
                        </div>
                      </div>
                    </td>

                  ))}
                </tr>))}
            </tbody>
          </table>
        </div>

      </div>
      <div className='info-horizontal'>
        <input
          type="text"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          placeholder="IPKW Quartier Name"
        />
        <button className='ipkw-sidebar-results-button' onClick={handleSave}>💾</button>
      </div>
    </div>
  );
};

export default ResultingIPKWTypes;
