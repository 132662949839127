// ConfigModal.js
import React, { useState } from 'react';
import '../../../../styles/ipkw/ConfigModal.css';
import ModellQuartierCard from './ModellQuartierCard';
import Potenziale from './Potenziale';



const QuartierConfigModal = ({ onClose }) => {
    const [activeTab, setActiveTab] = useState(1); // Default to Tab 1



    return (
        <div className="modal-overlay-left">
            <div className="config-header">
                <div className="tab-buttons">
                    <div
                        className={"tab-header"}
                    >

                        Quartier Konfigurieren
                    </div>
                </div>
                <button className="close-button" onClick={onClose}>
                    X
                </button>
            </div>
            <div className="modal-content">
                <ModellQuartierCard />
                <Potenziale />
            </div>
        </div>
    );
};

export default QuartierConfigModal;
