import React from 'react';
import { useDashboardContext } from '../../../context/DashboardContext';
import GuideImage  from '../../../images/MapGuide.png';

const Guide = () => {

  return (
    <div className='quartier-sidebar-info-card'>
      <h2>Bedienungsanleitung Karte</h2>
      <ul>
       <li> Auf dieser Seite können Sie ein Quartier definieren, Gebäude- & Quartier-Daten abrufen sowie die aus dem Quartier generierten repräsentativen Gebäudetypen für die Toolbox anpassen.</li> 
       <li> A: Gebäudedaten anzeigen</li> 
       <li> B: Quartier selber einzeichnen</li> 
       <li> C: Quartierauswahl löschen</li> 
      </ul>
      <img src={GuideImage} alt="Bedienungsanleitung" />

    </div>
  );
};

export default Guide;
