export const lwp_split_einzel = [
    { leistung: 5, lebensdauer: 20, jazFussboden: 3.75, jazUnsaniert: 2.60, spezInvestKosten: 2288, anlagenKostenProzent: 72, installationsKostenProzent: 28, investKostenGesamtAnlage: 11400, jaehrlicheFixkosten: 76, geringinvestiveKostenProKW: 671, geringinvestiveKosten: 3350, pufferspeicherKosten: 1025, pufferKapazitaet: 500, investKostenProL: 2.0, investKostenGesamt: 15800 },
    { leistung: 10, lebensdauer: 20, jazFussboden: 3.75, jazUnsaniert: 2.60, spezInvestKosten: 1982, anlagenKostenProzent: 82, installationsKostenProzent: 18, investKostenGesamtAnlage: 19800, jaehrlicheFixkosten: 38, geringinvestiveKostenProKW: 540, geringinvestiveKosten: 5400, pufferspeicherKosten: 1025, pufferKapazitaet: 500, investKostenProL: 2.0, investKostenGesamt: 26200 },
    { leistung: 20, lebensdauer: 20, jazFussboden: 3.75, jazUnsaniert: 2.60, spezInvestKosten: 1717, anlagenKostenProzent: 89, installationsKostenProzent: 11, investKostenGesamtAnlage: 34300, jaehrlicheFixkosten: 21, geringinvestiveKostenProKW: 469, geringinvestiveKosten: 9370, pufferspeicherKosten: 1726, pufferKapazitaet: 1000, investKostenProL: 1.7, investKostenGesamt: 45400 },
    { leistung: 30, lebensdauer: 20, jazFussboden: 3.75, jazUnsaniert: 2.60, spezInvestKosten: 1381, anlagenKostenProzent: 90, installationsKostenProzent: 10, investKostenGesamtAnlage: 41400, jaehrlicheFixkosten: 19, geringinvestiveKostenProKW: 385, geringinvestiveKosten: 11550, pufferspeicherKosten: 1726, pufferKapazitaet: 1000, investKostenProL: 1.7, investKostenGesamt: 54700 },
    { leistung: 40, lebensdauer: 20, jazFussboden: 3.75, jazUnsaniert: 2.60, spezInvestKosten: 1226, anlagenKostenProzent: 90, installationsKostenProzent: 10, investKostenGesamtAnlage: 49100, jaehrlicheFixkosten: 18, geringinvestiveKostenProKW: 352, geringinvestiveKosten: 14080, pufferspeicherKosten: 11445, pufferKapazitaet: 1500, investKostenProL: 7.6, investKostenGesamt: 74600 },
    { leistung: 50, lebensdauer: 20, jazFussboden: 3.75, jazUnsaniert: 2.60, spezInvestKosten: 1420, anlagenKostenProzent: 90, installationsKostenProzent: 10, investKostenGesamtAnlage: 71000, jaehrlicheFixkosten: 17, geringinvestiveKostenProKW: 329, geringinvestiveKosten: 16430, pufferspeicherKosten: 11445, pufferKapazitaet: 1500, investKostenProL: 7.6, investKostenGesamt: 98900 },
    { leistung: 60, lebensdauer: 20, jazFussboden: 3.75, jazUnsaniert: 2.60, spezInvestKosten: 1368, anlagenKostenProzent: 90, installationsKostenProzent: 10, investKostenGesamtAnlage: 82100, jaehrlicheFixkosten: 16, geringinvestiveKostenProKW: 310, geringinvestiveKosten: 18630, pufferspeicherKosten: 11445, pufferKapazitaet: 1500, investKostenProL: 7.6, investKostenGesamt: 112200 },
    { leistung: 80, lebensdauer: 20, jazFussboden: 3.75, jazUnsaniert: 2.60, spezInvestKosten: 1289, anlagenKostenProzent: 90, installationsKostenProzent: 10, investKostenGesamtAnlage: 103100, jaehrlicheFixkosten: 15, geringinvestiveKostenProKW: 284, geringinvestiveKosten: 22720, pufferspeicherKosten: 15260, pufferKapazitaet: 2000, investKostenProL: 7.6, investKostenGesamt: 141100 },
    { leistung: 110, lebensdauer: 20, jazFussboden: 3.75, jazUnsaniert: 2.60, spezInvestKosten: 1206, anlagenKostenProzent: 90, installationsKostenProzent: 10, investKostenGesamtAnlage: 132600, jaehrlicheFixkosten: 14, geringinvestiveKostenProKW: 257, geringinvestiveKosten: 28300, pufferspeicherKosten: 15260, pufferKapazitaet: 2000, investKostenProL: 7.6, investKostenGesamt: 176200 },
];

export const wwp_grundwasser_einzel = [
    {
        leistung: 5,
        jazFussboden: 5.35,
        jazSanierteAltbau: 4.55,
        jazUnsaniert: 3.96,
        lebensdauer: 20,
        spezInvestKosten: 3012,
        anlagenKostenProzent: 72,
        installationsKostenProzent: 28,
        erschliessungskostenWQ: 1635,
        spezInvestKostenGesamt: 4647,
        investKostenGesamtAnlage: 23200,
        jaehrlicheFixkosten: 76,
        geringinvestiveKostenProKW: 671,
        geringinvestiveKosten: 3354,
        pufferspeicherKosten: 1025,
        pufferKapazitaet: 500,
        investKostenProL: 2.0,
        investKostenGesamt: 27600
    },
    {
        leistung: 10,
        jazFussboden: 5.35,
        jazSanierteAltbau: 4.55,
        jazUnsaniert: 3.96,
        lebensdauer: 20,
        spezInvestKosten: 1938,
        anlagenKostenProzent: 78,
        installationsKostenProzent: 22,
        erschliessungskostenWQ: 1635,
        spezInvestKostenGesamt: 3573,
        investKostenGesamtAnlage: 35700,
        jaehrlicheFixkosten: 38,
        geringinvestiveKostenProKW: 540,
        geringinvestiveKosten: 5404,
        pufferspeicherKosten: 1025,
        pufferKapazitaet: 500,
        investKostenProL: 2.0,
        investKostenGesamt: 42100
    },
    {
        leistung: 20,
        jazFussboden: 5.35,
        jazSanierteAltbau: 4.55,
        jazUnsaniert: 3.96,
        lebensdauer: 20,
        spezInvestKosten: 1271,
        anlagenKostenProzent: 84,
        installationsKostenProzent: 16,
        erschliessungskostenWQ: 1635,
        spezInvestKostenGesamt: 2906,
        investKostenGesamtAnlage: 58100,
        jaehrlicheFixkosten: 19,
        geringinvestiveKostenProKW: 469,
        geringinvestiveKosten: 9374,
        pufferspeicherKosten: 1726,
        pufferKapazitaet: 1000,
        investKostenProL: 1.7,
        investKostenGesamt: 69200
    },
    {
        leistung: 30,
        jazFussboden: 5.35,
        jazSanierteAltbau: 4.55,
        jazUnsaniert: 3.96,
        lebensdauer: 20,
        spezInvestKosten: 1000,
        anlagenKostenProzent: 86,
        installationsKostenProzent: 14,
        erschliessungskostenWQ: 1635,
        spezInvestKostenGesamt: 2635,
        investKostenGesamtAnlage: 79000,
        jaehrlicheFixkosten: 15,
        geringinvestiveKostenProKW: 385,
        geringinvestiveKosten: 11547,
        pufferspeicherKosten: 1726,
        pufferKapazitaet: 1000,
        investKostenProL: 1.7,
        investKostenGesamt: 92300
    },
    {
        leistung: 40,
        jazFussboden: 5.35,
        jazSanierteAltbau: 4.55,
        jazUnsaniert: 3.96,
        lebensdauer: 20,
        spezInvestKosten: 846,
        anlagenKostenProzent: 88,
        installationsKostenProzent: 12,
        erschliessungskostenWQ: 1635,
        spezInvestKostenGesamt: 2481,
        investKostenGesamtAnlage: 99200,
        jaehrlicheFixkosten: 13,
        geringinvestiveKostenProKW: 352,
        geringinvestiveKosten: 14082,
        pufferspeicherKosten: 11445,
        pufferKapazitaet: 1500,
        investKostenProL: 7.6,
        investKostenGesamt: 124700
    },
    {
        leistung: 50,
        jazFussboden: 5.35,
        jazSanierteAltbau: 4.55,
        jazUnsaniert: 3.96,
        lebensdauer: 20,
        spezInvestKosten: 744,
        anlagenKostenProzent: 89,
        installationsKostenProzent: 11,
        erschliessungskostenWQ: 1635,
        spezInvestKostenGesamt: 2379,
        investKostenGesamtAnlage: 118900,
        jaehrlicheFixkosten: 11,
        geringinvestiveKostenProKW: 329,
        geringinvestiveKosten: 16426,
        pufferspeicherKosten: 11445,
        pufferKapazitaet: 1500,
        investKostenProL: 7.6,
        investKostenGesamt: 146800
    },
    {
        leistung: 60,
        jazFussboden: 5.35,
        jazSanierteAltbau: 4.55,
        jazUnsaniert: 3.96,
        lebensdauer: 20,
        spezInvestKosten: 671,
        anlagenKostenProzent: 90,
        installationsKostenProzent: 10,
        erschliessungskostenWQ: 1635,
        spezInvestKostenGesamt: 2306,
        investKostenGesamtAnlage: 138300,
        jaehrlicheFixkosten: 10,
        geringinvestiveKostenProKW: 310,
        geringinvestiveKosten: 18628,
        pufferspeicherKosten: 11445,
        pufferKapazitaet: 1500,
        investKostenProL: 7.6,
        investKostenGesamt: 168400
    },
    {
        leistung: 80,
        jazFussboden: 5.35,
        jazSanierteAltbau: 4.55,
        jazUnsaniert: 3.96,
        lebensdauer: 20,
        spezInvestKosten: 570,
        anlagenKostenProzent: 90,
        installationsKostenProzent: 10,
        erschliessungskostenWQ: 1635,
        spezInvestKostenGesamt: 2205,
        investKostenGesamtAnlage: 176400,
        jaehrlicheFixkosten: 9,
        geringinvestiveKostenProKW: 284,
        geringinvestiveKosten: 22719,
        pufferspeicherKosten: 15260,
        pufferKapazitaet: 2000,
        investKostenProL: 7.6,
        investKostenGesamt: 214400
    },
    {
        leistung: 110,
        jazFussboden: 5.35,
        jazSanierteAltbau: 4.55,
        jazUnsaniert: 3.96,
        lebensdauer: 20,
        spezInvestKosten: 477,
        anlagenKostenProzent: 90,
        installationsKostenProzent: 10,
        erschliessungskostenWQ: 1635,
        spezInvestKostenGesamt: 2112,
        investKostenGesamtAnlage: 232300,
        jaehrlicheFixkosten: 7,
        geringinvestiveKostenProKW: 257,
        geringinvestiveKosten: 28302,
        pufferspeicherKosten: 15260,
        pufferKapazitaet: 2000,
        investKostenProL: 7.6,
        investKostenGesamt: 275900
    }
]

export const swp_sonden_einzel = [
    {
        jazFussboden: 4.60,
        leistung: 5,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 3065,
        anlagenKostenProzent: 71,
        installationsKostenProzent: 29,
        erschliessungskostenWQ: 1800,
        spezInvestKostenGesamt: 4865,
        investKostenGesamtAnlage: 24300,
        jaehrlicheFixkosten: 76,
        geringinvestiveKostenProKW: 671,
        geringinvestiveKosten: 3354,
        pufferspeicherKosten: 1025,
        pufferKapazitaet: 500,
        investKostenProL: 2.0,
        investKostenGesamt: 28700
    },
    {
        jazFussboden: 4.60,
        leistung: 10,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 2118,
        anlagenKostenProzent: 79,
        installationsKostenProzent: 21,
        erschliessungskostenWQ: 1637,
        spezInvestKostenGesamt: 3755,
        investKostenGesamtAnlage: 37500,
        jaehrlicheFixkosten: 38,
        geringinvestiveKostenProKW: 540,
        geringinvestiveKosten: 5404,
        pufferspeicherKosten: 1025,
        pufferKapazitaet: 500,
        investKostenProL: 2.0,
        investKostenGesamt: 43900
    },
    {
        jazFussboden: 4.60,
        leistung: 20,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 1508,
        anlagenKostenProzent: 85,
        installationsKostenProzent: 15,
        erschliessungskostenWQ: 1591,
        spezInvestKostenGesamt: 3099,
        investKostenGesamtAnlage: 62000,
        jaehrlicheFixkosten: 23,
        geringinvestiveKostenProKW: 469,
        geringinvestiveKosten: 9374,
        pufferspeicherKosten: 1726,
        pufferKapazitaet: 1000,
        investKostenProL: 1.7,
        investKostenGesamt: 73100
    },
    {
        jazFussboden: 4.60,
        leistung: 30,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 1250,
        anlagenKostenProzent: 88,
        installationsKostenProzent: 12,
        erschliessungskostenWQ: 1579,
        spezInvestKostenGesamt: 2829,
        investKostenGesamtAnlage: 84900,
        jaehrlicheFixkosten: 19,
        geringinvestiveKostenProKW: 385,
        geringinvestiveKosten: 11547,
        pufferspeicherKosten: 1726,
        pufferKapazitaet: 1000,
        investKostenProL: 1.7,
        investKostenGesamt: 98200
    },
    {
        jazFussboden: 4.60,
        leistung: 40,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 1098,
        anlagenKostenProzent: 90,
        installationsKostenProzent: 10,
        erschliessungskostenWQ: 1573,
        spezInvestKostenGesamt: 2671,
        investKostenGesamtAnlage: 106800,
        jaehrlicheFixkosten: 16,
        geringinvestiveKostenProKW: 352,
        geringinvestiveKosten: 14082,
        pufferspeicherKosten: 11445,
        pufferKapazitaet: 1500,
        investKostenProL: 7.6,
        investKostenGesamt: 132300
    },
    {
        jazFussboden: 4.60,
        leistung: 50,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 996,
        anlagenKostenProzent: 90,
        installationsKostenProzent: 10,
        erschliessungskostenWQ: 1487,
        spezInvestKostenGesamt: 2482,
        investKostenGesamtAnlage: 124100,
        jaehrlicheFixkosten: 15,
        geringinvestiveKostenProKW: 329,
        geringinvestiveKosten: 16426,
        pufferspeicherKosten: 11445,
        pufferKapazitaet: 1500,
        investKostenProL: 7.6,
        investKostenGesamt: 152000
    },
    {
        jazFussboden: 4.60,
        leistung: 60,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 920,
        anlagenKostenProzent: 90,
        installationsKostenProzent: 10,
        erschliessungskostenWQ: 1410,
        spezInvestKostenGesamt: 2330,
        investKostenGesamtAnlage: 139800,
        jaehrlicheFixkosten: 14,
        geringinvestiveKostenProKW: 310,
        geringinvestiveKosten: 18628,
        pufferspeicherKosten: 11445,
        pufferKapazitaet: 1500,
        investKostenProL: 7.6,
        investKostenGesamt: 169900
    },
    {
        jazFussboden: 4.60,
        leistung: 80,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 813,
        anlagenKostenProzent: 90,
        installationsKostenProzent: 10,
        erschliessungskostenWQ: 1348,
        spezInvestKostenGesamt: 2162,
        investKostenGesamtAnlage: 172900,
        jaehrlicheFixkosten: 12,
        geringinvestiveKostenProKW: 284,
        geringinvestiveKosten: 22719,
        pufferspeicherKosten: 15260,
        pufferKapazitaet: 2000,
        investKostenProL: 7.6,
        investKostenGesamt: 210900
    },
    {
        jazFussboden: 4.60,
        leistung: 110,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 712,
        anlagenKostenProzent: 90,
        installationsKostenProzent: 10,
        erschliessungskostenWQ: 1273,
        spezInvestKostenGesamt: 1985,
        investKostenGesamtAnlage: 218300,
        jaehrlicheFixkosten: 11,
        geringinvestiveKostenProKW: 257,
        geringinvestiveKosten: 28302,
        pufferspeicherKosten: 15260,
        pufferKapazitaet: 2000,
        investKostenProL: 7.6,
        investKostenGesamt: 261900
    }
]

export const swp_flaechenkollektor_einzel = [
    {
        jazFussboden: 4.60,
        leistung: 5,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 3065,
        anlagenKostenProzent: 71,
        installationsKostenProzent: 29,
        erschliessungskostenWQ: 1393,
        spezInvestKostenGesamt: 4458,
        investKostenGesamtAnlage: 22300,
        jaehrlicheFixkosten: 76,
        geringinvestiveKostenProKW: 671,
        geringinvestiveKosten: 3354,
        pufferspeicherKosten: 1025,
        pufferKapazitaet: 500,
        investKostenProL: 2.0,
        investKostenGesamt: 26700
    },
    {
        jazFussboden: 4.60,
        leistung: 10,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 2118,
        anlagenKostenProzent: 79,
        installationsKostenProzent: 21,
        erschliessungskostenWQ: 1054,
        spezInvestKostenGesamt: 3172,
        investKostenGesamtAnlage: 31700,
        jaehrlicheFixkosten: 38,
        geringinvestiveKostenProKW: 540,
        geringinvestiveKosten: 5404,
        pufferspeicherKosten: 1025,
        pufferKapazitaet: 500,
        investKostenProL: 2.0,
        investKostenGesamt: 38100
    },
    {
        jazFussboden: 4.60,
        leistung: 20,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 1508,
        anlagenKostenProzent: 85,
        installationsKostenProzent: 15,
        erschliessungskostenWQ: 1007,
        spezInvestKostenGesamt: 2515,
        investKostenGesamtAnlage: 50300,
        jaehrlicheFixkosten: 23,
        geringinvestiveKostenProKW: 469,
        geringinvestiveKosten: 9374,
        pufferspeicherKosten: 1726,
        pufferKapazitaet: 1000,
        investKostenProL: 1.7,
        investKostenGesamt: 61400
    },
    {
        jazFussboden: 4.60,
        leistung: 30,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 1250,
        anlagenKostenProzent: 88,
        installationsKostenProzent: 12,
        erschliessungskostenWQ: 1007,
        spezInvestKostenGesamt: 2257,
        investKostenGesamtAnlage: 67700,
        jaehrlicheFixkosten: 19,
        geringinvestiveKostenProKW: 385,
        geringinvestiveKosten: 11547,
        pufferspeicherKosten: 1726,
        pufferKapazitaet: 1000,
        investKostenProL: 1.7,
        investKostenGesamt: 81000
    },
    {
        jazFussboden: 4.60,
        leistung: 40,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 1098,
        anlagenKostenProzent: 90,
        installationsKostenProzent: 10,
        erschliessungskostenWQ: 1007,
        spezInvestKostenGesamt: 2105,
        investKostenGesamtAnlage: 84200,
        jaehrlicheFixkosten: 16,
        geringinvestiveKostenProKW: 352,
        geringinvestiveKosten: 14082,
        pufferspeicherKosten: 11445,
        pufferKapazitaet: 1500,
        investKostenProL: 7.6,
        investKostenGesamt: 109700
    },
    {
        jazFussboden: 4.60,
        leistung: 50,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 996,
        anlagenKostenProzent: 90,
        installationsKostenProzent: 10,
        erschliessungskostenWQ: 1007,
        spezInvestKostenGesamt: 2003,
        investKostenGesamtAnlage: 100100,
        jaehrlicheFixkosten: 15,
        geringinvestiveKostenProKW: 329,
        geringinvestiveKosten: 16426,
        pufferspeicherKosten: 11445,
        pufferKapazitaet: 1500,
        investKostenProL: 7.6,
        investKostenGesamt: 128000
    },
    {
        jazFussboden: 4.60,
        leistung: 60,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 920,
        anlagenKostenProzent: 90,
        installationsKostenProzent: 10,
        erschliessungskostenWQ: 1007,
        spezInvestKostenGesamt: 1927,
        investKostenGesamtAnlage: 115600,
        jaehrlicheFixkosten: 14,
        geringinvestiveKostenProKW: 310,
        geringinvestiveKosten: 18628,
        pufferspeicherKosten: 11445,
        pufferKapazitaet: 1500,
        investKostenProL: 7.6,
        investKostenGesamt: 145700
    },
    {
        jazFussboden: 4.60,
        leistung: 80,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 813,
        anlagenKostenProzent: 90,
        installationsKostenProzent: 10,
        erschliessungskostenWQ: 1007,
        spezInvestKostenGesamt: 1821,
        investKostenGesamtAnlage: 145700,
        jaehrlicheFixkosten: 12,
        geringinvestiveKostenProKW: 284,
        geringinvestiveKosten: 22719,
        pufferspeicherKosten: 15260,
        pufferKapazitaet: 2000,
        investKostenProL: 7.6,
        investKostenGesamt: 183700
    },
    {
        jazFussboden: 4.60,
        leistung: 110,
        jazSanierteAltbau: 3.60,
        jazUnsaniert: 3.15,
        lebensdauer: 20,
        lebensdauerErschliessung: 50,
        spezInvestKosten: 712,
        anlagenKostenProzent: 90,
        installationsKostenProzent: 10,
        erschliessungskostenWQ: 1007,
        spezInvestKostenGesamt: 1719,
        investKostenGesamtAnlage: 189100,
        jaehrlicheFixkosten: 11,
        geringinvestiveKostenProKW: 257,
        geringinvestiveKosten: 28302,
        pufferspeicherKosten: 15260,
        pufferKapazitaet: 2000,
        investKostenProL: 7.6,
        investKostenGesamt: 232700
    }
]

export const pellets_einzel = [
    {
        thermischeLeistung: 10,
        wirkungsgrad: 90,
        lebensdauer: 20,
        spezifischeInvestitionskosten: 1428,
        anlagenKostenProzent: 60,
        installationsKostenProzent: 40,
        investitionsKostenGesamt: 14300,
        jaehrlicheFixkosten: 86,
        geringinvestiveMassnahmenProKW: 162,
        geringinvestiveMassnahmen: 1624,
        schornsteinertuechtigungProKW: 184,
        pelletlagerkostenProKW: 357,
        pufferspeicherKosten: 1025,
        pufferKapazitaet: 500,
        investKostenProL: 2.0,
        investKostenGesamt: 22400
    },
    {
        thermischeLeistung: 20,
        wirkungsgrad: 90,
        lebensdauer: 20,
        spezifischeInvestitionskosten: 1014,
        anlagenKostenProzent: 60,
        installationsKostenProzent: 40,
        investitionsKostenGesamt: 20300,
        jaehrlicheFixkosten: 61,
        geringinvestiveMassnahmenProKW: 131,
        geringinvestiveMassnahmen: 2618,
        schornsteinertuechtigungProKW: 111,
        pelletlagerkostenProKW: 253,
        pufferspeicherKosten: 1726,
        pufferKapazitaet: 1000,
        investKostenProL: 1.7,
        investKostenGesamt: 31900
    },
    {
        thermischeLeistung: 30,
        wirkungsgrad: 81,
        lebensdauer: 20,
        spezifischeInvestitionskosten: 801,
        anlagenKostenProzent: 60,
        installationsKostenProzent: 40,
        investitionsKostenGesamt: 24000,
        jaehrlicheFixkosten: 48,
        geringinvestiveMassnahmenProKW: 115,
        geringinvestiveMassnahmen: 3461,
        schornsteinertuechtigungProKW: 82,
        pelletlagerkostenProKW: 200,
        pufferspeicherKosten: 1726,
        pufferKapazitaet: 1000,
        investKostenProL: 1.7,
        investKostenGesamt: 37700
    },
    {
        thermischeLeistung: 60,
        wirkungsgrad: 81,
        lebensdauer: 20,
        spezifischeInvestitionskosten: 552,
        anlagenKostenProzent: 60,
        installationsKostenProzent: 40,
        investitionsKostenGesamt: 33100,
        jaehrlicheFixkosten: 33,
        geringinvestiveMassnahmenProKW: 93,
        geringinvestiveMassnahmen: 5575,
        schornsteinertuechtigungProKW: 49,
        pelletlagerkostenProKW: 138,
        pufferspeicherKosten: 11445,
        pufferKapazitaet: 1500,
        investKostenProL: 7.6,
        investKostenGesamt: 61400
    },
    {
        thermischeLeistung: 110,
        wirkungsgrad: 81,
        lebensdauer: 20,
        spezifischeInvestitionskosten: 414,
        anlagenKostenProzent: 60,
        installationsKostenProzent: 40,
        investitionsKostenGesamt: 45500,
        jaehrlicheFixkosten: 25,
        geringinvestiveMassnahmenProKW: 77,
        geringinvestiveMassnahmen: 8460,
        schornsteinertuechtigungProKW: 32,
        pelletlagerkostenProKW: 103,
        pufferspeicherKosten: 15260,
        pufferKapazitaet: 2000,
        investKostenProL: 7.6,
        investKostenGesamt: 84100
    }
];

export const gas_einzel = [
    {
        thermischeLeistung: 10,
        wirkungsgrad: 93,
        wirkungsgradBrennwert: 99,
        lebensdauer: 20,
        spezifischeInvestitionskosten: 709,
        anlagenKostenProzent: 70,
        installationsKostenProzent: 30,
        investitionsKostenGesamt: 7100,
        jaehrlicheFixkosten: 21,
        geringinvestiveMassnahmenProKW: 162,
        geringinvestiveMassnahmen: 1620,
        schornsteinertuechtigungProKW: 97,
        pufferspeicherKosten: 1025,
        pufferKapazitaet: 500,
        investKostenProL: 2.0,
        investKostenGesamt: 10700
    },
    {
        thermischeLeistung: 20,
        wirkungsgrad: 95,
        wirkungsgradBrennwert: 99.4,
        lebensdauer: 20,
        spezifischeInvestitionskosten: 354,
        anlagenKostenProzent: 70,
        installationsKostenProzent: 30,
        investitionsKostenGesamt: 7100,
        jaehrlicheFixkosten: 11,
        geringinvestiveMassnahmenProKW: 131,
        geringinvestiveMassnahmen: 2620,
        schornsteinertuechtigungProKW: 49,
        pufferspeicherKosten: 1726,
        pufferKapazitaet: 1000,
        investKostenProL: 1.7,
        investKostenGesamt: 12400
    },
    {
        thermischeLeistung: 30,
        wirkungsgrad: 95,
        wirkungsgradBrennwert: 99.4,
        lebensdauer: 20,
        spezifischeInvestitionskosten: 245,
        anlagenKostenProzent: 70,
        installationsKostenProzent: 30,
        investitionsKostenGesamt: 7400,
        jaehrlicheFixkosten: 7,
        geringinvestiveMassnahmenProKW: 115,
        geringinvestiveMassnahmen: 3460,
        schornsteinertuechtigungProKW: 32,
        pufferspeicherKosten: 1726,
        pufferKapazitaet: 1000,
        investKostenProL: 1.7,
        investKostenGesamt: 13600
    },
    {
        thermischeLeistung: 60,
        wirkungsgrad: 95,
        wirkungsgradBrennwert: 99.4,
        lebensdauer: 20,
        spezifischeInvestitionskosten: 165,
        anlagenKostenProzent: 70,
        installationsKostenProzent: 30,
        investitionsKostenGesamt: 9900,
        jaehrlicheFixkosten: 5,
        geringinvestiveMassnahmenProKW: 93,
        geringinvestiveMassnahmen: 5580,
        schornsteinertuechtigungProKW: 16,
        pufferspeicherKosten: 11445,
        pufferKapazitaet: 1500,
        investKostenProL: 7.6,
        investKostenGesamt: 27900
    },
    {
        thermischeLeistung: 110,
        wirkungsgrad: 95,
        wirkungsgradBrennwert: 99.4,
        lebensdauer: 20,
        spezifischeInvestitionskosten: 161,
        anlagenKostenProzent: 70,
        installationsKostenProzent: 30,
        investitionsKostenGesamt: 17800,
        jaehrlicheFixkosten: 5,
        geringinvestiveMassnahmenProKW: 77,
        geringinvestiveMassnahmen: 8460,
        schornsteinertuechtigungProKW: 9,
        pufferspeicherKosten: 15260,
        pufferKapazitaet: 2000,
        investKostenProL: 7.6,
        investKostenGesamt: 42500
    }
];

export const lwp_kompress_netz = [

    {
        leistung: 0.3,
        jazDurchschnitt: 2.8,
        lebensdauer: 25,
        spezInvestKosten: 1861,
        anlagenKostenProzent: 75,
        installationsKostenProzent: 25,
        investKostenGesamt: 558000,
        jaehrlicheFixkosten: 47
    },
    {
        leistung: 1.5,
        jazDurchschnitt: 2.8,
        lebensdauer: 25,
        spezInvestKosten: 1163,
        anlagenKostenProzent: 78,
        installationsKostenProzent: 23,
        investKostenGesamt: 1745000,
        jaehrlicheFixkosten: 29
    },
    {
        leistung: 5,
        jazDurchschnitt: 2.8,
        lebensdauer: 25,
        spezInvestKosten: 931,
        anlagenKostenProzent: 80,
        installationsKostenProzent: 20,
        investKostenGesamt: 4653000,
        jaehrlicheFixkosten: 23
    },
    {
        leistung: 20,
        jazDurchschnitt: 2.8,
        lebensdauer: 25,
        spezInvestKosten: 651,
        anlagenKostenProzent: 80,
        installationsKostenProzent: 20,
        investKostenGesamt: 13029000,
        jaehrlicheFixkosten: 16
    }



]

export const wwp_gewaesser_netz = [
    {
        leistung: 0.3,
        jazDurchschnitt: 2.8,
        lebensdauer: 25,
        spezInvestKosten: 1861,
        anlagenKostenProzent: 75,
        installationsKostenProzent: 25,
        investKostenGesamt: 558000,
        jaehrlicheFixkosten: 47
    },
    {
        leistung: 1.5,
        jazDurchschnitt: 2.8,
        lebensdauer: 25,
        spezInvestKosten: 1163,
        anlagenKostenProzent: 78,
        installationsKostenProzent: 23,
        investKostenGesamt: 1745000,
        jaehrlicheFixkosten: 29
    },
    {
        leistung: 5,
        jazDurchschnitt: 2.8,
        lebensdauer: 25,
        spezInvestKosten: 931,
        anlagenKostenProzent: 80,
        installationsKostenProzent: 20,
        investKostenGesamt: 4653000,
        jaehrlicheFixkosten: 23
    },
    {
        leistung: 20,
        jazDurchschnitt: 2.8,
        lebensdauer: 25,
        spezInvestKosten: 651,
        anlagenKostenProzent: 80,
        installationsKostenProzent: 20,
        investKostenGesamt: 13029000,
        jaehrlicheFixkosten: 16
    }
]
export const wwp_abwasser_netz = [
    {
        thermischeLeistungMW: 1,
        jazDurchschnitt: 2.9,
        lebensdauer: 20,
        spezInvestKosten: 1243,
        anlagenKostenProzent: 65,
        installationsKostenProzent: 35,
        investKostenGesamt: 1243000,
        jaehrlicheFixkosten: 31
    },
    {
        thermischeLeistungMW: 10,
        jazDurchschnitt: 2.9,
        lebensdauer: 20,
        spezInvestKosten: 719,
        anlagenKostenProzent: 65,
        installationsKostenProzent: 35,
        investKostenGesamt: 7194000,
        jaehrlicheFixkosten: 18
    }
]




export const wp_foerder = [
    "Elektrisch angetriebene Wärmepumpen",
    "Einzelmaßnahmen an der Gebäudehülle",
    "Anlagentechnik (außer Heizung)",
    "Maßnahmen zur Verbesserung der Anlageneffizienz",
]

export const foerderungen = [
    {
        durchfuehrer: 'BAFA',
        richtlinienNr: '5.1',
        einzelmassnahme: 'Einzelmaßnahmen an der Gebäudehülle',
        grundfoerdersatz: '15%',
        isfpBonus: '5%',
        effizienzBonus: '-',
        klimaGeschwindigkeitsBonus: '-',
        einkommensBonus: '-',
        fachplanungUndBaubegleitung: '50%',
    },
    {
        durchfuehrer: 'BAFA',
        richtlinienNr: '5.2',
        einzelmassnahme: 'Anlagentechnik (außer Heizung)',
        grundfoerdersatz: '15%',
        isfpBonus: '5%',
        effizienzBonus: '-',
        klimaGeschwindigkeitsBonus: '-',
        einkommensBonus: '-',
        fachplanungUndBaubegleitung: '50%',
    },
    {
        durchfuehrer: 'KfW',
        richtlinienNr: '5.3a',
        einzelmassnahme: 'Solarthermische Anlagen',
        grundfoerdersatz: '30%',
        isfpBonus: '-',
        effizienzBonus: '-',
        klimaGeschwindigkeitsBonus: 'max. 20%',
        einkommensBonus: '30%',
        fachplanungUndBaubegleitung: '-',
    },
    {
        durchfuehrer: 'KfW',
        richtlinienNr: '5.3b',
        einzelmassnahme: 'Biomasseheizungen',
        grundfoerdersatz: '30%',
        isfpBonus: '-',
        effizienzBonus: '-',
        klimaGeschwindigkeitsBonus: 'max. 20%',
        einkommensBonus: '30%',
        fachplanungUndBaubegleitung: '-',
    },
    {
        durchfuehrer: 'KfW',
        richtlinienNr: '5.3c',
        einzelmassnahme: 'Elektrisch angetriebene Wärmepumpen',
        grundfoerdersatz: '30%',
        isfpBonus: '-',
        effizienzBonus: '5%',
        klimaGeschwindigkeitsBonus: 'max. 20%',
        einkommensBonus: '30%',
        fachplanungUndBaubegleitung: '-',
    },
    {
        durchfuehrer: 'KfW',
        richtlinienNr: '5.3d',
        einzelmassnahme: 'Brennstoffzellenheizungen',
        grundfoerdersatz: '30%',
        isfpBonus: '-',
        effizienzBonus: '-',
        klimaGeschwindigkeitsBonus: 'max. 20%',
        einkommensBonus: '30%',
        fachplanungUndBaubegleitung: '-',
    },
    {
        durchfuehrer: 'KfW',
        richtlinienNr: '5.3e',
        einzelmassnahme: 'Wasserstofffähige Heizungen (Investitionsmehrausgaben)',
        grundfoerdersatz: '30%',
        isfpBonus: '-',
        effizienzBonus: '-',
        klimaGeschwindigkeitsBonus: 'max. 20%',
        einkommensBonus: '30%',
        fachplanungUndBaubegleitung: '-',
    },
    {
        durchfuehrer: 'KfW',
        richtlinienNr: '5.3f',
        einzelmassnahme: 'Innovative Heizungstechnik auf Basis erneuerbarer Energien',
        grundfoerdersatz: '30%',
        isfpBonus: '-',
        effizienzBonus: '-',
        klimaGeschwindigkeitsBonus: 'max. 20%',
        einkommensBonus: '30%',
        fachplanungUndBaubegleitung: '-',
    },
    {
        durchfuehrer: 'BAFA',
        richtlinienNr: '5.3g',
        einzelmassnahme: 'Errichtung, Umbau, Erweiterung eines Gebäudenetzes',
        grundfoerdersatz: '30%',
        isfpBonus: '-',
        effizienzBonus: '-',
        klimaGeschwindigkeitsBonus: 'max. 20%',
        einkommensBonus: '30%',
        fachplanungUndBaubegleitung: '50%',
    },
    {
        durchfuehrer: 'BAFA/KfW',
        richtlinienNr: '5.3h',
        einzelmassnahme: 'Anschluss an ein Gebäudenetz',
        grundfoerdersatz: '30%',
        isfpBonus: '-',
        effizienzBonus: '-',
        klimaGeschwindigkeitsBonus: 'max. 20%',
        einkommensBonus: '30%',
        fachplanungUndBaubegleitung: '50%',
    },
    {
        durchfuehrer: 'KfW',
        richtlinienNr: '5.3i',
        einzelmassnahme: 'Anschluss an ein Wärmenetz',
        grundfoerdersatz: '30%',
        isfpBonus: '-',
        effizienzBonus: '-',
        klimaGeschwindigkeitsBonus: 'max. 20%',
        einkommensBonus: '30%',
        fachplanungUndBaubegleitung: '-',
    },
    {
        durchfuehrer: 'BAFA',
        richtlinienNr: '5.4a',
        einzelmassnahme: 'Maßnahmen zur Verbesserung der Anlageneffizienz',
        grundfoerdersatz: '15%',
        isfpBonus: '5%',
        effizienzBonus: '-',
        klimaGeschwindigkeitsBonus: '-',
        einkommensBonus: '-',
        fachplanungUndBaubegleitung: '50%',
    },
    {
        durchfuehrer: 'BAFA',
        richtlinienNr: '5.4b',
        einzelmassnahme: 'Maßnahmen zur Emissionsminderung von Biomasseheizungen',
        grundfoerdersatz: '50%',
        isfpBonus: '-',
        effizienzBonus: '-',
        klimaGeschwindigkeitsBonus: '-',
        einkommensBonus: '-',
        fachplanungUndBaubegleitung: '50%',
    },
];


export const technikkatalog_jahre = [
    2020, 2021, 2022, 2025, 2030, 2035, 2040, 2045, 2050
];

export const technikkatalog_emissionen = [
    { energietraeger: 'Heizöl', emissionen: [310, 310, 310, 310, 310, 310, 310, 310, 310] },
    { energietraeger: 'Erdgas', emissionen: [240, 240, 240, 240, 240, 240, 240, 240, 240] },
    { energietraeger: 'Braunkohle', emissionen: [430, 430, 430, 430, 430, 430, 430, 430, 430] },
    { energietraeger: 'Steinkohle', emissionen: [400, 400, 400, 400, 400, 400, 400, 400, 400] },
    { energietraeger: 'Holz', emissionen: [20, 20, 20, 20, 20, 20, 20, 20, 20] },
    { energietraeger: 'Biogas', emissionen: [140, 140, 139, 137, 133, 130, 126, 123, 120] },
    { energietraeger: 'Erdwärme, Geothermie, Solarthermie, Umgebungswärme', emissionen: [0, 0, 0, 0, 0, 0, 0, 0, 0] },
    { energietraeger: 'Erdkälte, Umgebungskälte', emissionen: [0, 0, 0, 0, 0, 0, 0, 0, 0] },
    { energietraeger: 'Wärme aus Verbrennung von Siedlungsabfällen', emissionen: [20, 20, 20, 20, 20, 20, 20, 20, 20] },
    { energietraeger: 'Abwärme aus Prozessen', emissionen: [40, 40, 40, 39, 38, 37, 36, 35, 34] },
    { energietraeger: 'Strom-Mix-D', emissionen: [424, 472, 499, 260, 110, 45, 25, 15, 15] }
];

export const waermepumpen_map = [
    {
        option: 'lwp_mono',
        name: 'Monoblock Luftwärmepumpe',
        waermetraeger: 'Luft',
        bauweisen: 'Monoblock',
        buildings: ['MFH', 'EFH'],
        funktionsweise: 'Eine Monoblock Luftwärmepumpe nutzt die in der Außenluft vorhandene Wärmeenergie, um diese für die Heizung und Warmwasserbereitung zu verwenden. Alle Komponenten sind in einem einzigen Gerät (Monoblock) verbaut, welches außerhalb oder innerhalb des Gebäudes installiert werden kann. Die Wärme wird über einen Wärmetauscher an das Heizsystem abgegeben.',
    },
    {
        option: 'lwp_split',
        name: 'Split Luftwärmepumpe',
        waermetraeger: 'Luft',
        bauweisen: 'Split',
        buildings: ['Netz', 'GMFH', 'MFH', 'EFH'],
        funktionsweise: 'Die Split Luftwärmepumpe besteht aus einer Innen- und einer Außeneinheit. Die Außeneinheit entzieht der Umgebungsluft Wärme und leitet diese über Kältemittelleitungen an die Inneneinheit weiter. In der Inneneinheit wird die Wärme an das Heizsystem übertragen. Dieses System ermöglicht eine flexible Installation und kann platzsparend sein.',

    },
    {
        option: 'wwp_grundwasser',
        name: 'Grundwasser-Wärmepumpe',
        waermetraeger: 'Wasser',
        bauweisen: 'Grundwasser',
        buildings: ['Netz', 'GMFH', 'MFH', 'EFH'],
        funktionsweise: 'Die Grundwasser-Wärmepumpe nutzt die ganzjährig konstante Temperatur des Grundwassers als Wärmequelle. Durch einen Förderbrunnen wird das Grundwasser entnommen, die Wärme entzogen und anschließend über einen Schluckbrunnen wieder in den Grund geleitet. Die gewonnene Wärme wird an das Heizsystem des Gebäudes abgegeben.',

    },
    {
        option: 'wwp_abwasser',
        name: 'Abwasser-Wärmepumpe',
        waermetraeger: 'Wasser',
        bauweisen: 'Abwasser',
        buildings: ['Netz', 'GMFH', 'MFH', 'EFH'],
        funktionsweise: 'Die Abwasser-Wärmepumpe nutzt die im Abwasser enthaltene Wärmeenergie. Über Wärmetauscher wird dem Abwasser Wärme entzogen, die dann für Heizzwecke verwendet wird. Dieses System ermöglicht eine effiziente Nutzung von Abwärme, die ansonsten ungenutzt bliebe.',
    },
    {
        option: 'wwp_gewaesser',
        name: 'Flusswasser-Wärmepumpe',
        waermetraeger: 'Wasser',
        bauweisen: 'Flusswasser',
        buildings: ['Netz', 'GMFH', 'MFH', 'EFH'],
        funktionsweise: 'Die Flusswasser-Wärmepumpe entzieht Wärme aus Flüssen oder Seen. Über einen Wärmetauscher wird die im Wasser vorhandene Wärmeenergie aufgenommen und an das Heizsystem übertragen. Dieses System erfordert eine entsprechende Genehmigung und die Nähe zu einem geeigneten Gewässer.',
    },
    {
        option: 'swp_erdsonde',
        name: 'Erdsonden-Wärmepumpe',
        waermetraeger: 'Sole',
        bauweisen: 'Erdsonde',
        buildings: ['Netz', 'GMFH', 'MFH', 'EFH'],
        funktionsweise: 'Die Erdsonden-Wärmepumpe nutzt die im Erdreich gespeicherte Wärme. Vertikale Sonden werden tief in den Boden gebohrt, um die konstante Erdwärme aufzunehmen. Die Wärme wird dann an das Heizsystem abgegeben. Dieses System ist effizient und platzsparend, da wenig Oberfläche benötigt wird.',

    },
    {
        option: 'swp_flaechenkollektor',
        name: 'Flächenkollektor-Wärmepumpe',
        waermetraeger: 'Sole',
        bauweisen: 'Flächenkollektor',
        buildings: ['Netz', 'GMFH', 'MFH', 'EFH'],
        funktionsweise: 'Die Flächenkollektor-Wärmepumpe nutzt ebenfalls die Erdwärme, jedoch über horizontal im Boden verlegte Kollektoren. Die Kollektoren entziehen dem Boden die Wärme, die an das Heizsystem weitergegeben wird. Dieses System erfordert eine größere verfügbare Fläche für die Installation der Kollektoren.',

    },
];
