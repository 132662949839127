import React, { useEffect, useState} from 'react';
import '../../../../../styles/ipkw/Dashboard.css';
import '../../../../../styles/ipkw/QuartierCard.css';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend,
} from 'recharts';
import { useDashboardContext } from '../../../../../context/DashboardContext';
import { getEinzelMoney } from './SingleMoneyCalculator';
import { getNetzMoney } from './NetzMoneyCalculator';

function getAllCostData(ipkwQuartierData, scenarioConfig, einzelConfig, currentWlGeoJSONData, solutionBuilderData) {
    const years = [2025, 2030, 2035, 2040, 2045];
    const dummyFallbackCosts = [
        { year: 2025, cost: 0 },
        { year: 2030, cost: 0 },
        { year: 2035, cost: 0 },
        { year: 2040, cost: 0 },
        { year: 2045, cost: 0 },
    ];

    let data = years.map(year => ({ year }));
  
    const einzelData = getEinzelMoney(ipkwQuartierData, scenarioConfig, einzelConfig);
    const customSolutionData = dummyFallbackCosts;
    const fwData = getNetzMoney(ipkwQuartierData, scenarioConfig);


    einzelData.forEach((item, idx) => {
      data[idx]["Einzellösung"] = item.cost;
    });
  
    customSolutionData.forEach((item, idx) => {
      data[idx]["Eigene Wärmelösung"] = item.cost;
    });
  
    fwData.forEach((item, idx) => {
      data[idx]["Fernwärmelösung"] = item.cost;
    });
    return data;
  }

const MoneyChart = () => {
    const { ipkwQuartierData, currentWlGeoJSONData, scenarioConfig, einzelConfig, solutionBuilderData} = useDashboardContext();


        const [costData, setCostData] = useState(null);
        
        const maxDomain = ipkwQuartierData.quartierData.gesamtverbrauch? Math.ceil(ipkwQuartierData.quartierData.gesamtverbrauch / 2.5e6) * 1e6 : 5e6;
        
        useEffect(() => {
            setCostData(getAllCostData(ipkwQuartierData,scenarioConfig, einzelConfig,currentWlGeoJSONData,solutionBuilderData));
        }, [ipkwQuartierData, scenarioConfig, einzelConfig,currentWlGeoJSONData,solutionBuilderData]);
        
    
        const options = ['Einzellösung', 'Eigene Wärmelösung', 'Fernwärmelösung']; 
        const colors = ['#4d4d4d', '#00b1aa', '#f37021']; 
        const colorsdark = ['#1a1a1a', '#08918b', '#c05d1d']; 
    
        const initialLineVisibility = options.reduce(
            (acc, img) => ({ ...acc, [img]: true }),
            {}
        );
        initialLineVisibility['Eigene Wärmelösung'] = true; 
        const [lineVisibility, setLineVisibility] = useState(initialLineVisibility);
    
        const legendPayload = [
            ...options.map((img, index) => ({
                value: img,
                type: 'line',
                id: img,
                dataKey: img,
                color: colors[index % colors.length],
                darkColor: colorsdark[index % colors.length],
            })),
        ];
    
        const handleLegendClick = (dataKey) => {
            setLineVisibility((prevState) => ({
                ...prevState,
                [dataKey]: !prevState[dataKey],
            }));
        };
    
        const renderCustomizedLegend = (props) => {
            const { payload } = props;
            return (
                <ul
                    className="legend-list"
    
                >
                    {payload.map((entry, index) => (
                        <li
                            key={`item-${index}`}
                            onClick={() => handleLegendClick(entry.dataKey)}
                            style={{
                                cursor: 'pointer',
                                marginRight: 10,
                            }}
                        >
                            <span
                                style={{
    
                                    color: lineVisibility[entry.dataKey]
                                        ? entry.color
                                        : entry.darkColor,                            }}
                            >
                                {entry.value}
                            </span>
                        </li>
                    ))}
                </ul>
            );
        };

    return (


                <div className="status-quo-charts">
                            <div className="chart-container">
                                <div className="chart-label">
                                    Leistungsgebundene Kosten €/a durch Wärmebereitstellung inkl. Inflation
                                </div>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={costData}>
                                        <Legend content={renderCustomizedLegend} payload={legendPayload} />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="year" />
                                        <YAxis
                                            tickFormatter={(value) => `${value / 1e6} Mio €`}
                                            domain={[0, maxDomain]}
                                        />
                                        <Tooltip formatter={(value) => `${(value / 1e6).toFixed(4)} Mio €`} />
                
                                        {options.map((img, index) =>
                                            lineVisibility[img] ? (
                                                <Line
                                                    key={img}
                                                    name={img}
                                                    type="monotone"
                                                    dataKey={img}
                                                    stroke={colors[index % colors.length]}
                                                    isAnimationActive={false} 
                
                                                />
                                            ) : null
                                        )}
                                        {lineVisibility['Eigene Wärmelösung'] && (
                                            <Line
                                                name="Eigene Wärmelösung"
                                                type="monotone"
                                                dataKey="solutionBuilder"
                                                stroke="#000000"
                                                isAnimationActive={false} 
                
                                            />
                                        )}
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>

    );
};

export default MoneyChart;
