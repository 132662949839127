import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/home/CardStyles.css'; // Importing shared CSS
import WikiIcon  from '../../images/WikiIcon.png';

const WikiPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/wiki'); 
  };

  return (
    <div className="card"  onClick={handleClick}>
      <img src={WikiIcon} alt="Wiki Icon" className="card-icon" />
      <h2>Wiki</h2>
      <p>Dies ist die Wiki-Seite. Entdecken Sie Wissen und Einblicke zu der kommunalen Wärmewende und zu dem iPKW Spiel.</p>
    </div>
  );
};

export default WikiPage;
