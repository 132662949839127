import React, { useState } from 'react';
import '../../../../styles/ipkw/QuartierCard.css';

const Potenziale = () => {
    const [potentials, setPotentials] = useState({
        Umgebungswaerme: { checked: false, value: '' },
        IndustrielleAbwaerme: { checked: false, value: '' },
        Geothermie: { checked: false, option: '' },
        Aufstellplaetze: { checked: false },
        Freiflaeche: { checked: false },
    });

    const handleCheckboxChange = (event, key) => {
        setPotentials({
            ...potentials,
            [key]: {
                ...potentials[key],
                checked: event.target.checked,
            },
        });
    };

    const handleInputChange = (event, key) => {
        setPotentials({
            ...potentials,
            [key]: {
                ...potentials[key],
                value: event.target.value,
            },
        });
    };

    const handleToggleGeothermieOption = () => {
        setPotentials((prevPotentials) => ({
            ...prevPotentials,
            Geothermie: {
                ...prevPotentials.Geothermie,
                option:
                    prevPotentials.Geothermie.option === 'Oberfläche' ? 'Tief' : 'Oberfläche',
            },
        }));
    };

    const displayNames = {
        Umgebungswaerme: 'Umgebungswärme',
        IndustrielleAbwaerme: 'Industrielle Abwärme',
        Geothermie: 'Geothermie',
        Aufstellplaetze: 'Aufstellplätze',
        Freiflaeche: 'Freifläche',
    };

    return (
        <div className='potenziale-container'>
            <h3 className='inner-title'>Potenziale</h3>
            <div className='potenziale-items'>

                {/* Umgebungswärme */}
                <div className='potenziale-item' key='Umgebungswaerme'>
                    <div className='potenziale-item-left'>
                        <label>
                            <input
                                type='checkbox'
                                checked={potentials.Umgebungswaerme.checked}
                                onChange={(e) => handleCheckboxChange(e, 'Umgebungswaerme')}
                            />
                            {displayNames.Umgebungswaerme}
                        </label>
                    </div>
                </div>

                {/* Industrielle Abwärme */}
                <div className='potenziale-item' key='IndustrielleAbwaerme'>
                    <div className='potenziale-item-left'>
                        <label>
                            <input
                                type='checkbox'
                                checked={potentials.IndustrielleAbwaerme.checked}
                                onChange={(e) => handleCheckboxChange(e, 'IndustrielleAbwaerme')}
                            />
                            {displayNames.IndustrielleAbwaerme}
                        </label>
                    </div>
                </div>

                {/* Geothermie */}
                <div className='potenziale-item' key='Geothermie'>
                    <div className='potenziale-item-left'>
                        <label>
                            <input
                                type='checkbox'
                                checked={potentials.Geothermie.checked}
                                onChange={(e) => handleCheckboxChange(e, 'Geothermie')}
                            />
                            {displayNames.Geothermie}
                        </label>
                    </div>
                    <div className='potenziale-item-right'>
                        <button
                            onClick={handleToggleGeothermieOption}
                            disabled={!potentials.Geothermie.checked}
                            className='swap-button'
                        >
                            {potentials.Geothermie.option || 'Auswahl'}
                        </button>
                    </div>
                </div>

                {/* Aufstellplätze */}
                <div className='potenziale-item' key='Aufstellplaetze'>
                    <div className='potenziale-item-left'>
                        <label>
                            <input
                                type='checkbox'
                                checked={potentials.Aufstellplaetze.checked}
                                onChange={(e) => handleCheckboxChange(e, 'Aufstellplaetze')}
                            />
                            {displayNames.Aufstellplaetze}
                        </label>
                    </div>
                </div>

                {/* Freifläche */}
                <div className='potenziale-item' key='Freiflaeche'>
                    <div className='potenziale-item-left'>
                        <label>
                            <input
                                type='checkbox'
                                checked={potentials.Freiflaeche.checked}
                                onChange={(e) => handleCheckboxChange(e, 'Freiflaeche')}
                            />
                            {displayNames.Freiflaeche}
                        </label>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Potenziale;
